import React from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Home from '../../Home/Home'
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../assets/images/searchIcon.png";
import buttonadd from "../../../assets/images/plus.png";
import "./index.scss"

const AddNewServiceProvider =() => {

  const navigate = useNavigate();

  const handleHomeHealthcareServiceProviderForm=()=>{
    navigate("/home-healthcare-service-provider-form")
  }

  const handleEdit=()=>{
    navigate("/home-healthcare-service-provider-edit")
  }

  const data =[
    {
      sno:"1",
      name:"Julie R",
      spl1:"Nursing Support Service",
      spl2:"Mom & Baby care",
      date:"01-02-2022",
      gender:"Female",
      age:"34",
      dob:"02-08-1997",
      fee:"250",
      mobile:"+9192226 25629",
      email:"julie@gmail.com",
      city:"Bangalore",
      pincode:"421 260",
      license:"KA2411641",
      exp:"2",
      edu:"Bsc Nursing",
      hos:"Advaith Homecare, bangalore",
      total:"1",
      language:"Kanada",
      associate:"Karnataka Nursing Counsil",
      status:"Active",
    },
    {
      sno:"1",
      name:"Ahmed K",
      spl1:"Physiotheraphy",
      spl2:"-",
      date:"30-01-2022",
      gender:"male",
      age:"32",
      dob:"09-08-1987",
      fee:"250",
      mobile:"+91 92226 25629",
      email:"ahmed@gmail.com",
      city:"Jaipur",
      pincode:"626023",
      license:"RJ642611",
      exp:"14",
      edu:"Diplomo in Physiotherapy",
      hos:"-",
      total:"4",
      language:"Hindi",
      associate:"Rajasthan Physiotheropist Forum",
      status:"Track",
    },
  ]

  return (
    <>
     {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title carrer-job-title ms-4">
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "0%" }}>Home Healthcare Service Providers</span>
              </Link>
              <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>></span>
              <Link
                to=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span>List of Home Healthcare Service Providers</span>
              </Link>
            </div>

            <div className="healtharticle-addnew">
              <div
                id="addnewpost"
                onClick={handleHomeHealthcareServiceProviderForm}
                className="addnewhealth"
                style={{ width: "250px" }}
              >
                <div>
                  {/* <BsFillPlusCircleFill /> */}
                  <img src={buttonadd} alt="" />
                </div>
                <div>
                  <button>Add New Service Provider</button>
                </div>
              </div>
            </div>

            <div className="healtharticle-show mt-5">
              <div>Show</div>
              <div>
                <select
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    // onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="healtharticle-table home-healthcare-service-provider">
              <table>
                <thead>
                  <tr>
                  <td>S.No</td>
                    <td>Name of Doctor</td>
                    <td>Specialisation (Primary)</td>
                    <td>Specialisation (Secondary)</td>
                    <td>Date of On-boarding</td>
                    <td>Gender</td>
                    <td>Age</td>
                    <td>DOB</td>
                    <td>Fees (INR) Per Hour</td>
                    <td>Registered Mobile Number</td>
                    <td>Registered Email</td>
                    <td>City / Town</td>
                    <td>Pincode</td>
                    <td>License / Registration Number</td>
                    <td>Experience in (Yrs)</td>
                    <td>Education Qualification</td>
                    <td>Name of Hospital / Clinic Employed at or Owned</td>
                    <td>Total Bookings Completed</td>
                    <td>Language Known</td>
                    <td>Associated Councils</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((x, i)=>{
                      return(
                        <tr>
                          <td>{x.sno}</td>
                          <td>{x.name}</td>
                          <td>{x.spl1}</td>
                          <td>{x.spl2}</td>
                          <td>{x.date}</td>
                          <td>{x.gender}</td>
                          <td>{x.age}</td>
                          <td>{x.dob}</td>
                          <td>{x.fee}</td>
                          <td>{x.mobile}</td>
                          <td>{x.email}</td>
                          <td>{x.city}</td>
                          <td>{x.pincode}</td>
                          <td>{x.license}</td>
                          <td>{x.exp}</td>
                          <td>{x.edu}</td>
                          <td>{x.hos}</td>
                          <td>{x.total}</td>
                          <td>{x.langugae}</td>
                          <td>{x.associate}</td>
                          <td>
                          <span 
                              className='doctor-edit'
                              onClick={()=>{
                                handleEdit()
                              }}
                            >Edit</span>
                            <span className='doctor-edit-logo'></span>
                            <span className='doctor-delete'>Delete</span>
                            <span className='doctor-delete-logo'></span>
                            <span 
                              className='doctor-view'
                              onClick={()=>{
                                navigate("/healthcare-view")
                              }}
                            >View</span>
                            <span className='doctor-view-logo'></span>
                            <span 
                              className='healthcare-activate'
                              onClick={()=>{
                                if(x.status === "Active"){
                                  navigate("/healthcare-activate")
                                }
                                if(x.status === "Track"){
                                  navigate("/healthcare-track")
                                }
                              }}
                            >{x.status}</span>
                            <span className='doctor-activate-logo'></span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* </Home> */}
    </>
  )
}

export default AddNewServiceProvider