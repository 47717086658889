import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

export default function HealthArticleView() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [desc, setDesc] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}health-article/get/${id}`)
      .then((res) => {
        console.log(res.data.metatags);
        setData(res.data);
        setDesc(res.data.description);
      });
  };

  return (
    <div>
      {/* <Home> */}
      <div onClick={() => navigate("/healtharticle")} className="rose back-button">
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
        <div className="healtharticle-title blog-view-title mb-5">
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Dashboard</span>
          </Link>
          <span style={{paddingLeft:"1%"}}>></span>
          <Link
            to="/healtharticle"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              fontWeight: "bold",
              paddingLeft: "0.4%",
            }}
          >
            <span>Health Article</span>
          </Link>
          <span style={{paddingLeft:"0.4%"}}>></span>
          <span style={{paddingLeft:"0.4%"}}>View Health Articles</span>
        </div>
        <div className="healthArticleView mb-5">
          <div className="row">
            <div className="menu-field col-md-3 me-0">
              <p className="form-label " htmlFor="">
                Menu
              </p>
              {/* <input
                type="text"
                className="form-control menu-input  mt-2 "
                value={data.menu}
                style={{border:"1px solid transparent"}}
                name=""
                id=""
              /> */}
              <div className="blog-view-text-half">{data.menu}</div>
            </div>
            <div className="category-field col-md-3">
              <p className="form-label " htmlFor="">
                Category
              </p>
              {/* <input
                type="text"
                className="form-control mt-2 category-in "
                name=""
                id=""
                value={data.category}
                style={{border:"1px solid transparent"}}
              /> */}
              <div className="blog-view-text-half">{data.category}</div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <p className="form-label" htmlFor="">
              Title
            </p>
            {/* <input
              type="text"
              name=""
              id=""
              className="form-control"
              value={data.title}
            /> */}
            <div className="blog-view-text-full">{data.title}</div>
          </div>
          <div>
            <div className="row">
              <div className="col-md-3">
                <label htmlFor="" className="form-label mt-4 ">
                  Active
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="Active"
                      value={data.active}
                      id="option1"
                      checked={data.active === true ? "checked" : null}
                    />
                    <label htmlFor="option1" className="ms-4  mt-1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Active"
                      value={data.active}
                      id="option2"
                      checked={data.active === false ? "checked" : null}
                    />
                    <label htmlFor="option2" className="ms-4 mt-1">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <label htmlFor="" className="form-label mt-4 ">
                  Featured
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="Featured"
                      value={data.featured}
                      id="option1"
                      checked={data.featured === true ? "checked" : null}
                    />
                    <label htmlFor="option1" className="ms-4 mt-1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Featured"
                      value={data.featured}
                      id="option2"
                      checked={data.featured === false ? "checked" : null}
                    />
                    <label htmlFor="option2" className="ms-4 mt-1">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="menu-field col-md-3 me-0">
                <p className="form-label " htmlFor="">
                  Author Name
                </p>
                {/* <input
                type="text"
                className="form-control menu-input  mt-2 "
                value={data.menu}
                style={{border:"1px solid transparent"}}
                name=""
                id=""
              /> */}
                <div className="blog-view-text-half-bor">
                  {data.authordetails}
                </div>
              </div>
              <div className="category-field col-md-3">
                <p className="form-label " htmlFor="">
                  Author Specialization
                </p>
                {/* <input
                type="text"
                className="form-control mt-2 category-in "
                name=""
                id=""
                value={data.category}
                style={{border:"1px solid transparent"}}
              /> */}
                <div className="blog-view-text-half-bor">
                  {data.authorSpecialization}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="videoUrl" className="form-label">
                Description
              </p>
              {/* <input
                type="text"
                className="form-control w-75"
                name=""
                id="videoUrl"
                value={data.videoUrl}
              /> */}
              <div
                dangerouslySetInnerHTML={{ __html: data.description }}
                className="blog-view-text-full-des"
              ></div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="videoUrl" className="form-label">
                Video Url
              </p>
              {/* <input
                type="text"
                className="form-control w-75"
                name=""
                id="videoUrl"
                value={data.videoUrl}
              /> */}
              <div className="blog-view-text-full">{data.videoUrl}</div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="metaTitle" className="form-label">
                Meta Title
              </p>
              {/* <input
                type="text"
                className="form-control "
                name=""
                id="metaTitle"
                value={data.metatitle}
              /> */}
              <div className="blog-view-text-full">{data.metatitle}</div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="metaDescription" className="form-label">
                Meta Description
              </p>
              {/* <input
                type="text"
                className="form-control "
                name=""
                id="metaDescription"
                value={data.metadescription}
              /> */}
              <div className="blog-view-text-full">{data.metadescription}</div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="metakeyword" className="form-label">
                Meta Keywords
              </p>
              {/* <input
                type="text"
                className="form-control "
                name=""
                id="metakeyword"
                value={data.metakeywords}
              /> */}
              <div className="blog-view-text-full">{data.metakeywords}</div>
            </div>
            <div className="col-md-12 mt-3">
              <p htmlFor="metatag" className="form-label">
                Meta Tags
              </p>
              {/* <input
                type="text"
                className="form-control "
                name=""
                id="metatag"
                value={data.metatags}
              /> */}
              <div className="blog-view-text-full metatags-view">
                {
                  data.metatags && data.metatags.map((el)=>{
                    return(
                      <div className="v-dynamic-tags">{el.slice(1)}</div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-md-12 mt-3 canonical ">
              <p htmlFor="canonicalUrl" className="form-label">
                Canonical Url
              </p>
              {/* <input
                type="text"
                className="form-control mb-3"
                name=""
                id="canonicalUrl"
                value={data.canonicalUrl}
              /> */}
              <div className="blog-view-text-full canonicalurl">{data.canonicalUrl}</div>
            </div>
          </div>
        </div>
      {/* </Home> */}
    </div>
  );
}
