import React from 'react'
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Home from '../../Home/Home'
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import search from "../../../assets/images/searchIcon.png";
import buttonadd from "../../../assets/images/plus.png";
import "./index.scss"
import { FaAngleRight } from "react-icons/fa";

const DoctorSpecialisation = () => {

  const navigate = useNavigate();

  const handleDoctorSpecialisationForm=()=>{
    navigate("/doctor-add-new-splecialisation-form")
  }

  const data =[
    {
      spl:"Andrology",
      image:"",
      totalConsult:"335",
      totalInclinic:"264",
      status:"Active",
    },
    {
      spl:"Ayurveda",
      image:"",
      totalConsult:"697",
      totalInclinic:"509",
      status:"Active",
    },
    {
      spl:"Cardiology",
      image:"",
      totalConsult:"761",
      totalInclinic:"627",
      status:"Active",
    }
  ]

  return (
    <>
     {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title carrer-job-title ms-4">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctors</span>
              </Link>
              <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>></span>
              <Link
                to=""
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span>Doctor Specialisation</span>
              </Link>
            </div>

            <div className="healtharticle-addnew">
              <div
                id="addnewpost"
                onClick={handleDoctorSpecialisationForm}
                className="addnewhealth"
                style={{ width: "300px" }}
              >
                <div>
                  {/* <BsFillPlusCircleFill /> */}
                  <img src={buttonadd} alt="" />
                </div>
                <div>
                  <button>Add New Doctor Specialisation</button>
                </div>
              </div>
            </div>

            <div className="healtharticle-show mt-5">
              <div>Show</div>
              <div>
                <select
                    // value={limit}
                    // onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    // onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="healtharticle-table add-new-doctors-spl-table">
              <table>
                <thead>
                  <tr>
                    <td>S.No</td>
                    <td>Doctor Specialisation</td>
                    <td>Image / Icon</td>
                    <td>Total Doctor in Online Consultation</td>
                    <td>Total Doctor in In-Clinic Appointment</td>
                    <td>Status</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((x, i)=>{
                      return(
                        <tr>
                          <td>{i+1}</td>
                          <td>{x.spl}</td>
                          <td>{x.image}</td>
                          <td>{x.totalConsult}</td>
                          <td>{x.totalInclinic}</td>
                          <td>{x.status}</td>
                          <td>
                            <span className='doctor-spl-inactive'>Inactive</span>
                            <span className='doctor-spl-inactive-logo'></span>
                            <span className='doctor-spl-delete'>Delete</span>
                            <span className='doctor-spl-delete-logo'></span>
                            <span className='doctor-spl-edit' onClick={()=>{
                              navigate("/doctor-add-new-splecialisation-edit")
                            }}>Edit</span>
                            <span className='doctor-spl-edit-logo'></span>
                            <span className='doctor-spl-view' onClick={()=>{
                              navigate("/doctor-add-new-splecialisation-view")
                            }}>View</span>
                            <span className='doctor-spl-view-logo'></span>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      {/* </Home> */}
    </>
  )
}

export default DoctorSpecialisation