import React from "react";
import Home from "../../../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const ViewDoctorForm = () => {

    const navigate = useNavigate();

  return (
   <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/add-new-doctors")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle-title ms-0">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctor</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/add-new-doctors"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  List of Doctors for Online Consultation
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>View Doctor</span>
            </div>
        <div>

          <div className="addnew-form1-autor-detail mt-3 d-flex">
            
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Name of Doctor
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Primary Specialisation
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Main Stream
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

          </div>

          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Secondary / Sub Specialisation
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              By Health Concerns
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Age
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

          </div>

          <div className="addnew-form1-autor-detail mt-3 d-flex">

          <div className="col">
              <label htmlFor="" className="mb-3">
              Verified  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    No
                  </label>
                </div>
              </div>
            </div>

            <div className="menu-field col-md-3 me-5">
                <p className="form-label " htmlFor="">
                Doctor's Experience (Yrs)
                </p>
                <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="col">
              <label htmlFor="" className="mb-3 ms-3 ">
              Gender  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    Male
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    Female
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="gender"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    Others
                  </label>
                </div>
              </div>
            </div>

          </div>

          {/* Four */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Date of Onboarding (Active Since)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Online Doctor Consultation Fees (INR)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Educational Qualification
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

          </div>

          {/* Five */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Educational Institution
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Educational Qualification 2
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Educational Institution
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

          </div>

          {/* Six */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Known Languages
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Registered Mobile Number
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Registered Email ID
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

          </div>

          {/* Seven */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              City / Town
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              State
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-6 ms-5">
              <p className="form-label " htmlFor="">
              Logo of the above Hospital Clinic
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

          </div>

          {/* Eight */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">

          <div className="col">
              <label htmlFor="" className="mb-3">
              Available on Online Consultation Modes  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    All (Audio, Video, Chat)
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    Chat
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    Video
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="verified"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    Audio
                  </label>
                </div>
              </div>
            </div>

            </div>

            {/* Nine */}
          <div className="addnew-form1-autor-detail mt-3 d-flex">

          <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload Header / Cover Video
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            <div className="menu-field col-md-6 ms-5">
              <p className="form-label " htmlFor="">
              Upload Profile Image
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

          </div>

           {/* Ten */}
           <div className="addnew-form1-autor-detail mt-3 d-flex">

           <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              About (Opt of Max 100 words)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

           </div>

           {/* Eleven */}
           <div className="addnew-form1-autor-detail mt-3 d-flex">

           <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Medical Registration Number
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

            <div className="menu-field col-md-6 ms-5">
              <p className="form-label " htmlFor="">
              Upload Document of MCI
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

           </div>

           {/* Twelve */}
           <div className="addnew-form1-autor-detail mt-3 mb-5 d-flex">

           <div className="menu-field col-md-6">
              <p className="form-label " htmlFor="">
              Upload a Valid Photo ID of Doctor
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>

            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Membership (Opt)
              </p>
              <div className="blog-view-text-half-bor"></div>
            </div>

           </div>

        </div>
      {/* </Home> */}
    </>
  );
};

export default ViewDoctorForm