import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

export default function ViewCategory() {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}health-article-category/get/${id}`)
      .then((res) => {
        setData(res.data);
      });
  };

  return (
    <>
    {/* <Home> */}
      <div onClick={() => navigate("/Category")} className="rose back-button">
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container category-container ps-3">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Dashboard</span>
            </Link>
            <span style={{ fontSize: "15px", paddingLeft: "0.4%" }}>></span>
            <span style={{ paddingLeft: "0.4%" }}>View Category</span>
          </div>
          <div className="row mt-3">
            <div className="menu-field col-md-3 me-0">
              <p className="form-label " htmlFor="">
                Menu
              </p>
              {/* <input
                type="text"
                className="form-control menu-input  mt-2 "
                value={data.menu}
                style={{border:"1px solid transparent"}}
                name=""
                id=""
              /> */}
              <div className="blog-view-text-half-bor">{data.menu}</div>
            </div>
            <div className="category-field col-md-3">
              <p className="form-label " htmlFor="">
                Category
              </p>
              {/* <input
                type="text"
                className="form-control mt-2 category-in "
                name=""
                id=""
                value={data.category}
                style={{border:"1px solid transparent"}}
              /> */}
              <div className="blog-view-text-half-bor">{data.category}</div>
            </div>
          </div>

          <div className="row">
            <label htmlFor="" className="mb-3 mt-3 ">
              Active Status <span className="star">*</span>
            </label>
            <div className="d-flex p-0">
              <div className="form-check form-check-inline m-0">
                <input
                  className=" me-3"
                  type="radio"
                  name="active"
                  id="status"
                  value={data.active}
                  checked={data.active === true ? "checked" : null}
                />
                <label class="" htmlFor="inline1">
                  yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="active"
                  id="status"
                  value={data.active}
                  checked={data.active === false ? "checked" : null}
                />
                <label class="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    {/* </Home> */}
    </>
  );
}
