import React from "react";
import "./header.scss";
import header_logo from "../../../assets/svg/logo.svg";
import profile from "../../../assets/svg/avatar.svg";
import logout_icon from "../../../assets/svg/logout_icon.svg";
import pipe_icon from "../../../assets/svg/pipe-icon.svg";
import Logout from "../../Account/Logout/Logout";
import { useNavigate } from "react-router";

const Header = () => {
  
  const navigate = useNavigate();

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
  };

  const time = formatAMPM(new Date());

  let currentDate = `${day}-${month}-${year}`;

  const handleHome = () => {
    navigate("/");
  };

  return (
    // <div className="header">
    //   <div className="header-container">
    //     <div className="header-logo">
    //       <img src={header_logo} alt="" />
    //     </div>

    //     <div className="header-current-time-date">
    //       <div>{time}</div>
    //       <div>{currentDate}</div>
    //     </div>
    //     <div className="header-profile">
    //       <img src={profile} alt="" />
    //     </div>
    //     <div className="header-profile-name">Admin</div>
    //     <div className="pipe-line d-flex justify-content-center align-items-center">
    //         <img src={pipe_icon} alt="" />
    //       </div>
    //     <div className="header-logout">
    //       <div
    //         // className="btn"
    //         data-bs-toggle="modal"
    //         data-bs-target="#exampleModal"
    //       >

    //         <div>
    //         <img src={logout_icon} alt="" />
    //         </div>
    //         <div>Log out</div>
    //       </div>
    //       {/* <Logout /> */}
    //     </div>
    //   </div>
    // </div>

    <div className="header ">
      <div className="container-fluid">
        <div className="row  pt-3">
          <div className="left-container col d-flex align-items-center ms-3">
            <img onClick={handleHome} src={header_logo} alt="" />
          </div>

          <div className="right-container col d-flex justify-content-between  align-items-center col-4 me-3 mt-1">
            <div className="time-date d-flex ">
              <div className="time text-muted mt-1  me-1">{time}</div>
              <div className="date text-muted mt-1  me-1">|</div>
              <div className="curdate text-muted mt-1 ms-1 me-1">
                {currentDate}
              </div>
            </div>
            <div className="img-profile">
              <img src={profile} alt="" />
            </div>
            <div className="adminName fw-bold">Admin</div>
            <img src={pipe_icon} alt="" />
            <div className="logout d-flex">
              <div className="logout-icon me-2 text-center">
                <img src={logout_icon} alt="" />
              </div>

              <div
                className="header-logout"
                data-bs-toggle="modal"
                data-bs-target="#logoutModal"
              >
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
