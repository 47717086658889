import React from "react";
import "./SucessModel.scss"

const Deletepopup= ({ id, handleDelete }) => {
  return (
    <div>
      <div className="modal-backdrop fade show"></div>
      <div
        class="modal fade show"
        id="deleteModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div class="modal-dialog " style={{ marginLeft: "35rem", width:"500px" }}>
          <div
            style={{ width: "95%", padding: "1.7rem" }}
            class=" logout-content"
          >
            <div class="modal-content p-4">
            <div>
           
            <p className="text-center ">Are you sure you want to Delete?</p>

            
          
              <div 
                 className="text-center mt-3 me-4 d-flex justify-content-center "
              >
                <button
                  className="btn me-3 " 
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{
                    border: "1px solid gray",
                    color: "gray",
                    width: "90px",
                    height: "40px",
                    marginLeft:"10%"
                  }}
                  onClick={()=>{
                    window.location.reload()
                  }}
                >
                  Cancel
                </button> 
                
                <button
                  className="btn   "
                  style={{
                    background: " #fff 0% 0% no-repeat padding-box",
                    color: "red",
                    border: "1px solid red",
                    width: "90px",
                    height: "40px",
                  }}
                  onClick={(e) => handleDelete(id)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
  

  )


  
}

export default Deletepopup;





