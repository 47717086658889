import React, { useEffect, useState } from "react";
import "./healtharticle.scss";
import Home from "../../Home/Home";
import add from "../../../assets/images/buttonAdd.png";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaAngleRight } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router";
import edit from "../../../assets/svg/edit.svg";
import inactive from "../../../assets/svg/inactive.svg";
import featuredImage from "../../../assets/images/featured-image.PNG";
import { Link } from "react-router-dom";
import Deletepopup from "../../modal/Deletepopup";
import Moment from "moment";
import search from "../../../assets/images/searchIcon.png"
import LoadingOverlay from "react-loading-overlay";

const HealthArticle = () => {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("id-desc");
  const [filter, setFilter] = useState({ name: "" });
  const [records, setRecords] = useState([]);
  const [del, setDel] = useState(false)
  const [id, setId] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  const handleActive = (id, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}health-article/update/active/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  useEffect(() => {
    list_api_call();
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article/admin-blog-list`, {
        ...filter,
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((response) => {
        setRecords(response.data.rows);
        setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
      //   console.error(err);
      //   alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows); 
  };

  const handleNewHealthArticle = () => {
    navigate("/healtharticleaddnew");
  };

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}health-article/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false)
      });
    });
  };

  const handleMenu = () => {
    navigate("/healtharticlemenu");
  };
  const handleView = (id) => {
    navigate(`/healthArticleView/${id}`);
  };

  const handleEdit=(id)=>{
    navigate(`/healtharticleEdit/${id}`)
  }

  return (
    <>
      {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container ms-2">
            <div className="healtharticle-title ms-2">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{paddingLeft:"1%"}}>></span>
              <span>Health Articles</span>
            </div>
            <div className="healtharticle-addnew ms-2">
              <div onClick={handleNewHealthArticle} className="addnewhealth">
                <div>
                  <img src={add} alt="" />
                </div>
                <div>
                  <button> Add New Health Articles</button>
                </div>
              </div>
              <div 
                 onClick={handleMenu} 
                 className="managehealth" 
                //  style={{width:"40%"}}
              >
                <div>
                  <button>Manage Menu & Category & Comments</button>
                </div>
                <div>
                  <FaAngleRight />
                </div>
              </div>
            </div>
            <div className="healtharticle-show ms-2">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <LoadingOverlay
              active={isLoading}
              spinner
              text="Loading..."
              styles={{
                overlay: (base) => ({
                  ...base,
                  backgroundColor: "transparent"
                }),
                spinner: (base) => ({
                  ...base,
                  '& svg circle': {
                    stroke: '#D5497C'
                  }
                }),
                content: (base) => ({
                  ...base,
                  color: "#CB1B5B"
                })
              }}
            >
              <div className="healtharticle-table blog-table ms-2">
                <table>
                  <thead>
                    <tr>
                      <td>S.No</td>
                      <td>Actions</td>
                      <td>Title</td>
                      <td>Menu</td>
                      <td>Category</td>
                      <td>Date of Publishing</td>
                      <td>Time</td>
                      <td>Trending</td>
                      <td>Featured Images</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {(filtered_records.length > 0
                      ? filtered_records
                      : records
                    ).map((el, i) => {
                      if (el.show === undefined || el.show === true)
                        return (
                          <tr key={el._id}>
                            <td>{(current_page - 1) * limit + (i + 1)}</td>
                            <td>
                            <span
                                className="inactive-health"
                                onClick={() => {
                                  handleActive(el._id, el.active);
                                }}
                              >
                                {el.active ? "Inactive" : "Active"}
                              </span>
                              <span className="inactive-icon">
                                <img src={inactive} alt="" />
                              </span>
                          
                              <span
                                onClick={() => {
                                  setId(el._id)
                                  setDel(true);
                                }}
                                className="delete"
                                type="submit"
                              >
                                Delete
                              </span>
                              <span className="delete-icon"></span>
                              
                              <span className="edit-health" onClick={()=>handleEdit(el.slugurl)}>Edit</span>
                              <span className="edit-icon">
                                <img src={edit} alt="" />
                              </span>
                            
                              <span
                                className="view"
                                onClick={() => handleView(el._id)}
                              >
                                View
                              </span>
                              <span className="view-icon"></span>
                            </td>
                            <td>{el.title}</td>
                            <td>{el.menu}</td>
                            <td>{el.category}</td>
                            <td>{Moment(el.createdAt).format("DD MMMM YYYY")}</td>
                            <td>{Moment(el.createdAt).format('LT')} (IST)</td>
                            <td>No</td>
                            <td>
                              <img src={featuredImage} alt="" />
                            </td>

                            <td>{el.active ? "Active" : "Inactive"}</td>
                          </tr>
                        );
                    })}
                  </tbody>
                </table>
              </div>
            </LoadingOverlay>
          </div>
        </div>
        <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
          <input
            type="button"
            className="page-item page-link"
            value="<<"
            // onClick={(e) => setCurrentPage(1)}
            disabled={current_page === 1}
          />
          <input
            type="button"
            className="page-item page-link"
            value="<"
            // onClick={(e) => setCurrentPage(current_page - 1)}
            disabled={current_page === 1}
          />

          {current_page > 1 && (
            <input
              type="button"
              value={current_page - 1}
              onClick={(e) => {
                // setCurrentPage(current_page - 1);
              }}
            />
          )}
          <input
            className="page-item page-link"
            type="button"
            value={current_page}
          />
          {current_page < pages && (
            <input
              type="button"
              value={current_page + 1}
              onClick={(e) => {
                // setCurrentPage(current_page + 1);
              }}
            />
          )}
          <input
            type="button"
            value=">"
            className="page-item page-link"
            // onClick={(e) => setCurrentPage(current_page + 1)}
            disabled={current_page === pages}
          />
          <input
            type="button"
            value=">>"
            className="page-link"
            // onClick={(e) => setCurrentPage(pages)}
            disabled={current_page === pages}
          />
        </div>
      {/* </Home> */}
      {
       del === true
         && 
       <Deletepopup
        handleDelete={handleDelete}
        id={id}
       />
      }
    </>
  );
};

export default HealthArticle;
