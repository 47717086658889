import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import SucessModal from "../../../modal/SucessModal";
import "./positionform.scss";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const PositionForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [checked, setChecked] = useState(true)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  const onSubmit = (fields) => {
    let updatedFileds = {...fields,active:checked}
    axios
      .post(
        `${process.env.REACT_APP_API_URL}career-add-position/create`,
        updatedFileds
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };

  const [data, setData] = useState([])

  useEffect(()=>{
    getDepartment()
  }, [])

  const getDepartment=()=>{
    axios.post(`${process.env.REACT_APP_API_URL}career-add-department/list`)
    .then((res)=>{
      console.log(res.data.rows);
      setData(res.data.rows)
    })
  }

  return (
    <>
      {/* <Home> */}
        <div 
            onClick={()=>navigate("/careerposition")} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        <div className="healtharticle-title">
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "black", fontSize: "15px" }}
          >
            <span style={{ paddingLeft: "5%" }}>Dashboard</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft:"1%" }}>&#62;</span>
          <Link
            to="/careers"
            style={{ textDecoration: "none", color: "black", fontSize: "15px" }}
          >
            <span style={{ paddingLeft: "5%" }}>Careers</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>&#62;</span>
          <span style={{ paddingLeft: "0.5%", fontSize: "15px" }}>
            Add Position / Designation
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-pos-form">
          <div className="row car-pos-form">
            <div className="col-5">
              <label htmlFor="" className=" small mb-3">
                Select Department /Function Name{" "}
                <span className="star">*</span>
              </label>
              <select
                style={{height:"50px"}}
                className="form-control w-75 "
                name=""
                id="functionName1"
                {...register("functionName1", { required: true })}
              >
                <option value="select" selected hidden>Select</option>
                {
                  data.map((el)=>{
                    return(
                      <option value={el.department}>{el.department}</option>
                    )
                  })
                }
              </select>
              {errors.functionName1 && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
            </div>
            <div className="col-5">
              <label htmlFor="" className=" small mb-3">
                Type Department /Function Name{" "}
                <span className="star">*</span>
              </label>
              <input
                type="text"
                placeholder="Type here.."
                className="form-control w-75"
                name=""
                id="functionName2"
                {...register("functionName2", { required: true })}
              />
              {errors.functionName2 && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Department
                  </div>
                )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label className=" small mb-2" htmlFor="">
                Status <span className="star">*</span>
              </label>
              <div className="d-flex">
                <div className="form-check form-check-inline me-3 mt-1">
                  <input
                    className="form-check-input me-3"
                    type="radio"
                    name="status"
                    id="status"
                    value="Active"
                    checked={checked ? "checked" : ""}
                    onClick={()=>setChecked(true)}
                    {...register("status", { required: true })}
                  />
                  <label htmlFor="option-1" className="text-dark mt-1">
                    Active
                  </label>
                </div>
                <div className="form-check form-check-inline mt-1">
                  <input
                    className="form-check-input  me-3"
                    type="radio"
                    name="status"
                    id="status"
                    value="Inactive"
                    checked={checked ? "" : "checked"}
                    onClick={()=>setChecked(false)}
                    {...register("status", { required: true })}
                  />
                  <label htmlFor="option-2" className="text-dark mt-1">
                    Inactive
                  </label>
                </div>
              </div>
            </div>
            {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
          </div>
          <div className="car-pos-form-button">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      {/* </Home> */}
      {
       success && 
       <SucessModal 
        page={"/careerposition"}
        name="Career Position"
        post='posted'
       />
      }
    </>
  );
};

export default PositionForm;
