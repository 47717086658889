import React, { useState } from "react";
import "./form.scss";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import Home from "../../../Home/Home";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../../modal/SucessModal";

const OthersForm = () => {

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const onSubmit = (fields) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}types/create`,
        fields
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };
  return (
    <>
    {/* <Home> */}
      <div
        onClick={() => navigate("/master-others")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/master-primary-specialisation"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Masters</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to="/master-others"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Others</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>></span>
          <span style={{ paddingLeft: "0.4%", fontSize: "15px" }}>
            Types
          </span>
        </div>
        <form 
           onSubmit={handleSubmit(onSubmit)} 
           className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-5">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Types{" "}
                <span className="star">*</span>
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("type", { required: true })}
                placeholder="Type here..."
              />
              {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Primary Specialisation
                  </div>
                )}
            </div>

            <div className="col-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Active <span className="star">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline me-3 mt-1">
                    <input
                      className="form-check-input me-3"
                      type="radio"
                      name="active"
                      id="active"
                      {...register("active", { required: true })}
                      value={true}
                      checked={checked ? "checked" : ""}
                      onClick={() => setChecked(true)}
                    />
                    <label htmlFor="option-1" className="text-dark ">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input
                      className="form-check-input  me-3"
                      type="radio"
                      name="active"
                      id="active"
                      value={false}
                      checked={checked ? "" : "checked"}
                      onClick={() => setChecked(false)}
                      {...register("active", { required: true })}
                    />
                    <label htmlFor="option-2" className="text-dark">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.active && (
                  <div className={`invalid-feedback d-block`}>
                    Please select an Option
                  </div>
                )}
              </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit" style={{ marginTop: "5%" }}>
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    {/* </Home> */}
    {success && (
        <SucessModal page={"/master-others"} name="Other Types" post='posted'/>
      )}
  </>
  )
}

export default OthersForm