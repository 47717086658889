import axios from "axios";
import React, { useState, useEffect } from "react";
import Home from "../../Home/Home";
import { useNavigate } from "react-router";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import Moment from "moment";
import search from "../../../assets/images/searchIcon.png";
import { FaAngleRight } from "react-icons/fa";

// actions icons
import contact from "../../../assets/images/HealthSy/contact.png";
import onboard from "../../../assets/images/HealthSy/onboard.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import Deletepopup from "../../modal/Deletepopup";

const DoctorDemo = () => {
  const [isChecked, setIsChecked] = useState(true);

  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({ name: "" });
  const [records, setRecords] = useState([]);

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [specialisationdata, setSpecialisationData] = useState([]);
  const [daydata, setDayData] = useState([]);
  const [submitfilter, setSubmitFilter] = useState(false);
  // const[spltdata, setSpltdata]=useState('')
  // const[checked1, setChecked1]=useState(null)
  // const[checked2, setChecked2]=useState(null)

  // const[specialisation, setSpecialisation]=useState([])
  // const[preferredDay, setPreferredDay]=useState([])

  // useEffect(()=>{
  //   getSpecialisationData()
  //   getPreferredDayData()
  // }, [])

  // const getSpecialisationData=()=>{
  //   axios
  //   .post(`${process.env.REACT_APP_API_URL}primary-specialisation/list`)
  //   .then((res)=>{
  //     setSpecialisation(res.data.rows)
  //   })
  // }

  // const getPreferredDayData=()=>{
  //   axios
  //   .post(`${process.env.REACT_APP_API_URL}days/list`)
  //   .then((res)=>{
  //     setPreferredDay(res.data.rows)
  //   })
  // }

  const specialisation = [
    {
      text: "Andrology",
    },
    {
      text: "Ayurveda",
    },
    {
      text: "Allergist",
    },
    {
      text: "Bariatrics",
    },
    {
      text: "Cardiology",
    },
    {
      text: "Cosmetology",
    },
    {
      text: "Dentistry",
    },
  ];

  const preferredDay = [
    {
      text: "Today",
    },
    {
      text: "Tomorrow",
    },
    {
      text: "Day After",
    },
  ];

  useEffect(() => {
    list_api_call();
  }, [limit, sort, skip]);

  useEffect(() => {
    filter_call();
  }, [daydata, specialisationdata, submitfilter]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/sortlist`, {
        // ...filter,
        // skip: skip,
        // limit: limit,
        key: "demo",
        value: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data);
        // setRowCount(response.data);
        // setPages(Math.ceil(response.data / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_call = () => {
    if (submitfilter === true) {
      axios
        .post(`${process.env.REACT_APP_API_URL}filters/healthsy-filter`, {
          key: "demo",
          values: {
            specialization: specialisationdata,
            day: daydata,
            // skip: skip,
            // limit: limit,
          },
        })
        .then((res) => {
          setRecords(res.data);
        });
    }
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).indexOf(filter) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleDoctor = () => {
    navigate("/doctor-registration");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/RetailPharmacy");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };

  //Actions

  //Delete Functionality
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}doctor-book-a-demo/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  //Contact Functionality
  const handleContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: true,
          notContacted: false,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //NotContact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: false,
          notContacted: true,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //On-Board Functionality
  const handleOnBoard = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-book-a-demo/update/${id}`,
        {
          contact: false,
          notContacted: false,
          onBoard: true,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="top-container ps-3 ">
            <div className="row">
              <div className="healtharticle-title ms-0">
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/dashboard"
                  >
                    Dashboard
                  </Link>
                </span>
                <span className="bread-crumb">&#62;</span>
                <span>
                  <Link
                    style={{ textDecoration: "none", color: "black" }}
                    to="/doctor-registration"
                  >
                    HealthSynergy
                  </Link>
                </span>
                <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
                  &#62;
                </span>
                <span style={{ paddingLeft: "0.4%" }}>Book a Demo</span>
              </div>
            </div>

            <div className="careers-tab  ">
              <div className="career-tab-career " onClick={handleDoctor}>
                Doctors
              </div>
              <div className="career-tab-jobpost " onClick={handleRetail}>
                Retail Pharmacy
              </div>
              <div className="career-post-department" onClick={handleService}>
                Home Healthcare Service Providers
              </div>
              <div
                className="career-post-position  other"
                onClick={handleOthers}
              >
                Others
              </div>
              <div className="career-post-position book">Book a Demo</div>
            </div>
            <div className="healtharticle-show mt-5 ms-2">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setSortShow(!sortshow);
                        }}
                      >
                        {sortName}
                      </div>
                      {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc">
                            <div style={{ color: "red" }}>Oldest</div>
                          </option>
                          <option value="name-asc">
                            <div>Newest</div>
                          </option>
                          <option value="id-asc">
                            <div>Alphabetical ↑</div>
                          </option>
                          <option value="id-asc">
                            <div>Alphabetical ↓</div>
                            <span>
                              <img src="" alt="" />
                            </span>
                          </option>
                        </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setFilterShow(true);
                        }}
                      >
                        Filter
                      </div>
                      {/* <select
                          name=""
                          id=""
                          // onChange={handleSort}
                        >
                          <option value="">Filter</option>
                          <option value="Asc to Des">Asc to Des</option>
                          <option value="Des to Asc">Des to Asc</option>
                        </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="SortByposition">
                    <div>
                      <select onChange={(e) => setSort(e.target.value)}>
                        <option>Sort by</option>
                        <option value="id-desc">Latest</option>
                        <option value="name-asc">By Name</option>
                        <option value="id-asc">Oldest</option>
                      </select>
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                  <div className="SortByDepartment">
                    <div>
                      <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select>
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div> */}
            </div>
          </div>
          <div className="healtharticle-table doctor-demo-table ms-2">
            <table className="">
              <thead className="t-head ">
                <tr>
                  <th className="align-middle">S.No</th>
                  <th className="align-middle">Name</th>
                  <th className="align-middle">Email</th>
                  <th className="align-middle">Mobile Number</th>
                  <th className="align-middle">Specialisation</th>
                  {/* <th className="align-middle">Preferred Day</th> */}
                  <th className="align-middle">Preferred Contact Time</th>
                  <th className="align-middle">Registration Date and Time</th>
                  <th className="align-middle">Actions</th>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (x, i) => {
                    if (x.show === undefined || x.show === true)
                      return (
                        <tr key={x._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{x.name}</td>
                          <td>{x.email}</td>
                          <td>{x.mobile}</td>
                          <td>{x.specialization}</td>
                          {/* <td>{new Date(x.day).toLocaleDateString()}</td> */}
                          {/* <td>{Moment(x.createdAt).format("DD MMMM YYYY")}</td> */}
                          <td>{x.time}</td>
                          <td>
                            {Moment(x.createdAt).format("DD MMMM YYYY LT")}
                          </td>
                          <td>
                            <span
                              className={`contact ${
                                x.contact == true ? "contact-highlight" : ""
                              }`}
                              onClick={() => {
                                handleContact(x._id);
                              }}
                            >
                              Contact
                            </span>
                            <span className="contact-logo">
                              <img src={contact} alt="" />
                            </span>
                            <span
                              className={`onboard ${
                                x.onBoard == true ? "onboard-highlight" : ""
                              }`}
                              onClick={() => {
                                // if (
                                //   x.contact == true &&
                                //   x.notContacted == false
                                // ) {
                                //   handleOnBoard(x._id, x.onBoard);
                                // }
                                handleOnBoard(x._id);
                              }}
                            >
                              On-Board
                            </span>
                            <span className="onboard-logo">
                              <img src={onboard} alt="" />
                            </span>
                            <span
                              className={`notcontact ${
                                x.notContacted == true
                                  ? "notcontact-highlight"
                                  : ""
                              }`}
                              onClick={() => {
                                handleNotContact(x._id);
                              }}
                            >
                              Not Contacted
                            </span>
                            <span className="notcontacted-logo">
                              <img src={notcontact} alt="" />
                            </span>
                            <span
                              className="newdelete"
                              onClick={() => {
                                setId(x._id);
                                setDel(true);
                              }}
                            >
                              Delete
                            </span>
                            <span className="newdelete-logo">
                              <img src={newdelete} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(1);
              }}
            >
              <div
                style={{
                  color: textcolor == 1 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 1 ? "bold" : "",
                }}
              >
                Specialisation
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(2);
              }}
            >
              <div
                style={{
                  color: textcolor == 2 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 2 ? "bold" : "",
                }}
              >
                Preferred Day
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSpecialisationData([]);
                  setDayData([]);
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSubmitFilter(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Specialisation---------*/}
      {textcolor == 1 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {specialisation.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setSpecialisationData([
                          ...specialisationdata,
                          e.target.name,
                        ]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Preferred Day---------*/}
      {textcolor == 2 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Preferred Day</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {preferredDay.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setDayData([...daydata, e.target.name]);
                      }}
                      // onClick={()=>{
                      //   setChecked2(!checked2)
                      // }}
                      // checked={checked2?'checked':null}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*--------Sort By Portion---------*/}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor == 11 ? "#CB1B5B" : "",
              fontWeight: textcolor == 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor == 12 ? "#CB1B5B" : "",
              fontWeight: textcolor == 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor == 13 ? "#CB1B5B" : "",
              fontWeight: textcolor == 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor == 14 ? "#CB1B5B" : "",
              fontWeight: textcolor == 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DoctorDemo;
