import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import "./index.scss";
import Deletepopup from "../../modal/Deletepopup";
import { Link } from "react-router-dom";
import buttonadd from "../../../assets/images/plus.png";

const SocialMediaLink = () => {
  const navigate = useNavigate();

  const handleSocialMediaForm = () => {
    navigate("/settings-social-media-form");
  };

  const handleEdit = (id) => {
    navigate(`/master-others-edit/${id}`);
  };

  const handleView = (id) => {
    navigate(`/master-others-view/${id}`);
  };

  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}social-media-links/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("id-desc");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);

  useEffect(() => {
    list_api_call();
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}social-media-links/list`, {
        ...filter,
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data.rows);
        setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container">
          <div className="healtharticle-title carrer-job-title ms-4">
            <Link
              to="/settings-social-media-link"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Settings</span>
            </Link>
            <span style={{ paddingLeft: "1%", paddingRight: "0.4%" }}>></span>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Social Media Links</span>
            </Link>
          </div>

          <div className="healtharticle-addnew">
            <div
              id="addnewpost"
              onClick={handleSocialMediaForm}
              className="addnewhealth"
              style={{ width: "260px" }}
            >
              <div>
                {/* <BsFillPlusCircleFill /> */}
                <img src={buttonadd} alt="" />
              </div>
              <div>
                <button>Add New Social Media Link</button>
              </div>
            </div>
          </div>

          <div className="healtharticle-table settings-social-media-links">
            <table>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td2">LinkedIn</td>
                  <td className="career-jobpost-td3">Facebook</td>
                  <td className="career-jobpost-td12">Instagram</td>
                  <td className="career-jobpost-td12">Twitter</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (el, i) => {
                    if (el.show === undefined || el.show === true)
                      return (
                        <tr key={el._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{el.linkedin}</td>
                          <td>{el.facebook}</td>
                          <td>{el.instagram}</td>
                          <td>{el.twitter}</td>
                        </tr>
                      );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}
    </>
  );
};

export default SocialMediaLink;
