import React from "react";
import Home from "../../../Home/Home";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const ViewDoctorSpecialisation = () => {
  
  const navigate = useNavigate();

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/doctor-specialisation")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle-title ms-0">
              <Link
                to="/doctor-specialisation"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctor</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/doctor-specialisation"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Doctor Specialisation
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>View Doctor Specialisation</span>
            </div>
        <div>
          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="menu-field col-md-3 me-5">
              <p className="form-label " htmlFor="">
              Name of Doctor Specialisation
              </p>
              <div className="blog-view-text-half-bor">Name</div>
            </div>

            <div className="menu-field col-md-6 ms-5">
              <p className="form-label " htmlFor="">
              Upload Image / Icon for Doctor Specialisation
              </p>
              <div>
                <img 
                  src="" 
                  alt="" 
                  style={{
                    borderRadius:"8px",
                    width:"99px",
                    height:"64px",
                    border:"1px solid gray"
                  }}
                />
              </div>
            </div>
          </div>

          <div className="addnew-form1-autor-detail mt-3 d-flex">
            <div className="col">
              <label htmlFor="" className="mb-3 ms-3 ">
              Doctor Specialisation in Online Doctor Consultation  <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl1"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl1"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="col">
              <label htmlFor="" className="mb-3 ms-3 ">
              Doctor Specialisation in In-clinic Doctor Appointment <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl2"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl2"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="col ms-6">
              <label htmlFor="" className="mb-3 ms-3 ">
              Active Doctor Specialisation <span className="text-danger"></span>
              </label>
              <div className="d-flex ">
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl3"
                    id="status"
                    checked
                  />
                  <label class="" htmlFor="inline1">
                    yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="me-3"
                    type="radio"
                    name="doctorspl3"
                    id="status"
                  />
                  <label class="" htmlFor="inline2">
                    No
                  </label>
                </div>
              </div>
            </div>
            </div>
        </div>
      {/* </Home> */}
    </>
  );
};

export default ViewDoctorSpecialisation;
