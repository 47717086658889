import React, { useState } from "react";
import "./home.scss";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header/Header";
import submenu from "../../assets/svg/submenu-icon.svg";
import dashboardd from "../../assets/svg/dashboard-icon.svg";
import { FaAngleDown } from "react-icons/fa";
import Logout from "../Account/Logout/Logout";

const Home = ({ children }) => {

  const [healthsy, setHealthsy]=useState(null)
  const [masters, setMasters]=useState(null)
  const [popupdata, setPopupData]=useState(null)
  const [doctor, setDoctor]=useState(null)
  const [homehealth, setHomeHealth]=useState(null)
  const [retail, setRetail]=useState(null)
  const [clinic, setClinic]=useState(null)
  const [setting, setSetting]=useState(null)
  const [brocher, setBrocher]=useState(null)
  const [textcolor, setTextColor]=useState(1)
  const navigate = useNavigate();

  return (
    <div className="home">
      <Header />
      <div className="home-container ">
        <div style={{ display: "flex" }}>
          <div className="sidebar">
            <div>

              {/*--------Dashboard----------*/}
              <div className="side-n-dashboard common-dash-card">
                <div
                  style={{
                    color:textcolor===1?"#fff":"",
                    background:textcolor===1?"#464151":""
                  }}
                >
                 <div>
                    <img src={dashboardd} alt="" />
                  </div>
                  <div 
                    onClick={()=>{
                      navigate("/dashboard")
                      setTextColor(1)
                    }}
                    style={{
                      color:textcolor===1?"#fff":""
                    }}
                  >
                    Dashboard
                  </div>
                </div>
              </div>

              {/*--------Healthsy----------*/}
              <div className="side-n-healthsy common-dash-select">
                <div 
                  onClick={()=>{
                    setHealthsy(!healthsy)
                    setTextColor(2)
                  }}
                  style={{
                    color:textcolor===2?"#fff":"",
                    background:textcolor===2?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/doctor-registration")}
                    style={{
                      color:healthsy?"#fff":""
                    }}
                  >
                    HealthSynergy
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  healthsy
                  ?
                  <div className="common-show-dash healthsy-show-dash">
                    <div 
                      style={{color:textcolor===2?"#fff":""}} 
                      onClick={()=>{
                        navigate("/doctor-registration")
                        setTextColor(2)
                      }}
                    >HealthSy Partnered Doctor..</div>
                    <div 
                      onClick={()=>{
                        navigate("/pharmacy-registration")
                        setTextColor(201)
                      }}
                      style={{color:textcolor===201?"#fff":""}}
                    >HealthSy Partnered Pharma..</div>
                    <div 
                      onClick={()=>{
                        navigate("/healthcare-registration")
                        setTextColor(202)
                      }}
                      style={{color:textcolor===202?"#fff":""}}
                    >HealthSy Partnered Home H..</div>
                    <div 
                      onClick={()=>{
                        navigate("/other-registration")
                        setTextColor(203)
                      }}
                      style={{color:textcolor===203?"#fff":""}}
                    >Others</div>
                    <div 
                      onClick={()=>{
                        navigate("/doctor-demo")
                        setTextColor(204)
                      }}
                      style={{color:textcolor===204?"#fff":""}}
                    >Book a Demo</div>
                    <hr />
                  </div>
                  :null
                }

              {/*--------Masters----------*/}
              <div className="side-n-masters common-dash-select">
                <div 
                  onClick={()=>{
                    setMasters(!masters)
                    setTextColor(3)
                  }}
                  style={{
                    color:textcolor===3?"#fff":"",
                    background:textcolor===3?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/master-primary-specialisation")}
                    style={{
                      color:masters?"#fff":""
                    }}
                  >
                    Masters
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  masters
                  ?
                  <div className="common-show-dash masters-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/master-primary-specialisation")
                        setTextColor(3)
                      }}
                      style={{color:textcolor===3?"#fff":""}}
                    >Doctors</div>
                    <div 
                      onClick={()=>{
                        navigate("/master-health-care")
                        setTextColor(301)
                      }}
                      style={{color:textcolor===301?"#fff":""}}
                    >Home Healthcare Servoice Pr..</div>
                    <div 
                      onClick={()=>{
                        navigate("/master-partnered-pharmacy")
                        setTextColor(302)
                      }}
                      style={{color:textcolor===302?"#fff":""}}
                    >Partnered Pharamcies</div>
                    <div 
                      onClick={()=>{
                        navigate("/master-others")
                        setTextColor(303)
                      }}
                      style={{color:textcolor===303?"#fff":""}}
                    >Others</div>
                    <div 
                      onClick={()=>{
                        navigate("/master-gender")
                        setTextColor(304)
                      }}
                      style={{color:textcolor===304?"#fff":""}}
                    >Common</div>
                    <hr />
                  </div>
                  :null
                }

                 {/*--------Popup Data----------*/}
              <div className="side-n-popupdata common-dash-select">
                <div 
                  onClick={()=>{
                    setPopupData(!popupdata)
                    setTextColor(4)
                  }}
                  style={{
                    color:textcolor===4?"#fff":"",
                    background:textcolor===4?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/getnotified")}
                    style={{
                      color:popupdata?"#fff":""
                    }}
                  >
                    Popup Data
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  popupdata
                  ?
                  <div className="common-show-dash popupdata-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/getnotified")
                        setTextColor(4)
                      }}
                      style={{color:textcolor===4?"#fff":""}}
                    >Get Notified</div>
                    <div 
                      onClick={()=>{
                        navigate("/notifyme")
                        setTextColor(401)
                      }}
                      style={{color:textcolor===401?"#fff":""}}
                    >Notify Me</div>
                    <div 
                      onClick={()=>{
                        navigate("/comment")
                        setTextColor(402)
                      }}
                      style={{color:textcolor===402?"#fff":""}}
                    >Post a Comment</div>
                    <div 
                      onClick={()=>{
                        navigate("/blog-subscribe")
                        setTextColor(403)
                      }}
                      style={{color:textcolor===403?"#fff":""}}
                    >News letter Subscription</div>
                    <hr />
                  </div>
                  :null
                }

                {/*--------Health Articles----------*/}
              <div className="side-n-healtharticle common-dash-card">
                <div
                onClick={()=>{
                  setTextColor(5)
                }}
                style={{
                  color:textcolor===5?"#fff":"",
                  background:textcolor===5?"#464151":""
                }}
                >
                 <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/healtharticle")}
                    style={{
                      color:textcolor===5?"#fff":""
                    }}
                  >
                    Health Articles & Guides
                  </div>
                </div>
              </div>

              {/*--------Careers----------*/}
              <div className="side-n-career common-dash-card">
                <div
                onClick={()=>{
                  setTextColor(6)
                }}
                 style={{
                  color:textcolor===6?"#fff":"",
                  background:textcolor===6?"#464151":""
                }}
                >
                 <div>
                    <img className="submenu-common" src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/careers")}
                    style={{
                      color:textcolor===6?"#fff":""
                    }}
                  >
                    Careers
                  </div>
                </div>
              </div>

               {/*--------Doctors----------*/}
               <div className="side-n-doctor common-dash-select">
                <div 
                  onClick={()=>{
                    setDoctor(!doctor)
                    setTextColor(7)
                  }}
                  style={{
                    color:textcolor===7?"#fff":"",
                    background:textcolor===7?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/add-new-doctors")}
                    style={{
                      color:doctor?"#fff":""
                    }}
                  >
                    Doctors
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  doctor
                  ?
                  <div className="common-show-dash doctor-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/add-new-doctors")
                        setTextColor(7)
                      }}
                      style={{color:textcolor===7?"#fff":""}}
                    >Add New Doctor</div>
                    <div 
                      onClick={()=>{
                        navigate("/doctor-specialisation")
                        setTextColor(701)
                      }}
                      style={{color:textcolor===701?"#fff":""}}
                    >Doctor Specialisation</div>
                    <hr />
                  </div>
                  :null
                }

                {/*--------Home Healthcare Service Provider----------*/}
               <div className="side-n-homehealth common-dash-select">
                <div 
                  onClick={()=>{
                    setHomeHealth(!homehealth)
                    setTextColor(8)
                  }}
                  style={{
                    color:textcolor===8?"#fff":"",
                    background:textcolor===8?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/home-healthcare-service-provider")}
                    style={{
                      color:homehealth?"#fff":""
                    }}
                  >
                    Home Healthcare Se..
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  homehealth
                  ?
                  <div className="common-show-dash homehealth-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/home-healthcare-service-provider")
                        setTextColor(8)
                      }}
                      style={{color:textcolor===8?"#fff":""}}
                    >Add New Doctor</div>
                    <div 
                      onClick={()=>{
                        navigate("/home-healthcare-hhsp")
                        setTextColor(801)
                      }}
                      style={{color:textcolor===801?"#fff":""}}
                    >Doctor Specialisation</div>
                    <hr />
                  </div>
                  :null
                }

                {/*--------Partnered Pharmacies----------*/}
               <div className="side-n-retail common-dash-select">
                <div 
                  onClick={()=>{
                    setRetail(!retail)
                    setTextColor(9)
                  }}
                  style={{
                    color:textcolor===9?"#fff":"",
                    background:textcolor===9?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/add-new-retail-pharmacy")}
                    style={{
                      color:retail?"#fff":""
                    }}
                  >
                    Partnered Pharmacies
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  retail
                  ?
                  <div className="common-show-dash retail-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/add-new-retail-pharmacy")
                        setTextColor(9)
                      }}
                      style={{color:textcolor===9?"#fff":""}}
                    >Add New Retail Pharmacies</div>
                    <hr />
                  </div>
                  :null
                }

                {/*--------Clinic / Hospital----------*/}
               <div className="side-n-clinic common-dash-select">
                <div 
                  onClick={()=>{
                    setClinic(!clinic)
                    setTextColor(10)
                  }}
                  style={{
                    color:textcolor===10?"#fff":"",
                    background:textcolor===10?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/add-new-clinic")}
                    style={{
                      color:clinic?"#fff":""
                    }}
                  >
                    Clinic / Hospital
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  clinic
                  ?
                  <div className="common-show-dash clinic-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/add-new-clinic")
                        setTextColor(10)
                      }}
                      style={{color:textcolor===10?"#fff":""}}
                    >Add New Clinic / Hospital</div>
                    <hr />
                  </div>
                  :null
                }

                {/*--------Settings----------*/}
               <div className="side-n-setting common-dash-select">
                <div 
                  onClick={()=>{
                    setSetting(!setting)
                    setTextColor(11)
                  }}
                  style={{
                    color:textcolor===11?"#fff":"",
                    background:textcolor===11?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/settings-social-media-form")}
                    style={{
                      color:setting?"#fff":""
                    }}
                  >
                    Settings
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  setting
                  ?
                  <div className="common-show-dash setting-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/settings-social-media-form")
                        setTextColor(11)
                      }}
                      style={{color:textcolor===11?"#fff":""}}
                    >Social Media Links</div>
                    <div 
                      onClick={()=>{
                        navigate("/add-banner-images")
                        setTextColor(1001)
                      }}
                      style={{color:textcolor===1001?"#fff":""}}
                    >Banner Images</div>
                    <div 
                      onClick={()=>{
                        navigate("/store-links")
                        setTextColor(1002)
                      }}
                      style={{color:textcolor===1002?"#fff":""}}
                    >Play Store / App Store Links</div>
                    <div 
                      onClick={()=>{
                        navigate("/business-settings")
                        setTextColor(1003)
                      }}
                      style={{color:textcolor===1003?"#fff":""}}
                    >Business Settings</div>
                    <div 
                      onClick={()=>{
                        navigate("/meta-tags")
                        setTextColor(1004)
                      }}
                      style={{color:textcolor===1004?"#fff":""}}
                    >Meta Tags</div>
                    <div 
                      onClick={()=>{
                        navigate("/site-map-setting")
                        setTextColor(1005)
                      }}
                      style={{color:textcolor===1005?"#fff":""}}
                    >Site Map Settings</div>
                    <div 
                      onClick={()=>{
                        navigate("/email-setting")
                        setTextColor(1006)
                      }}
                      style={{color:textcolor===1006?"#fff":""}}
                    >Email Settings</div>
                    <hr />
                  </div>
                  :null
                }

                 {/*--------Email Brochures----------*/}
               <div className="side-n-broucher common-dash-select">
                <div 
                  onClick={()=>{
                    setBrocher(!brocher)
                    setTextColor(12)
                  }}
                  style={{
                    color:textcolor===12?"#fff":"",
                    background:textcolor===12?"#464151":""
                  }}
                >
                 <div>
                    <img src={submenu} alt="" />
                  </div>
                  <div 
                    onClick={()=>navigate("/doctor-registration-brochures")}
                    style={{
                      color:brocher?"#fff":""
                    }}
                  >
                    Email Brochures
                  </div>
                  <div>
                    <FaAngleDown />
                  </div>
                </div>
              </div>
              {
                  brocher
                  ?
                  <div className="common-show-dash broucher-show-dash">
                    <div 
                      onClick={()=>{
                        navigate("/doctor-registration-brochures")
                        setTextColor(12)
                      }}
                      style={{color:textcolor===12?"#fff":""}}
                    >Doctor Registration</div>
                    <div 
                      onClick={()=>{
                        navigate("/doctor-demo-registration-brochures")
                        setTextColor(2001)
                      }}
                      style={{color:textcolor===2001?"#fff":""}}
                    >Doctor Demo</div>
                    <div 
                      onClick={()=>{
                        navigate("/pharmacy-registration-brochures")
                        setTextColor(2002)
                      }}
                      style={{color:textcolor===2002?"#fff":""}}
                    >Pharmacy Registration</div>
                    <div 
                      onClick={()=>{
                        navigate("/healthcare-registration-brochures")
                        setTextColor(2003)
                      }}
                      style={{color:textcolor===2003?"#fff":""}}
                    >Healthcare Registration</div>
                    <div 
                      onClick={()=>{
                        navigate("/others-registration-brochures")
                        setTextColor(2004)
                      }}
                      style={{color:textcolor===2004?"#fff":""}}
                    >Other Registartion</div>
                    <hr />
                  </div>
                  :null
                }


            </div>
          </div>
          <main className="main-container">
            <div>{children}</div>
          </main>
        </div>
      </div>
      <Logout />
    </div>
  );
};

export default Home;
