import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../assets/svg/back-rose-icon.svg";

const RetailPharmacyView = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/retail-pharmacy-track")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle">
        <div className="healtharticle-container ps-3">
          <div className="healtharticle-title ms-0">
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span style={{ paddingLeft: "5%" }}>Dashboard</span>
            </Link>
            <span style={{ paddingLeft: "1%" }}>></span>
            <span style={{ paddingLeft: "0.4%" }}>
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                Partnered Retail Pharmacies
              </Link>
            </span>
            {/* <span style={{ paddingLeft: "0.4%" }}>></span>
                <span style={{ paddingLeft: "0.4%" }}>
                  List of Doctors for Online Consultation
                </span> */}
            <span style={{ paddingLeft: "0.4%" }}>></span>
            <span style={{ paddingLeft: "0.4%" }}>Track Retail Pharmacy</span>
            <span style={{ paddingLeft: "0.4%" }}>></span>
            <span style={{ paddingLeft: "0.4%" }}>View Retail Pharmacy</span>
          </div>

          <div className="doctor-track-header">
            <div>
              <img src="" alt="" />
            </div>
            <div>
              <div>Prakash. R</div>
              {/* <div>General Physician</div> */}
            </div>
            <div style={{ fontWeight: "700", cursor: "pointer" }}>Edit</div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>Order ID</div>
              <div>622412</div>
            </div>
            <div>
              <div>Customer Name</div>
              <div>Prakash R</div>
            </div>
            <div>
              <div>Mobile Number</div>
              <div>+91 72964 45263</div>
            </div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>Item(s) Ordered</div>
              <div>Dolo 650 Mg Telekast L 25 Rediase..</div>
            </div>
            <div>
              <div>Received</div>
              <div>10 Mins Ago</div>
            </div>
            <div>
              <div>Order Status</div>
              <div>Accepted</div>
            </div>
          </div>

          <div className="doctor-track-data">
            <div>
              <div>Fulfilment Status</div>
              <div>Packed Billed Ready for dispatch</div>
            </div>
            <div>
              <div>Order Value</div>
              <div>716.16</div>
            </div>
            <div>
              <div>HealthSy %</div>
              <div>100.61</div>
            </div>
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  );
};

export default RetailPharmacyView;
