import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Home from "../../../Home/Home";
import "./index.scss";
import axios from "axios";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { ImageUploadAndPreview } from "../../../Pages/HealthArticle/AddNew/AddNew";
import SucessModal from "../../../modal/SucessModal";

const AddDoctorSplecialisation = () => {

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/doctor-specialisation")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctor</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
              <Link
                to="/doctor-specialisation"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span>Doctor Specialisation</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>></span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Add New Doctor Specialisation
              </span>
            </div>

            <form className="career-jobpost-form">
              <div className="addnew-form1-autor-detail mt-3 d-flex">
                <div className="col-md-4">
                  <label for="inputAddress" className="form-label title">
                    Name of Doctor Specialisation <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input"
                    id="authordetails"
                    {...register("authordetails", { required: true })}
                    style={{ paddingLeft: "10px" }}
                  />
                  {errors.authordetails && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Author Name
                    </div>
                  )}
                </div>

                <div className="authorUpload">
                  <label className="title fo-tit fo-tit-top">
                    Upload Image / Icon <span className="star">*</span>
                  </label>
                  <div id="img-preview-two"></div>
                  <div>
                    <ImageUploadAndPreview
                    //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                    />
                    {errors.authorImage && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select Author Image
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="addnew-form1-autor-detail mt-3 d-flex">
              <div className="row mt-3 col-md-6">
                <div className="col">
                  <label className=" small mb-2" htmlFor="">
                    Doctor Specialisation in Online Doctor Consultation <span className="star">*</span>
                  </label>
                  <div className="d-flex">
                    <div className="form-check form-check-inline me-3 mt-1">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Active"
                      />
                      <label htmlFor="option-1" className="text-dark mt-1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-1">
                      <input
                        className="form-check-input  me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Inactive"
                      />
                      <label htmlFor="option-2" className="text-dark mt-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
              </div>

              <div className="row mt-3">
                <div className="col">
                  <label className=" small mb-2" htmlFor="">
                    Doctor Specialisation in In-clinic Doctor Appointment <span className="star">*</span>
                  </label>
                  <div className="d-flex">
                    <div className="form-check form-check-inline me-3 mt-1">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Active"
                      />
                      <label htmlFor="option-1" className="text-dark mt-1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-1">
                      <input
                        className="form-check-input  me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Inactive"
                      />
                      <label htmlFor="option-2" className="text-dark mt-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
              </div>
            </div>

            <div className="addnew-form1-autor-detail mt-3 d-flex">
              <div className="row mt-3 col-md-6">
                <div className="col">
                  <label className=" small mb-2" htmlFor="">
                    Active Doctor Specialisation <span className="star">*</span>
                  </label>
                  <div className="d-flex">
                    <div className="form-check form-check-inline me-3 mt-1">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Active"
                      />
                      <label htmlFor="option-1" className="text-dark mt-1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-1">
                      <input
                        className="form-check-input  me-3"
                        type="radio"
                        name="status"
                        id="status"
                        value="Inactive"
                      />
                      <label htmlFor="option-2" className="text-dark mt-1">
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
              </div>
              </div>

              <div className="car-pos-form-button mb-5">
            <button 
              type="button"
              onClick={()=>{
                setSuccess(true) 
               }}
            >ADD</button>
          </div>


            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
          page={"/doctor-specialisation"} 
          name="Doctor Specialisation" 
          post='posted'
        />
      )}
    </>
  );
};

export default AddDoctorSplecialisation;
