import React from "react";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const ViewDoctorInclinicForm = () => {
  const navigate = useNavigate();

  return (
    <>
      {/* <Home> */}
      <div
        onClick={() => navigate("/doctor-inclinic-appointment")}
        className="rose back-button"
      >
        <img src={backRed} alt="" />
        <span className="rose-back">Back</span>
      </div>
      <div className="healtharticle-title ms-0">
        <Link
          to="/add-new-doctors"
          style={{
            textDecoration: "none",
            color: "black",
            fontSize: "15px",
          }}
        >
          <span style={{ paddingLeft: "5%" }}>Doctor</span>
        </Link>
        <span style={{ paddingLeft: "1%" }}>></span>
        <span style={{ paddingLeft: "0.4%" }}>
          <Link
            to="/doctor-inclinic-appointment"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            Clinic & Hospital
          </Link>
        </span>
        <span style={{ paddingLeft: "0.4%" }}>></span>
        <span style={{ paddingLeft: "0.4%" }}>View Clinic & Hospital</span>
      </div>
      <div>
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Name of Clinic / Hospital
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="col">
            <label htmlFor="" className="mb-3">
              Type of Organisation <span className="text-danger"></span>
            </label>
            <div className="d-flex ">
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="organisation"
                  id="status"
                  checked
                />
                <label class="" htmlFor="inline1">
                  yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="me-3"
                  type="radio"
                  name="organisation"
                  id="status"
                />
                <label class="" htmlFor="inline2">
                  No
                </label>
              </div>
            </div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Location of Clinic / Hospital
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Two */}
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              City / Town
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              State
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Pincode of Clinic / Hospital
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Three */}
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Clinic / Hospital Contact
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Select Specialisation
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Four */}
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Clinic / Hospital License of Registration Number
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              Upload Logo Clinic / Hospital
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>

          <div className="menu-field col-md-3">
            <p className="form-label " htmlFor="">
              Upload Photos of Clinic / Hospital
            </p>
            <div>
              <img
                src=""
                alt=""
                style={{
                  borderRadius: "8px",
                  width: "99px",
                  height: "64px",
                  border: "1px solid gray",
                }}
              />
            </div>
          </div>
        </div>

        {/* Five */}
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Google Map location Link
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>

        {/* Six */}
        <div className="addnew-form1-autor-detail mt-3 d-flex">
          <div className="menu-field col-md-3 me-5">
            <p className="form-label " htmlFor="">
              Search for Doctors
            </p>
            <div className="blog-view-text-half-bor"></div>
          </div>
        </div>
      </div>
      {/* </Home> */}
    </>
  );
};

export default ViewDoctorInclinicForm;
