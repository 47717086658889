import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import SucessModal from "../../../modal/SucessModal";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

export default function CategoryForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate()

  const onSubmit = (fields) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}health-article-category/create`,
        fields
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };

  const[menu,setMenu]=useState([])

  useEffect(()=>{
    axios.post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
    .then((res)=>{
      setMenu(res.data.rows)
    })
  }, [])

  const [checked, setChecked] = useState(true)

  return (
    <div>
      {/* <Home> */}
        <div 
            onClick={()=>navigate("/Category")} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        <div className="healtharticle">
          <div className="healtharticle-container category-container ps-3">
            <div className="healtharticle-title">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{  }}>Dashboard</span>
              </Link>
              <span>></span>
              <Link
                to="/healtharticlemenu"
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingLeft: "0.5%",
                }}
              >
                <span>Manage Menu and Category</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft:"0.4%" }}>Add New Category</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-5 ms-2">
                <div className="col-3">
                  <label className="form-label " htmlFor="">
                    Menu <span className="star ">*</span>
                  </label>
                  {/* <input className='form-control w-100 ' type="text" name="" id="" placeholder='Enter'/> */}
                  <select
                    // onFocus={this.size=10}
                    // onBlur={this.size=1}
                    className="category-select w-100 "
                    name=""
                    id="menu"
                    {...register("menu", { required: true })}
                  >
                    <option value="" selected hidden>Select Menu</option>
                    {
                      menu.map((el)=>{
                        return(
                          <option value={el.name}>{el.name}</option>
                        )
                      })
                    }
                  </select>
                  {errors.menu && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select an Option
                    </div>
                  )}
                </div>
                <div className="col-4 ms-3">
                  <label className="form-label " htmlFor="">
                    Category <span className="star ">*</span>
                  </label>
                  <input
                    className="form-control w-100 category-input"
                    type="text"
                    name=""
                    id="category"
                    {...register("category", { required: true })}
                    placeholder="Enter Category"
                  />
                  {errors.category && (
                    <div className={`invalid-feedback d-block`}>
                      Please Enter Category
                    </div>
                  )}
                </div>
              </div>

              <div className="row ms-2">
                <label htmlFor="" className="mb-3 mt-3 ">
                  Active Status <span className="star">*</span>
                </label>
                <div className="d-flex p-0">
                  <div className="form-check form-check-inline m-0">
                    <input
                      className=" me-3"
                      type="radio"
                      name="active"
                      id="active"
                      value={true}
                      checked={checked ? "checked" : ""}
                      onClick={()=>setChecked(true)}
                      {...register("active", { required: true })}
                    />
                    <label class="" htmlFor="inline1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="me-3"
                      type="radio"
                      name="active"
                      id="active"
                      value={false}
                      checked={checked ? "" : "checked"}
                      onClick={()=>setChecked(false)}
                      {...register("active", { required: true })}
                    />
                    <label class="" htmlFor="inline2">
                      No
                    </label>
                  </div>
                </div>
                {errors.status && (
                  <div className={`invalid-feedback d-block`}>
                    Please Select an Option
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-center category-btn">
                <button type="submit" className="add-button">
                  ADD
                </button>
              </div>
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
           page={"/Category"} 
           name="Health Article Category" 
           post='posted'
        />
      )}
    </div>
  );
}
