import React, { useEffect, useState } from "react";
import "./dashboard.scss";
import Home from "../../Home/Home";
import axios from "axios";
import one from "../../../assets/images/dashboard/one.png";
import two from "../../../assets/images/dashboard/two.png";
import three from "../../../assets/images/dashboard/three.png";
import four from "../../../assets/images/dashboard/four.png";
import five from "../../../assets/images/dashboard/five.png";
import six from "../../../assets/images/dashboard/six.png";

const Dashboard = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-department/dashboard`)
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <>
      {/* <Home> */}
        <div className="home-dashboard">
          <div className="home-dashboard-box">
            <div className="home-dashboard-title">Dashboard</div>
            <div className="home-dashboard-overall">Overall</div>
            <div className="home-dashboard-container">
              <div className="getNotified">
                <div>
                  <img src={one} alt="" />
                </div>
                <div>Total Get Notified</div>
                <div>{data.getNotified}</div>
              </div>
              <div>
                <div>
                  <img src={two} alt="" />
                </div>
                <div>Total Pharmacy Registrations</div>
                <div>{data.pharmacy}</div>
              </div>
              <div>
                <div>
                  <img src={three} alt="" />
                </div>
                <div>Total Doctor Registrations</div>
                <div className="doctortotal">{data.doctor}</div>
              </div>
              <div>
                <div>
                  <img src={four} alt="" />
                </div>
                <div>Total HHSP Registrations </div>
                <div>{data.healthCare}</div>
              </div>
              <div>
                <div>
                  <img src={five} alt="" />
                </div>
                <div>Total Other Registrations</div>
                <div>{data.other}</div>
              </div>
              <div>
                <div>
                  <img src={six} alt="" />
                </div>
                <div>Total Careers Registrations</div>
                <div>{data.career}</div>
              </div>
            </div>
          </div>
        </div>
      {/* </Home> */}
    </>
  );
};

export default Dashboard;
