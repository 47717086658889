import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { useForm } from "react-hook-form";
import { useState } from "react";
import "./index.scss";
import greenround from "../../../../assets/images/greenround.PNG";
import greentick from "../../../../assets/images/greentick.PNG";
import graytick from "../../../../assets/images/graytick.PNG";
import { ImageUploadAndPreview } from "../../../Pages/HealthArticle/AddNew/AddNew";
import next from "../../../../assets/svg/next-icon.svg";
import back from "../../../../assets/svg/back-icon.svg";
import SucessModal from "../../../modal/SucessModal";
import { useEffect } from "react";

const AddNewDoctorForm = () => {
  
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleNext = () => {
    setPage(2);
    setShow(true);
  };

  const handleBack = () => {
    setPage(1);
    setShow(false);
  };

  return (
    <>
      {/* <Home> */}
        {page === 1 ? (
          <div
            onClick={() => navigate("/add-new-doctors")}
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : page === 2 ? (
          <div onClick={handleBack} className="rose back-button">
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        ) : null}

        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Doctor</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
              <Link
                to="/add-new-doctors"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span>List of Doctors for Online Consultation</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>></span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Add Doctors
              </span>
            </div>

            {page === 1 ? (
              <div className="doctor-page-tick">
                <div>
                  <img src={greenround} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={graytick} alt="" />
                  <div>Page 2</div>
                </div>
              </div>
            ) : page === 2 ? (
              <div className="doctor-page2-tick">
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 1</div>
                </div>
                <div>
                  <img src={greentick} alt="" />
                  <div>Page 2</div>
                </div>
              </div>
            ) : null}

            <form className="career-jobpost-form">
              {show === false ? (
                <div className="doctor-page1">
                  {/* one */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Name of Doctor <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Primary Specialisation <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Please select
                        </option>
                        <option value="">Genereal Physician</option>
                        <option value="">Allergist</option>
                        <option value="">Cardiologist</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Mainstream <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Please select
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* two */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Secondary / Sub Specialisation{" "}
                        <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        By Health Concerns <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select upto 8 HC
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Age <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select Age
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* three */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="row col-md-4">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Verified <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="verified"
                              id="verified"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="verified"
                              id="verified"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Doctor's Expeience (Yrs) <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select Years
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="row col-md-6">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Gender <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="gender"
                              id="gender"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="gender"
                              id="gender"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="gender"
                              id="gender"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              Others
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>
                  </div>

                  {/* four */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Date of Onboarding (Active Since){" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Online Doctor Consultation Fees (INR){" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Educational Qualifications 1{" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type Degree"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* five */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Educational Institution <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type Name of Educational Institute"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Educational Qualification 2{" "}
                        <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type Degree"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Educational Institution <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type Name of Educational Intitute"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Six */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        Select Known Languages (Upto 6){" "}
                        <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select and Add
                        </option>
                        <option value="">Genereal Physician</option>
                        <option value="">Allergist</option>
                        <option value="">Cardiologist</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Registered Mobile Number <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-3 ms-5">
                      <label for="inputAddress" className="form-label title">
                        Registered Email ID <span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Seven */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        City / Town <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select
                        </option>
                        <option value="">Genereal Physician</option>
                        <option value="">Allergist</option>
                        <option value="">Cardiologist</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="col-md-4">
                      <label for="inputAddress" className="form-label title">
                        State <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select state
                        </option>
                        <option value="">Genereal Physician</option>
                        <option value="">Allergist</option>
                        <option value="">Cardiologist</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Logo of the above Hospital Clinic{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Eight */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="row col-md-12">
                      <div className="col">
                        <label className=" small mb-2" htmlFor="">
                          Available on Online Consultation Modes{" "}
                          <span className="star">*</span>
                        </label>
                        <div className="d-flex">
                          <div className="form-check form-check-inline me-3 mt-1">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              name="status"
                              id="status"
                              value="Active"
                              checked
                            />
                            <label
                              htmlFor="option-1"
                              className="text-dark mt-1"
                            >
                              All (Audio, Video, Chat)
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="status"
                              id="status"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              Chat
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="status"
                              id="status"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              Video
                            </label>
                          </div>
                          <div className="form-check form-check-inline mt-1">
                            <input
                              className="form-check-input  me-3"
                              type="radio"
                              name="status"
                              id="status"
                              value="Inactive"
                            />
                            <label
                              htmlFor="option-2"
                              className="text-dark mt-1"
                            >
                              Audio
                            </label>
                          </div>
                        </div>
                      </div>
                      {errors.status && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select an Option
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Eight */}
                  <div className="addnew-form1-autor-detail mt-5 d-flex">
                    <div className="authorUpload ms-0">
                      <label className="title fo-tit fo-tit-top">
                        Upload Header / Cover Video{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Profile Image <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="addnew-form1-next">
                    <div onClick={handleNext}>
                      <div>
                        <button>NEXT</button>
                      </div>
                      <div>
                        <img src={next} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="doctor-page2">
                  {/* one */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-12">
                      <label for="inputAddress" className="form-label title">
                        About (Opt of Max 100 words){" "}
                        <span className="star">*</span>
                      </label>
                      <br />
                      <textarea
                        className="doctor-textarea"
                        name=""
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  {/* two */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Medical Registration Number{" "}
                        <span className="star">*</span>
                      </label>
                      <select className="form-select w-75 car-job-select">
                        <option value="" hidden selected>
                          Select
                        </option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                        <option value="">Allopathy</option>
                      </select>
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Document of MCI <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="authorUpload">
                      <label className="title fo-tit fo-tit-top">
                        Upload Cancelled Cheque Leaf{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* three */}
                  <div className="addnew-form1-autor-detail mt-3 d-flex">
                    <div className="authorUpload ms-0 me-5">
                      <label className="title fo-tit fo-tit-top">
                        Upload a Valid Photo ID of Doctor{" "}
                        <span className="star">*</span>
                      </label>
                      <div id="img-preview-two"></div>
                      <div>
                        <ImageUploadAndPreview
                        //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                        />
                        {errors.authorImage && (
                          <div className={`invalid-feedback d-block`}>
                            Please Select Author Image
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-3">
                      <label for="inputAddress" className="form-label title">
                        Memberships (Opt)
                      </label>
                      <input
                        type="text"
                        className="form-control addnew-input"
                        id="authordetails"
                        placeholder="Type here"
                        style={{ paddingLeft: "10px" }}
                      />
                      {errors.authordetails && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Author Name
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="addnew1-form1-next">
                    <div className="addnew1-back-next">
                      <div className="addnew1-back">
                        <div onClick={handleBack}>
                          <div>
                            <img src={back} alt="" />
                          </div>
                          <div>BACK</div>
                        </div>
                      </div>
                      <div className="addnew1-next">
                        <div className="doc-btn-add">
                          <button
                            onClick={() => {
                              setSuccess(true);
                            }}
                            type="button"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/add-new-doctors"}
          name="New Doctor"
          post="posted"
        />
      )}
    </>
  );
};

export default AddNewDoctorForm;
