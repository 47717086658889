import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const ViewMenu = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}health-article-menu/get/${id}`)
      .then((res) => {
        setData(res.data);
      });
  };

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/healtharticlemenu")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/healtharticle"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  HealthArticle
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>View Menu</span>
            </div>
            <div className="row mt-5 new-menu ">
              <div className="menu-field col-md-3 me-0">
                <p className="form-label " htmlFor="">
                  Name
                </p>
                {/* <input
                type="text"
                className="form-control menu-input  mt-2 "
                value={data.menu}
                style={{border:"1px solid transparent"}}
                name=""
                id=""
              /> */}
                <div className="blog-view-text-half-bor">{data.name}</div>
              </div>

              <div className="col">
                <label htmlFor="" className="mb-3 ms-3 ">
                  Active Status <span className="text-danger"></span>
                </label>
                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input
                      className="me-3"
                      type="radio"
                      name="active"
                      id="status"
                      value={data.active}
                      checked={data.active === true ? "checked" : null}
                    />
                    <label class="" htmlFor="inline1">
                      yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="me-3"
                      type="radio"
                      name="active"
                      id="status"
                      value={data.active}
                      checked={data.active === false ? "checked" : null}
                    />
                    <label class="" htmlFor="inline2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </Home> */}
    </>
  );
};

export default ViewMenu;
