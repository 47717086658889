import React, { useEffect, useState } from "react";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import "./subscribe.scss";

const Subscribe = () => {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);

  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  useEffect(() => {
    list_api_call();
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}blog-subscribe/list`, {
        ...filter,
        skip: skip,
        limit: limit,
        sort: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data.rows);
        setRowCount(response.data.count);
        setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleSubscribe = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}blog-subscribe/update/${id}`, {
        subscription: true,
        unSubscription: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  const handleUnSubscribe = (id) => {
    axios
      .patch(`${process.env.REACT_APP_API_URL}blog-subscribe/update/${id}`, {
        unSubscription: true,
        subscription: false,
      })
      .then((result) => {
        list_api_call();
      });
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-container ms-1">
          <div className="healtharticle-title ms-4">
            <Link
              to="/getnotified"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                width: "9%",
              }}
            >
              <span style={{ paddingLeft: "1%" }}>Pop-up Data</span>
            </Link>
            <span style={{ paddingLeft: "0.6%" }}>></span>
            <span style={{ paddingLeft: "0.4%" }}>
              News Letter Subscriptions
            </span>
          </div>
          <div className="healtharticle-show mt-5 ms-4">
            <div>Show</div>
            <div>
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div>
                <FaSearch />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {/* Sort By */}
                      {sortName}
                    </div>
                    {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                    // onClick={() => {
                    //   setFilterShow(true);
                    // }}
                    >
                      Filter
                    </div>
                    {/* <select
                        name=""
                        id=""
                        onChange={(e) => {
                          setFilter((prevFilter) => {
                            prevFilter.active = e.target.value;
                            return prevFilter;
                          });
                          list_api_call();
                        }}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="healtharticle-table blog-subscribe-table ms-4">
            <table style={{ width: "100% " }}>
              <thead>
                <tr>
                  <td className="career-jobpost-td1">S.No</td>
                  <td className="career-jobpost-td4">Email ID</td>
                  <td className="career-jobpost-td6">Status</td>
                </tr>
              </thead>
              <tbody>
                {(filtered_records.length > 0 ? filtered_records : records).map(
                  (el, i) => {
                    return (
                      <tr key={el._id}>
                        <td>{(current_page - 1) * limit + (i + 1)}</td>
                        <td>{el.email}</td>
                        <td>
                          <span
                            className={`subs ${
                              el.subscription == true ? "subs-highlight" : ""
                            }`}
                            onClick={() => {
                              handleSubscribe(el._id);
                            }}
                          >
                            Subscription
                          </span>
                          <span className="subs-logo"></span>
                          <span
                            className={`unsubs ${
                              el.unSubscription == true
                                ? "unsubs-highlight"
                                : ""
                            }`}
                            onClick={() => {
                              handleUnSubscribe(el._id);
                            }}
                          >
                            Unsubscription
                          </span>
                          <span className="unsubs-logo"></span>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}
      {sortshow ? (
        <div className="sortby-Common-career-main sortby-popup-comments">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor == 11 ? "#CB1B5B" : "",
              fontWeight: textcolor == 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor == 12 ? "#CB1B5B" : "",
              fontWeight: textcolor == 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor == 13 ? "#CB1B5B" : "",
              fontWeight: textcolor == 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor == 14 ? "#CB1B5B" : "",
              fontWeight: textcolor == 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Subscribe;
