import React, { useEffect, useState } from "react";
import "./account.scss";
import logo from "../../assets/images/login_logo.PNG";
import banner_logo from "../../assets/svg/logo.svg";
import { useNavigate } from "react-router";
import eye from "../../assets/svg/eye-icon.svg";
import hideEye from "../../assets/images/hide.png";
import axios from "axios";

const Login = () => {
  const [bordercolor1, setBordercolor1] = useState(null);
  const [bordercolor2, setBordercolor2] = useState(null);
  const [showPassword, setShowPassword] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [eyeIcon, seteyeIcon] = useState(true);

  const [error, setError] = useState("");

  const handleEye = () => {
    seteyeIcon(!eyeIcon);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}admin-panel/list`)
      .then((res) => {
        setData(res.data);
      });
    if (localStorage.getItem("auth")) {
      navigate("/");
    }
  }, []);

  const handleLogin = () => {
    data.map((e) => {
      if (username != e.username && password != e.password) {
        return setError("Please Enter a valid credentials");
      }
    });
    localStorage.setItem("logout", false);
    window.location.reload();
    data.map((e) => {
      if (e.username == username && e.password == password) {
        navigate("/");
        localStorage.setItem("auth", true);
      }
    });
  };

  const handlePassword = () => {
    handleEye();
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    var cookiePass = JSON.parse(localStorage.getItem("CookiePassword"));
    var cookieUser = JSON.parse(localStorage.getItem("CookieUser"));
    setUsername(cookieUser);
    setPassword(cookiePass);
  }, []);

  const handleCookieData = () => {
    data.map((e) => {
      if (e.username == username && e.password == password) {
        localStorage.setItem("CookiePassword", JSON.stringify(password));
        localStorage.setItem("CookieUser", JSON.stringify(username));
      }
    });
  };

  return (
    <div className="adminpanellogin">
      <div className="adminpanellogin-container">
        <div className="adminpanel-left">
          <div className="adminpanel-left-box">
            <img className="adminpanel-logo" src={logo} alt="" />
          </div>
        </div>
        <div className="adminpanel-right">
          <div className="adminpanel-right-box">
            <div className="adminpanel-right-header-logo">
              <img src={banner_logo} alt="" />
            </div>
            <div className="adminpanel-right-title">
              <div>Login</div>
              {/* <div>Not admin yet? <span><Link>Request access</Link></span></div> */}
            </div>
            {error.length > 0 && (
              <div style={{ marginBottom: "10px", color: "red" }}>{error}</div>
            )}
            <div className="adminpanel-right-form">
              <div className="adminpanel-right-username">
                <div>
                  Username <span className="star">*</span>
                </div>
                <div style={{ border: `2px solid ${bordercolor1}` }}>
                  <input
                    className="login-username"
                    style={{ background: bordercolor1 ? "#fff" : "#fff" }}
                    type="text"
                    onChange={(e) => {
                      setBordercolor1("#CB1B5B");
                      setUsername(e.target.value);
                    }}
                    value={username}
                    placeholder="Enter your username"
                  />
                </div>
                {/* {
                  usererror
                  ? ""
                  : <div style={{color:"red", fontSize:"13px"}} htmlFor="">Please Enter a valid Username</div>
                } */}
              </div>
              <div className="adminpanel-right-password">
                <div>
                  Password <span className="star">*</span>
                </div>
                <div style={{ border: `2px solid ${bordercolor2}` }}>
                  <div>
                    <input
                      className="login-password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        setBordercolor2("#CB1B5B");
                        setPassword(e.target.value);
                      }}
                      value={password}
                      placeholder="Enter your password"
                    />
                  </div>
                  <div
                    className="hide"
                    style={{ width: "16px", height: "16px" }}
                  >
                    {eyeIcon ? (
                      <img
                        src={eye}
                        onClick={handlePassword}
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <img
                        src={hideEye}
                        onClick={handlePassword}
                        style={{ width: "100%" }}
                      />
                    )}
                  </div>
                </div>
                {/* {passerror
                ?""
                :<div style={{color:"red", fontSize:"13px"}}>Please enter a valid Password</div>
                } */}
              </div>
              <div className="adminpanel-right-forgot">
                <div className="check">
                  <div className="check-box">
                    {/* <input 
                       id="cb1"
                       type="checkbox" 
                       value="checkboxCookie"
                       onChange={handleCookieData}
                    /> */}
                    <label class="admin-container">
                      <input
                        id="cb1"
                        type="checkbox"
                        //  checked="checked"
                        value="checkboxCookie"
                        onChange={handleCookieData}
                      />
                      <span class="admin-checkmark"></span>
                    </label>
                  </div>
                  <div>Keep me Logged In</div>
                </div>
                <div>Forgot Password?</div>
              </div>
              <div className="adminpanel-right-button">
                <button
                  onClick={handleLogin}
                  style={{ background: bordercolor2 }}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
