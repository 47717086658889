import React from 'react'
import { Route, Routes } from 'react-router'
import Login from '../Account/Login'
import Careers from '../Pages/Careers/Careers'
import Department from '../Pages/Careers/department/Department'
import JobPost from '../Pages/Careers/JobPost/JobPost'
import JobPostForm from '../Pages/Careers/JobPostForm/JobPostForm'
import Dashboard from '../Pages/Dashboard/Dashboard'
import AddNewPageOne from '../Pages/HealthArticle/AddNew/AddNew'
import HealthArticle from '../Pages/HealthArticle/HealthArticle'
import DoctorDemo from '../Pages/HealthSynergy/DoctorDemo'
import DoctorRegistration from '../Pages/HealthSynergy/DoctorRegistration'
import PharmacyRegistration from '../Pages/HealthSynergy/PharmacyRegistration'
import HealthcareRegistration from '../Pages/HealthSynergy/HealthcareRegistration'
import OtherRegistration from '../Pages/HealthSynergy/OtherRegistration'
import DepartmentForm from '../Pages/Careers/departmentForm/DepartmentForm'
import Position from '../Pages/Careers/Position/Position'
import PositionForm from '../Pages/Careers/PositionForm/PositionForm'
import JobPostEdit from '../Pages/Careers/JobPostForm/JobPostEdit'
import Menu from "../Pages/HealthArticle/menu/Menu"
import MenuForm from '../Pages/HealthArticle/menu/MenuForm'
import CategoryForm from '../Pages/HealthArticle/Category/CategoryForm'
import Category from '../Pages/HealthArticle/Category/Category'
import RetailPharmacy from '../Pages/HealthSynergy/PharmacyRegistration'
import DepartmentEdit from '../Pages/Careers/departmentForm/DepartmentEdit'
import PositionEdit from '../Pages/Careers/PositionForm/PositionEdit'
import MenuEdit from '../Pages/HealthArticle/menu/MenuEdit'
import CategoryEdit from '../Pages/HealthArticle/Category/CategoryEdit'
import HealthArticleView from '../Pages/HealthArticle/AddNew/HealthArticleView'
import ViewMenu from '../Pages/HealthArticle/menu/ViewMenu'
import ViewCategory from '../Pages/HealthArticle/Category/ViewCategory'
import HealthArticleEdit from '../Pages/HealthArticle/AddNew/HealthArticleEdit'
import PrimarySpecialisation from '../masters/Doctors/PrimarySpecialisation/PrimarySpecialisation'
import InClinicFee from '../masters/Doctors/InClinicFee/InClinicFee'
import SpecialisationForm from '../masters/Doctors/PrimarySpecialisation/Form/SpecialisationForm'
import InClinicForm from '../masters/Doctors/InClinicFee/Form/InClinicForm'
import HomeHealthCare from '../masters/HomeHealthCare/HomeHealthCare'
import HealthcareForm from '../masters/HomeHealthCare/Form/HealthcareForm'
import PharmacyBusiness from '../masters/PartneredPharmacies/PharmacyBusiness/PharmacyBusiness'
import PharmacyBusinessForm from '../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessForm'
import Inventory from '../masters/PartneredPharmacies/Inventory/Inventory'
import InventoryForm from '../masters/PartneredPharmacies/Inventory/Form/InventoryForm'
import PharmacyStore from '../masters/PartneredPharmacies/PharmacyStore/PharmacyStore'
import PharmacyStoreForm from '../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreForm'
import Others from '../masters/Others/Others'
import OthersForm from '../masters/Others/Form/OthersForm'
import Gender from '../masters/Common/Gender/Gender'
import GenderForm from '../masters/Common/Gender/Form/GenderForm'
import Days from '../masters/Common/Days/Days'
import DaysForm from '../masters/Common/Days/Form/DaysForm'
import Experience from '../masters/Common/Experience/Experience'
import ExperienceForm from '../masters/Common/Experience/Form/ExperienceForm'
import Lanuages from '../masters/Common/Languages/Lanuages'
import LanguagesForm from '../masters/Common/Languages/Form/LanguagesForm'
import Location from '../masters/Common/Location/Location'
import LocationForm from '../masters/Common/Location/Form/LocationForm'
import Timings from '../masters/Common/Timing/Timings'
import TimingForm from '../masters/Common/Timing/Form/TimingForm'
import Edit from '../masters/Doctors/PrimarySpecialisation/Form/Edit'
import View from '../masters/Doctors/PrimarySpecialisation/Form/View'
import InclinicEdit from '../masters/Doctors/InClinicFee/Form/InclinicEdit'
import InclinicView from '../masters/Doctors/InClinicFee/Form/InclinicView'
import DaysEdit from '../masters/Common/Days/Form/DaysEdit'
import DaysView from '../masters/Common/Days/Form/DaysView'
import ExperienceEdit from '../masters/Common/Experience/Form/ExperienceEdit'
import ExperienceView from '../masters/Common/Experience/Form/ExperienceView'
import GenderEdit from '../masters/Common/Gender/Form/GenderEdit'
import GenderView from '../masters/Common/Gender/Form/GenderView'
import LanguagesEdit from '../masters/Common/Languages/Form/LanguagesEdit'
import LanguagesView from '../masters/Common/Languages/Form/LanguagesView'
import LocationEdit from '../masters/Common/Location/Form/LocationEdit'
import LocationView from '../masters/Common/Location/Form/LocationView'
import TimingEdit from '../masters/Common/Timing/Form/TimingEdit'
import TimingView from '../masters/Common/Timing/Form/TimingView'
import HomeHealthcareView from '../masters/HomeHealthCare/Form/HomeHealthcareView'
import HomeHealthcareEdit from '../masters/HomeHealthCare/Form/HomeHealthcareEdit'
import OthersEdit from '../masters/Others/Form/OthersEdit'
import OtherView from '../masters/Others/Form/OtherView'
import InventoryEdit from '../masters/PartneredPharmacies/Inventory/Form/InventoryEdit'
import InventoryView from '../masters/PartneredPharmacies/Inventory/Form/InventoryView'
import PharmacyBusinessEdit from '../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessEdit'
import PharmacyBusinessView from '../masters/PartneredPharmacies/PharmacyBusiness/Form/PharmacyBusinessView'
import PharmacyStoreEdit from '../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreEdit'
import PharmacyStoreView from '../masters/PartneredPharmacies/PharmacyStore/Form/PharmacyStoreView'
import SocialMediaLink from '../Settings/SocialMediaLinks/SocialMediaLink'
import SocialForm from '../Settings/SocialMediaLinks/Form/SocialForm'
import Comments from '../PopupData/Comments/Comments'
import GetNotified from '../PopupData/GetNotified/GetNotified'
import Notifyme from '../PopupData/Notifyme/Notifyme'
import Subscribe from '../PopupData/Subscribe/Subscribe'
import ViewComment from '../PopupData/Comments/ViewComment'
import BannerImages from '../Settings/BannerImages/BannerImages'
import StoreLinks from '../Settings/StoreLinks/StoreLinks'
import AddNewDoctors from '../Doctors/AddNewDoctor/AddNewDoctors'
import DoctorSpecialisation from '../Doctors/DoctorSpecialisation/DoctorSpecialisation'
import DoctorActivate from '../Doctors/AddNewDoctor/Pages/DoctorActivate'
import DoctorInstaDoc from '../Doctors/AddNewDoctor/DoctorsInstaDoc/DoctorInstaDoc'
import DoctorTrack from '../Doctors/AddNewDoctor/Pages/DoctorTrack'
import BusinessSettings from '../Settings/BusinessSettings/BusinessSettings'
import AddDoctorSplecialisation from '../Doctors/DoctorSpecialisation/form/AddDoctorSplecialisation'
import EditDoctorSplecialisation from '../Doctors/DoctorSpecialisation/form/EditDoctorSplecialisation'
import ViewDoctorSpecialisation from '../Doctors/DoctorSpecialisation/form/ViewDoctorSpecialisation'
import AddNewDoctorForm from '../Doctors/AddNewDoctor/Forms/AddNewDoctorForm'
import EditNewDoctorForm from "../Doctors/AddNewDoctor/Forms/EditNewDoctorForm"
import DoctorsInstaDocForm from '../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorsInstaDocForm'
import DoctorInstaDocEdit from '../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorInstaDocEdit'
import AddNewServiceProvider from '../HomeHealthcare/AddNewServiceProvider/AddNewServiceProvider.'
import AddNewServiceProviderForm from '../HomeHealthcare/AddNewServiceProvider/form/AddNewServiceProviderForm'
import EditNewServiceProviderForm from '../HomeHealthcare/AddNewServiceProvider/form/EditNewServiceProviderForm'
import HHSPSpecialisation from '../HomeHealthcare/HHSPSpecialisation/HHSPSpecialisation'
import AddHsspSpecialisation from '../HomeHealthcare/HHSPSpecialisation/form/AddHsspSpecialisation'
import EditHsspSpecialisation from '../HomeHealthcare/HHSPSpecialisation/form/EditHsspSpecialisation'
import AddNewRetailPharmacies from '../RetailPharmacies/AddNewRetailPharmacies'
import AddNewPharmacyForm from '../RetailPharmacies/forms/AddNewPharmacyForm'
import EditNewPharmacyForm from '../RetailPharmacies/forms/EditNewPharmacyForm'
import AddNewClinic from '../ClinicHospital/AddNewClinic'
import DoctorInclinicAppointment from '../ClinicHospital/DoctorInclinicAppointment/DoctorInclinicAppointment'
import AddNewClinicForm from '../ClinicHospital/form/AddNewClinicForm'
import EditNewClinicForm from '../ClinicHospital/form/EditNewClinicForm'
import AddDoctorInclinicform from '../ClinicHospital/DoctorInclinicAppointment/form/AddDoctorInclinicform'
import EditDoctorInclinicform from '../ClinicHospital/DoctorInclinicAppointment/form/EditDoctorInclinicform'
import HealthcareActivate from '../HomeHealthcare/AddNewServiceProvider/pages/HealthcareActivate'
import HHSPSpecialisationActive from '../HomeHealthcare/HHSPSpecialisation/pages/HHSPSpecialisationActive'
import RetailPharmacyActive from '../RetailPharmacies/pages/RetailPharmacyActive'
import HealthcareTrack from '../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrack'
import RetailPharmacyTrack from '../RetailPharmacies/pages/RetailPharmacyTrack'
import DoctorInclinicTrack from '../ClinicHospital/pages/DoctorInclinicTrack'
import InstaDocTrack from '../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrack'
import ViewDoctorForm from '../Doctors/AddNewDoctor/Forms/ViewDoctorForm'
import DoctorInstaDocView from '../Doctors/AddNewDoctor/DoctorsInstaDoc/form/DoctorInstaDocView'
import ViewNewServicdProvider from '../HomeHealthcare/AddNewServiceProvider/form/ViewNewServicdProvider'
import ViewHsspSpecialisation from '../HomeHealthcare/HHSPSpecialisation/form/ViewHsspSpecialisation'
import ViewNewPharmacyForm from '../RetailPharmacies/forms/ViewNewPharmacyForm'
import ViewNewClinicForm from '../ClinicHospital/form/ViewNewClinicForm'
import ViewDoctorInclinicForm from '../ClinicHospital/DoctorInclinicAppointment/form/ViewDoctorInclinicForm'
import DoctorInclinicRedirectOrder from '../ClinicHospital/pages/DoctorInclinicRedirectOrder'
import DoctorTrackRedirect from '../Doctors/AddNewDoctor/Pages/DoctorTrackRedirect'
import InstaDocTrackRedirect from '../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrackRedirect'
import RetailPharmacyTrackRedirect from '../RetailPharmacies/pages/RetailPharmacyTrackRedirect'
import DoctorTrackView from '../Doctors/AddNewDoctor/Pages/DoctorTrackView'
import InstaDocTrackView from '../Doctors/AddNewDoctor/DoctorsInstaDoc/pages/InstaDocTrackView'
import HealthcareTrackRedirect from '../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrackRedirect'
import HealthcareTrackView from '../HomeHealthcare/AddNewServiceProvider/pages/HealthcareTrackView'
import RetailPharmacyView from '../RetailPharmacies/pages/RetailPharmacyView'
import DoctorInclinicTrackView from '../ClinicHospital/pages/DoctorInclinicTrackView'
import RetailPharmacyTrackEdit from '../RetailPharmacies/pages/RetailPharmacyTrackEdit'
import DoctorInclinicTrackEdit from '../ClinicHospital/pages/DoctorInclinicTrackEdit'
import Home from '../Home/Home'
import DoctorBrochures from '../EmailBrochures/DoctorBrochures'
import DoctorDemoBrochures from '../EmailBrochures/DoctorDemoBrochures'
import PharmacyBrochures from '../EmailBrochures/PharmacyBrochures'
import HealthcareBrochures from '../EmailBrochures/HealthcareBrochures'
import OtherBrochures from '../EmailBrochures/OtherBrochures'
import MetaTags from '../Settings/MetaTags/MetaTags'
import SiteMapSettings from '../Settings/SiteMapSettings/SiteMapSettings'
import EmailSettings from '../Settings/EmailSettings/EmailSettings'

const AllRoutes = () => {
  return (
    <>
      <Home>
      <Routes>
        <Route path='/' element={<Dashboard />} />
        {/* <Route path='/login' element={<Login />} /> */}
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/doctor-demo' element={<DoctorDemo />} />
        <Route path='/doctor-registration' element={<DoctorRegistration />} />
        <Route path='/pharmacy-registration' element={<PharmacyRegistration />} />
        <Route path='/healthcare-registration' element={<HealthcareRegistration />} />
        <Route path='/other-registration' element={<OtherRegistration />} />
        <Route path='/healtharticle' element={<HealthArticle />} />
        <Route path='/healtharticleaddnew' element={<AddNewPageOne />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/careerjobpost' element={<JobPost />} />
        <Route path='/careerjobpostform' element={<JobPostForm />} />
        <Route path='/jobpostedit/:id' element={<JobPostEdit />} />
        <Route path='/departmentFunctionForm' element={<DepartmentForm />} />
        <Route path='/careerdepartment' element={<Department />} />
        <Route path='/careerDepartmentEdit/:id' element={<DepartmentEdit />} />
        <Route path='/careerposition' element={<Position />} />
        <Route path='/careerpositionForm' element={<PositionForm />} />
        <Route path='/careerpositionEdit/:id' element={<PositionEdit />} />
        <Route path="/healtharticlemenu" element={<Menu />} />
        <Route path="/addNewMenuForm" element={<MenuForm />} />
        <Route path="/addNewMenuEditForm/:id" element={<MenuEdit />} />
        <Route path="/addNewCategoryForm" element={<CategoryForm />} />
        <Route path="/addNewCategoryEditForm/:id" element={<CategoryEdit />} />
        <Route path="/Category" element={<Category />} />
        <Route path="/RetailPharmacy" element={<RetailPharmacy/>} />
        <Route path="/healthArticleView/:id" element={<HealthArticleView/>} />
        <Route path='/viewMenuForm/:id' element={<ViewMenu />} />
        <Route path='/viewCategoryForm/:id' element={<ViewCategory/>} />
        <Route path='/healtharticleEdit/:id' element={<HealthArticleEdit />} />
        

        {/*----------------masters--------------------*/}
        <Route path='/master-primary-specialisation' element={<PrimarySpecialisation />} /> 
        <Route path='/master-inclinic-fee' element={<InClinicFee />} />
        <Route path='/master-specialisation-create' element={<SpecialisationForm />} />
        <Route path='/master-in-clinic-create' element={<InClinicForm />} />
        <Route path='/master-specialisation-edit/:id' element={<Edit />} />
        <Route path='/master-specialisation-view/:id' element={<View />} />
        <Route path='/master-inclinic-edit/:id' element={<InclinicEdit />} />
        <Route path='/master-inclinic-view/:id' element={<InclinicView />} />

        <Route path='/master-health-care' element={<HomeHealthCare />} />
        <Route path='/master-health-care-create' element={<HealthcareForm />} />
        <Route path='/master-health-care-edit/:id' element={<HomeHealthcareEdit />} />
        <Route path='/master-health-care-view/:id' element={<HomeHealthcareView />} />

        <Route path='/master-partnered-pharmacy' element={<PharmacyBusiness />} />
        <Route path='/master-partnered-pharmacy-create' element={<PharmacyBusinessForm />} />
        <Route path='/master-partnered-pharmacy-edit/:id' element={<PharmacyBusinessEdit />} />
        <Route path='/master-partnered-pharmacy-view/:id' element={<PharmacyBusinessView />} />
        <Route path='/master-inventory' element={<Inventory />} />
        <Route path='/master-inventory-create' element={<InventoryForm />} />
        <Route path='/master-inventory-edit/:id' element={<InventoryEdit />} />
        <Route path='/master-inventory-view/:id' element={<InventoryView />} />
        <Route path='/master-pharmacy-store' element={<PharmacyStore />} />
        <Route path='/master-pharmacy-store-create' element={<PharmacyStoreForm />} />
        <Route path='/master-pharmacy-store-edit/:id' element={<PharmacyStoreEdit />} />
        <Route path='/master-pharmacy-store-view/:id' element={<PharmacyStoreView />} />

        <Route path='/master-others' element={<Others />} />
        <Route path='/master-others-form' element={<OthersForm />} />
        <Route path='/master-others-edit/:id' element={<OthersEdit />} />
        <Route path='/master-others-view/:id' element={<OtherView />} />

        <Route path='/master-gender' element={<Gender />} />
        <Route path='/master-gender-create' element={<GenderForm />} />
        <Route path='/master-gender-edit/:id' element={<GenderEdit />} />
        <Route path='/master-gender-view/:id' element={<GenderView />} />
        <Route path='/master-experience' element={<Experience />} />
        <Route path='/master-experience-create' element={<ExperienceForm />} />
        <Route path='/master-experience-edit/:id' element={<ExperienceEdit />} />
        <Route path='/master-experience-view/:id' element={<ExperienceView />} />
        <Route path='/master-days' element={<Days />} />
        <Route path='/master-days-create' element={<DaysForm />} />
        <Route path='/master-days-edit/:id' element={<DaysEdit />} />
        <Route path='/master-days-view/:id' element={<DaysView />} />
        <Route path='/master-languages' element={<Lanuages />} />
        <Route path='/master-languages-create' element={<LanguagesForm />} />
        <Route path='/master-languages-edit/:id' element={<LanguagesEdit />} />
        <Route path='/master-languages-view/:id' element={<LanguagesView />} />
        <Route path='/master-location' element={<Location />} />
        <Route path='/master-location-create' element={<LocationForm />} />
        <Route path='/master-location-edit/:id' element={<LocationEdit />} />
        <Route path='/master-location-view/:id' element={<LocationView />} />
        <Route path='/master-timing' element={<Timings />} />
        <Route path='/master-timing-create' element={<TimingForm />} />
        <Route path='/master-timing-edit/:id' element={<TimingEdit />} />
        <Route path='/master-timing-view/:id' element={<TimingView />} />

        {/*---------------Settings----------------*/}
        <Route path='/settings-social-media-link' element={<SocialMediaLink />} />
        <Route path='/settings-social-media-form' element={<SocialForm />} />
        <Route path='/add-banner-images' element={<BannerImages />} />
        <Route path='/store-links' element={<StoreLinks />} />
        <Route path='/business-settings' element={<BusinessSettings />} />
        <Route path='/meta-tags' element={<MetaTags />} />
        <Route path='/site-map-setting' element={<SiteMapSettings />} />
        <Route path='/email-setting' element={<EmailSettings />} />

        {/*--------------Popup Data---------------*/}
        <Route path='/comment' element={<Comments />} />
        <Route path='/getnotified' element={<GetNotified />} />
        <Route path='/notifyme' element={<Notifyme />} />
        <Route path='/blog-subscribe' element={<Subscribe />} />
        <Route path='/view-comment/:id' element={<ViewComment />} />

        {/*--------------Doctors------------------*/}
        <Route path='/add-new-doctors' element={<AddNewDoctors />} />
        <Route path='/add-new-doctors-form' element={<AddNewDoctorForm />} />
        <Route path='/edit-new-doctors-form' element={<EditNewDoctorForm />} />
        <Route path='/view-new-doctors-form' element={<ViewDoctorForm />} />
        <Route path='/doctor-specialisation' element={<DoctorSpecialisation />} />
        <Route path='/doctor-add-new-splecialisation-form' element={<AddDoctorSplecialisation />} />
        <Route path='/doctor-add-new-splecialisation-edit' element={<EditDoctorSplecialisation />} />
        <Route path='/doctor-add-new-splecialisation-view' element={<ViewDoctorSpecialisation />} />
        <Route path='/doctor-activate' element={<DoctorActivate />} />
        <Route path='/doctor-insta-doc' element={<DoctorInstaDoc />} />
        <Route path='/doctor-insta-doc-add-form' element={<DoctorsInstaDocForm />} />
        <Route path='/doctor-insta-doc-add-edit' element={<DoctorInstaDocEdit />} />
        <Route path='/doctor-insta-doc-add-view' element={<DoctorInstaDocView />} />
        <Route path='/doctor-track' element={<DoctorTrack />} />
        <Route path='/doctor-track-view' element={<DoctorTrackView />} />
        <Route path='/doctor-track-redirect' element={<DoctorTrackRedirect />} />
        <Route path='/insta-doc-track' element={<InstaDocTrack />} />
        <Route path='/insta-doc-track-redirect' element={<InstaDocTrackRedirect />} />
        <Route path='/insta-doc-track-view' element={<InstaDocTrackView />} />

        {/*--------------Home Healthcare------------------*/}
        <Route path='/home-healthcare-service-provider' element={<AddNewServiceProvider />} />
        <Route path='/home-healthcare-service-provider-form' element={<AddNewServiceProviderForm />} />
        <Route path='/home-healthcare-service-provider-edit' element={<EditNewServiceProviderForm />} />
        <Route path='/healthcare-view' element={<ViewNewServicdProvider />} />
        <Route path='/home-healthcare-hhsp' element={<HHSPSpecialisation />} />
        <Route path='/home-healthcare-hhsp-form' element={<AddHsspSpecialisation />} />
        <Route path='/home-healthcare-hhsp-edit' element={<EditHsspSpecialisation />} />
        <Route path='/home-healthcare-hhsp-view' element={<ViewHsspSpecialisation />} />
        <Route path='/healthcare-activate' element={<HealthcareActivate />} />
        <Route path='/healthcare-track' element={<HealthcareTrack />} />
        <Route path='/healthcare-track-redirect' element={<HealthcareTrackRedirect />} />
        <Route path='/healthcare-track-view' element={<HealthcareTrackView />} />
        <Route path='/hhsp-activate' element={<HHSPSpecialisationActive />} />

        {/*--------------Retail Pharmacies------------------*/}
        <Route path='/add-new-retail-pharmacy' element={<AddNewRetailPharmacies />} />
        <Route path='/add-new-retail-pharmacy-form' element={<AddNewPharmacyForm />} />
        <Route path='/edit-retail-pharmacy-form' element={<EditNewPharmacyForm />} />
        <Route path='/view-retail-pharmacy-form' element={<ViewNewPharmacyForm />} />
        <Route path='/retail-pharmacy-active' element={<RetailPharmacyActive />} />
        <Route path='/retail-pharmacy-track' element={<RetailPharmacyTrack />} />
        <Route path='/retail-pharmacy-track-redirect' element={<RetailPharmacyTrackRedirect />} />
        <Route path='/retail-pharmacy-track-view' element={<RetailPharmacyView />} />
        <Route path='/retail-pharmacy-track-edit' element={<RetailPharmacyTrackEdit />} />

        {/*--------------Clinic Hospital--------------------*/}
        <Route path='/add-new-clinic' element={<AddNewClinic />} />
        <Route path='/add-new-clinic-form' element={<AddNewClinicForm />} />
        <Route path='/edit-clinic-form' element={<EditNewClinicForm />} />
        <Route path='/view-clinic-form' element={<ViewNewClinicForm />} />
        <Route path='/doctor-inclinic-appointment' element={<DoctorInclinicAppointment />} />
        <Route path='/add-new-inclinic-form' element={<AddDoctorInclinicform />} />
        <Route path='/edit-inclinic-form' element={<EditDoctorInclinicform />} />
        <Route path='/view-inclinic-form' element={<ViewDoctorInclinicForm />} />
        <Route path='/clinic-track' element={<DoctorInclinicTrack />} />
        <Route path='/doctor-inclinic-redirect-order' element={<DoctorInclinicRedirectOrder />} />
        <Route path='/doctor-inclinic-view' element={<DoctorInclinicTrackView />} />
        <Route path='/doctor-inclinic-track-edit' element={<DoctorInclinicTrackEdit />} />

        {/*--------------Email Brochures--------------------*/}
        <Route path='/doctor-registration-brochures' element={<DoctorBrochures />} />
        <Route path='/doctor-demo-registration-brochures' element={<DoctorDemoBrochures />} />
        <Route path='/pharmacy-registration-brochures' element={<PharmacyBrochures />} />
        <Route path='/healthcare-registration-brochures' element={<HealthcareBrochures />} />
        <Route path='/others-registration-brochures' element={<OtherBrochures />} />

      </Routes>
      </Home>
    </>
  )
}

export default AllRoutes