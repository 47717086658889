import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import JoditEditor from "jodit-react";
import back from "../../../../assets/svg/back-icon.svg";
import redtick from "../../../../assets/images/redtick.PNG";
import graytick from "../../../../assets/images/graytick.PNG";
import next from "../../../../assets/svg/next-icon.svg";
import { Link } from "react-router-dom";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import Home from "../../../Home/Home";
import SucessModal from "../../../modal/SucessModal";

const HealthArticleEdit = () => {
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [menu, setMenu] = useState([]);
  const [category, setCategory] = useState([]);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [page, setPage] = useState(false);
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const[menudata, setMenuData]=useState(null)
  const[categorydata, setCategoryData]=useState(null)

  const[activecheck, setActiveCheck]=useState(false)
  const[featuredcheck, setFeaturedCheck]=useState(false)
  const[uploadImage, setUploadImage]=useState('')
  const[authorImage, setAuthorImage]=useState('')

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}health-article/get/${id}`)
      .then((res) => {
        setUploadImage(res.data.uploadImage)
        setAuthorImage(res.data.authorImage)
        setActiveCheck(res.data.active)
        setFeaturedCheck(res.data.featured)
        Object.keys(res.data).forEach((x) => {
          const arr = res.data.metatags;
          const stringData = arr.reduce((result, item) => {
            return `${result}${item} `
          }, "")
          // console.log(stringData, "stringdata...");
          // console.log(res.data.metatags[res.data.metatags.length-1]);
          if(res.data[x]===res.data.metatags){
            setValue(x, stringData)
          }else{
            setValue(x, res.data[x]);
          }
          
        });
      });
  }, []);


  const onSubmit = () => {
    // debugger;
    let fields = getValues();
    // delete fields.job_post_image;
    console.log(fields.description, "desc check...");
    fields.description = document.getElementsByName("description")[1].value;
    fields["uploadImage"] = document.getElementById("uploadImage").files[0] ? document.getElementById("uploadImage").files[0] : fields.uploadImage;
    fields["authorImage"] = document.getElementById("authorImage").files[0] ? document.getElementById("authorImage").files[0] : fields.authorImage;
    fields["metatags"] = fields.metatags.trim().split(' ');
    axios
      .postForm(
        `${process.env.REACT_APP_API_URL}health-article/update-blog-post/${id}`,
        fields
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
        }
      })
  };

  const handleNext = (e) => {
    e.preventDefault();
    setShow(true);
    setPage((prev) => !prev);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setShow(false);
    setPage((prev) => !prev);
  };

  const getMenuData = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
      .then((res) => {
        setMenu(res.data.rows);
      });
  };

  const getCategoryData = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}health-article-category/list`)
      .then((res) => {
        setCategory(res.data.rows);
      });
  };

  useEffect(() => {
    getMenuData();
    getCategoryData();
  }, []);

  const handlePage2back = (e)=>{
    e.preventDefault()
    setShow(false);
    setPage((prev) => !prev);
  }
  const handlePage1back = (e)=>{
    e.preventDefault()
    navigate("/healtharticle")
  }


  const config = {
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    triggerChangeEvent: true,
    height: 300,
    debugLanguage: false,
    tabIndex: -1,
    toolbar: true,
    enter: 'P',
    useSplitMode: false,
    colorPickerDefaultTab: 'background',
    disablePlugins: ['paste', 'stat'],
    events: {},
    textIcons: false,
    uploader: {
      insertImageAsBase64URI: true,
      // isSuccess: function(resp){
      //   return resp
      // },
      // process: function(resp){
      //   return{
      //     files: resp.data.files,
      //     path: resp.data.path,
      //     baseurl: resp.data.baseurl,
      //     error: resp.data.error,
      //     message: resp.data.message
      //   }
      // },
      // defaultHandlerSuccess: function(data){
      //   var i, field = 'files';
      //   if(data[field] && data[field].length){
      //     for(i=0; i<data[field].length; i+=1){
      //       this.selection.insertImage(data.baseurl + data[field[i]])
      //     }
      //   }
      // }
    },
    // uploader: {
    //   url:"/api/upload",
    // },
    // filebrowser: {
    //   ajax: {
    //     url: "/api/file/files",
    //   },
    //   uploader: {
    //      url:"/api/upload"
    //   },
    // },
    imageProcessor: {
      replaceDataURIToBlobIdInView: Boolean
    },
    placeholder: 'Type here',
    showXPathInStatusbar: false,
    imageDefaultWidth: 300,
    selectionCellStyle: 'border: 1px solid #1e88e5 !important;',
    selectionCellStyle: 'width: 100% !important',
    useExtraClassesOptions: true,
    table: {
        data: {
            classList: {
                'table table-bordered': 'Bootstrap Bordered',
                'table table-striped': 'Bootstrap Striped',
                'table table-dark': 'Bootstrap Dark'
            }
        }
    }
  };

  const[active, setActive]=useState(false)
  const[featured, setFeatured]=useState(false)

  const chooseFile1 = document.getElementById("uploadImage");
  const imgPreview1 = document.getElementById("img-preview1");

  const handlegetImgdata1=()=>{
    setUploadImage("")
    const files = chooseFile1.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", function () {
          imgPreview1.style.display = "block";
          imgPreview1.innerHTML = '<img src="' + this.result + '" />';
        });    
      }
  }

  const chooseFile2 = document.getElementById("authorImage");
  const imgPreview2 = document.getElementById("img-preview2");

  const handlegetImgdata2=()=>{
    setAuthorImage("")
    const files = chooseFile2.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", function () {
          imgPreview2.style.display = "block";
          imgPreview2.innerHTML = '<img src="' + this.result + '" />';
        });    
      }
  }

  return (
    <>
      {/* <Home> */}
      {
          show
          ?
          <div 
            onClick={handlePage2back} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
          :
          <div 
            onClick={handlePage1back} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        }
        <div className="addnew1">
          <div className="addnew1-container">
            <div className="addnew1-title">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span>></span>
              <Link
                to="/healtharticle"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  fontWeight: "bold",
                  paddingLeft: "0.4%",
                }}
              >
                <span>Health Article</span>
              </Link>
              <span>></span>
              <span style={{ fontWeight: "bold", fontSize: "15px", paddingLeft:"0.4%" }}>
                Edit Health Articles
              </span>
            </div>
            <div className="addnew1-page">
              <div className="addnew1-page1 " style={{
                borderBottom: page ? "" : "3px solid #CB1B5B"
              }}>
                {
                  <div>
                    <img src={redtick} alt="" />
                  </div>
                }

                <div>Page 1</div>
              </div>
              <div
                onClick={handleNext}
                style={{
                  color: page ? " #CB1B5B" : "black",
                  borderBottom: page && "3px solid #CB1B5B",
                }}
                className="addnew1-page2"
              >
                {page ? (
                  <div>
                    <img src={redtick} alt="" />
                  </div>
                ) : (
                  <div>
                    <img src={graytick} alt="" />
                  </div>
                )}

                <div>Page 2</div>
              </div>
            </div>

            <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{ display: show ? "none" : "block" }}
                className="addnew1-form"
              >
                <div className="d-flex mb-2">
                  <div className="col-md-4">
                    <label for="inputState" className="form-label title">
                      Menu <span className="star">*</span>
                    </label>
                    <select
                     {...register("menu", { required: false })}
                      id="menu"
                      className="form-select addnew-input"
                    >
                      <option selected hidden>
                        Select Menu
                      </option>
                      {menu.map((el) => {
                        return <option 
                          value={el.name}
                          selected={el.name===getValues('menu')?true:false}
                        >{el.name}</option>;
                      })}
                    </select>
                  </div>
                  <div className="col-md-4">
                    <label for="inputState" className="form-label">
                      Category <span className="star">*</span>
                    </label>
                    <select
                     {...register("category", { required: false })}
                      className="form-select addnew-input title"
                    >
                      <option selected hidden>
                        Select Category
                      </option>
                      {category.map((el) => {
                        return (
                          <option 
                            value={el.category}
                            selected={el.category===getValues('category')?true:false}
                          >{el.category}</option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Title <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input"
                    id="title"
                    {...register("title", { required: true })}

                    // placeholder="1234 Main St"
                  />
                </div>
                <div className="addnew-form-active mb-3">
                  <div className="addnew-active">
                    <div className="fo-tit">
                      Active <span className="star">*</span>
                    </div>
                    <div>
                      <div>
                        <div>
                          <input
                          {...register("active")}
                            type="radio"
                            value={true}
                            checked={
                              activecheck===true ? "checked" : null
                            }
                            onClick={()=>{
                              setActiveCheck(true)
                            }}
                          />
                        </div>
                        <div>Yes</div>
                      </div>
                      <div>
                        <div>
                          <input
                           {...register("active")}
                            type="radio"
                            value={false}
                            checked={
                              activecheck===false ? "checked" : null 
                            }
                            onClick={()=>{
                              setActiveCheck(false)
                            }}
                          />
                        </div>
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                  <div className="addnew-featured">
                    <div className="fo-tit">
                      Featured <span className="star">*</span>
                    </div>
                    <div>
                      <div>
                        <div>
                          <input
                          {...register("featured")}
                            type="radio"
                            value={true}
                            checked={
                              featuredcheck===true ? "checked" : null 
                            }
                            onClick={()=>{
                              setFeaturedCheck(true)
                            }}
                          />
                        </div>
                        <div>Yes</div>
                      </div>
                      <div>
                        <div>
                          <input
                          {...register("featured")}
                            type="radio"
                            value={false}
                            checked={
                              featuredcheck===false ? "checked" : null
                            }
                            onClick={()=>{
                              setFeaturedCheck(false)
                            }}
                          />
                        </div>
                        <div>No</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="addnew-form-upload">
                  <div>
                    <div className="title fo-tit fo-tit-top ">
                      Upload image <span className="star">*</span>
                    </div>
                    {
                      uploadImage && uploadImage.length > 0
                      ? <img 
                          src={
                            process.env.REACT_APP_API_URL +
                            "images/" +
                            uploadImage
                          }
                          style={{
                            width:"100px",
                            height:"50px",
                            marginTop:"2%",
                            marginBottom:"5%",
                            display: uploadImage == "" ? 'none' : 'block'
                          }}
                          alt="" 
                        />
                      : null
                    }
                    <div 
                      id="img-preview1"
                      style={{
                        width:"100px",
                        height:"50px",
                        marginTop:"2%",
                        marginBottom:"5%",
                      }}
                    ></div>
                    <div>
                      <input
                        type="file"
                        id="uploadImage"
                        className="hidden custom-file-input"
                        {...register("uploadImage", { required: false })}
                        onChange={(e)=>{
                          handlegetImgdata1()
                        }}
                      />
                    </div>
                  </div>
                  <div className="authorUpload">
                    <div className="title fo-tit fo-tit-top">
                      Upload Author image <span className="star">*</span>
                    </div>
                    {
                      authorImage && authorImage.length > 0
                      ? <img 
                          src={
                            process.env.REACT_APP_API_URL +
                            "images/" +
                            authorImage
                          }
                          style={{
                            width:"50px",
                            height:"50px",
                            marginTop:"2%",
                            marginBottom:"5%",
                            display: authorImage == "" ? 'none' : 'block'
                          }}
                          alt="" 
                        />
                      : null
                    }
                    <div 
                      id="img-preview2"
                      style={{
                        width:"50px",
                        height:"50px",
                        marginTop:"2%",
                        marginBottom:"5%",
                      }}
                    ></div>
                    <div>
                      <input
                        type="file"
                        id="authorImage"
                        className="hidden  custom-file-input"
                        {...register("authorImage", { required: false })}
                        onChange={(e)=>{
                          handlegetImgdata2()
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="addnew-form1-autor-detail mt-3 d-flex">
                  <div className="col-4">
                    <label for="inputAddress" className="form-label title">
                      Author Details <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      {...register("authordetails", { required: true })}

                      // placeholder="1234 Main St"
                    />
                  </div>
                   <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Author Specialization <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authorSpecialization"
                      {...register("authorSpecialization", { required: true })}
                      style={{paddingLeft:"10px"}}
                    />
                  </div>
                </div>
                <div className="addnew-form1-desc mt-3">
                  <div className="title">
                    Description <span className="star">*</span>
                  </div>
                  <div>
                    <JoditEditor
                      {...register("description", { required: false })}
                      ref={editor}
                      value={getValues('description')}
                      config={config}
                      // tabIndex={1} // tabIndex of textarea
                      // onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      // onChange={(newContent) => setContent(newContent)}
                    />
                  </div>
                </div>
                <div className="addnew-form1-videourl mt-3">
                  <div className="col-7">
                    <label for="inputAddress" className="form-label title">
                      Video URL
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input  w-100"
                      id="videoUrl"
                      {...register("videoUrl", { required: false })}
                    />
                  </div>
                </div>
                <div className="addnew-form1-next">
                  <div onClick={handleNext}>
                    <div>
                      <button>NEXT</button>
                    </div>
                    <div>
                      <img src={next} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: show ? "block" : "none" }}
                className="addnew2-form"
              >
                <div className="col-12">
                  <label for="inputAddress" className="form-label title ">
                    Meta Title <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metatitle"
                    {...register("metatitle", { required: true })}
                    // placeholder="1234 Main St"
                  />
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Meta Description <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metadescription"
                    // placeholder="1234 Main St"
                    {...register("metadescription", { required: true })}
                  />
                </div>
                <div className="col-12 ">
                  <label for="inputAddress" className="form-label title">
                    Meta Keywords <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100 "
                    id="metakeywords"
                    {...register("metakeywords", { required: true })}
                  />
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Meta Tags <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metatags"
                    {...register("metatags")}
                  />
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Canonical Url <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="canonicalUrl"
                    {...register("canonicalUrl", { required: true })}
                  />
                </div>
                <div className="addnew1-form1-next">
                  <div className="addnew1-back-next">
                    <div className="addnew1-back">
                      <div onClick={handleBack}>
                        <div>
                          <img src={back} alt="" />
                        </div>
                        <div>BACK</div>
                      </div>
                    </div>
                    <div className="addnew1-next">
                      <div>
                        <button
                          type="submit"
                          className="bg-transparent border-0 text-white"
                        >
                          UPDATE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      {/* </Home> */}
      
      {success && (
        <SucessModal 
          page={"/healtharticle"} 
          name="Health Article" 
          post='updated'
        />
      )}
    </>
  );
};

export default HealthArticleEdit;
