import { useEffect } from 'react';
import './App.scss';
import Login from './components/Account/Login';
import AllRoutes from './components/AllRoutes/AllRoutes';

function App() {

  const logout = JSON.parse(localStorage.getItem("logout"))

  useEffect(()=>{

  }, [logout])

  return (
    <div className="App">
      {
        logout === true || !localStorage.getItem("logout")
        ?
        <Login />
        :
        <AllRoutes />
      }
    </div>
  );
}

export default App;
