import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { useForm } from "react-hook-form";
import { ImageUploadAndPreview } from "../../../Pages/HealthArticle/AddNew/AddNew";
import { useState } from "react";
import SucessModal from "../../../modal/SucessModal";

const AddHsspSpecialisation = () => {

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/home-healthcare-hhsp")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>

        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/home-healthcare-service-provider"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "0%" }}>
                  Home Healthcare Service Provider
                </span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
              <Link
                to="/home-healthcare-hhsp"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span>HHSP Specialisations</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>></span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Add New HHSP Specialisation
              </span>
            </div>

            <form className="career-jobpost-form">

              <div className="addnew-form1-autor-detail mt-3 d-flex">
                <div className="col-md-4">
                  <label for="inputAddress" className="form-label title">
                    Name of HHSP Specialisation
                    <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input"
                    id="authordetails"
                    style={{ paddingLeft: "10px" }}
                  />
                  {errors.authordetails && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Author Name
                    </div>
                  )}
                </div>
                <div className="authorUpload ms-0">
                    <label className="title fo-tit fo-tit-top">
                      Upload Image / Icon for HHSP Specialisation{" "}
                      <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>
                      <ImageUploadAndPreview
                      //  onChange={files => setAuthorImage(files.length ? files[0] : null)}
                      />
                      {errors.authorImage && (
                        <div className={`invalid-feedback d-block`}>
                          Please Select Author Image
                        </div>
                      )}
                    </div>
                  </div>
              </div>

              <div className="addnew-form1-autor-detail mt-3 d-flex">
              <div className="row col-md-6 ms-5">
                    <div className="col">
                      <label className=" small mb-2" htmlFor="">
                        Active HHSP Specialisation <span className="star">*</span>
                      </label>
                      <div className="d-flex">
                        <div className="form-check form-check-inline me-3 mt-1">
                          <input
                            className="form-check-input me-3"
                            type="radio"
                            name="gender"
                            id="gender"
                            value="Active"
                            checked
                          />
                          <label htmlFor="option-1" className="text-dark mt-1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline mt-1">
                          <input
                            className="form-check-input  me-3"
                            type="radio"
                            name="gender"
                            id="gender"
                            value="Inactive"
                          />
                          <label htmlFor="option-2" className="text-dark mt-1">
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.status && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select an Option
                      </div>
                    )}
                  </div>
              </div>

              <div className="car-pos-form-button mb-5">
            <button type="button" onClick={()=> setSuccess(true)}>ADD</button>
          </div>

            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
          page={"/home-healthcare-hhsp"} 
          name="Home Healthcare HHSP" 
          post='posted'
        />
      )}
    </>
  );
};

export default AddHsspSpecialisation;
