import React, { useState } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../modal/SucessModal";
import { useEffect } from "react";

const MetaTags = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}meta-tags/list`)
      .then((res) => {
        console.log(res);
        setData(res.data.rows);
        setId(res.data.rows[0]._id);
        setCount(res.data.count);
        Object.keys(res.data.rows[0]).map((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
  }, [count]);

  const onSubmit = (fields) => {
    if (count == 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL}meta-tags/create`, fields)
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count == 1) {
      let fields = getValues();
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}meta-tags/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Meta Tags</span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="meta-tags-scroll">
            {/*--------------Home Page----------*/}
            <div
              className="mt-3"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Home Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("homepage_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("homepage_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("homepage_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Doctor Demo----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Doctor Demo Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_demo_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_demo_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_demo_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Doctor Registration Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Doctor Registration Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("doctor_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Home Healthcare Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Home Healthcare Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("healthcare_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("healthcare_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("healthcare_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Pharmacy Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Pharmacy Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("pharmacy_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("pharmacy_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("pharmacy_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Other Registration Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Other Registration Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("others_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("others_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("others_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Career Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Career Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("career_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("career_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("career_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>

            {/*--------------Health Article Page----------*/}
            <div
              className="mt-5"
              style={{ fontWeight: "bold", color: "#cb1b5b" }}
            >
              Health Article Page
            </div>
            <div className="row car-dep-form mt-2">
              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Title
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("blog_metaTitle", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6 mt-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Description
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("blog_metaDescription", { required: false })}
                  placeholder="Type here..."
                />
              </div>

              <div className="col-6">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Meta Keyword
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                  {...register("blog_metaKeywords", { required: false })}
                  placeholder="Type here..."
                />
              </div>
            </div>           

            <div className="car-dep-form-button mb-5 mt-5">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
      {success && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/meta-tags"}
          name="Meta tags"
          post="updated"
        />
      )}
    </>
  );
};

export default MetaTags;
