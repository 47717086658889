import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const HealthcareTrackView = () => {
  
    const navigate = useNavigate()

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/healthcare-track")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/add-new-doctors"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Home Healthcare Service Providers
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                Track HHSP
              </span>
              {/* <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>Track</span> */}
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>View Healthcare</span>
            </div>

            <div className="doctor-track-header">
              <div>
                <img src="" alt="" />
              </div>
              <div>
                <div>Vignesh. P</div>
                <div>Nursing Support Service</div>
              </div>
              <div style={{fontWeight:"700", cursor:"pointer"}}>Edit</div>
            </div>

            <div className="doctor-track-data">
              <div>
                <div>Name of Patient</div>
                <div>John K</div>
              </div>
              <div>
                <div>Booking date</div>
                <div>02-02-2022</div>
              </div>
              <div>
                <div>Age</div>
                <div>31</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Gender</div>
                <div>Male</div>
              </div>
              <div>
                <div>Patient's Mobile Number</div>
                <div>+91 72964 45263</div>
              </div>
              {/* <div>
                <div>Booking Date</div>
                <div>02-02-2022</div>
              </div> */}
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Slot</div>
                <div>12:30 PM</div>
              </div>
              <div>
                <div>Duration & Service</div>
                <div>2 Hours</div>
              </div>
              <div>
                <div>Health Concern</div>
                <div>-</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Status</div>
                <div>Upcoming</div>
              </div>
              <div>
                <div>HH Booking ID</div>
                <div>R62591</div>
              </div>
              <div>
                <div>FEES (₹INR)</div>
                <div>₹500</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Payment Mode</div>
                <div>Direct to Service Provider</div>
              </div>
              <div>
                <div>Payment Status</div>
                <div>-</div>
              </div>
              <div>
                <div>Transaction ID</div>
                <div>-</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Patient's Pincode</div>
                <div>421241</div>
              </div>
              <div>
                <div>Additional Service ( if any )</div>
                <div>-</div>
              </div>
              <div>
                <div>Final Invoice (₹INR)</div>
                <div>-</div>
              </div>
            </div>

            <div className="doctor-track-data">
            <div>
                <div>Booking Completed at</div>
                <div>-</div>
              </div>
            </div>


          </div>
        </div>
      {/* </Home> */}
    </>
  );
};

export default HealthcareTrackView