import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Home from "../../../Home/Home";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import SucessModal from "../../../modal/SucessModal";

const PositionEdit = () => {
  const { id } = useParams();
  const [fields, setFields] = useState({});
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false);

  const handleUpdateData = () => {
    let item = {
      functionName1: fields.functionName1,
      functionName2: fields.functionName2,
      active: fields.active,
    };
    fetch(`${process.env.REACT_APP_API_URL}career-add-position/update/${id}`, {
      method: 'PATCH',
        headers:{
            'Accept':'application/json',
            'Content-Type':'application/json'
        },
        body:JSON.stringify(item)
        })
        .then((result)=>{
            result.json()
            .then((resp) => {
              setSuccess(true);
            })
        });
      }

  const getpositionData = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-position/get/${id}`)
      .then((response) => {
        setFields(response.data);
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  useEffect(() => {
    getpositionData();
  }, []);

  return (
    <>
      {/* <Home> */}
         <div 
            onClick={()=>navigate("/careerposition")} 
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
          <div className="healtharticle ps-2 mt-5">
        <div className="healtharticle-title ms-0">
          <Link
            to="/dashboard"
            style={{ textDecoration: "none", color: "black", fontSize: "15px" }}
          >
            <span style={{ paddingLeft: "5%" }}>Dashboard</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft:"1%" }}>&#62;</span>
          <Link
            to="/careers"
            style={{ textDecoration: "none", color: "black", fontSize: "15px" }}
          >
            <span style={{ paddingLeft: "5%" }}>Careers</span>
          </Link>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>&#62;</span>
          <span style={{ paddingLeft: "0.8%", fontSize: "15px" }}>
            Edit Position / Designation
          </span>
        </div>
        <div className="row car-pos-form">
          <div className="col-5">
            <label htmlFor="" className="text-muted small mb-3">
              Select Department /Function Name{" "}
              <span className="text-danger">*</span>
            </label>
            <select
              className="form-control w-75 "
              name=""
              id="functionName1"
              value={fields.functionName1}
              onChange={(e) =>
                setFields((prevState) => {
                  return { ...prevState, functionName1: e.target.value };
                })
              }
            >
              <option>Select</option>
              <option value={fields.functionName1}>{fields.functionName1}</option>
              <option>Engineering</option>
              <option>Designing</option>
              <option>Sales</option>
            </select>
          </div>
          <div className="col-5">
            <label htmlFor="" className="text-muted small mb-3">
              Type Department /Function Name{" "}
              <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              placeholder="Type here.."
              className="form-control w-75"
              name=""
              id="functionName2"
              value={fields.functionName2}
              onChange={(e) =>
                setFields((prevState) => {
                  return { ...prevState, functionName2: e.target.value };
                })
              }
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <label className="text-muted small mb-2" htmlFor="">
              Status <span className="text-danger">*</span>
            </label>
            <div className="d-flex">
              <div className="form-check form-check-inline me-3 mt-1">
                <input
                  className="form-check-input me-3"
                  type="radio"
                  name="status"
                  id="status"
                  onChange={(e) => {
                    setFields((prevState) => {
                      return { ...prevState, active: true };
                    });
                  }}
                  value={true}
                  checked={fields.active === true ? "checked" : null}
                />
                <label htmlFor="option-1" className="text-dark">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline mt-1">
                <input
                  className="form-check-input  me-3"
                  type="radio"
                  name="status"
                  id="status"
                  onChange={(e) => {
                    setFields((prevState) => {
                      return { ...prevState, active: false };
                    });
                  }}
                  value={false}
                  checked={fields.active === false ? "checked" : null}
                />
                <label htmlFor="option-2" className="text-dark">
                  Inactive
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="car-pos-form-button pb-5">
          <button onClick={handleUpdateData}>UPDATE</button>
        </div>
        </div>
      {/* </Home> */}
      
      {success && (
        <SucessModal 
          page={"/careerposition"} 
          name="Position" 
          post='updated'
        />
      )}
    </>
  );
};

export default PositionEdit;
