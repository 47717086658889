import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import Home from "../../../Home/Home";
import SucessModal from "../../../modal/SucessModal";
import "./menu.scss";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

export default function MenuForm() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const onSubmit = (fields) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}health-article-menu/create`,
        fields
      )
      .then((response) => {
        console.log(response, "response...");
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };

  return (
    <div>
      {/* <Home> */}
        <div
          onClick={() => navigate("/healtharticlemenu")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-2">
            <div className="healtharticle-title ms-2">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <Link
                to="/healtharticlemenu"
                style={{
                  textDecoration: "none",
                  color: "black",
                  paddingLeft: "0.4%",
                }}
              >
                <span>Manage Menu and Category</span>
              </Link>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>Add New Menu</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row mt-5 new-menu ms-1">
                <div className="col-3">
                  <label className="form-label " htmlFor="">
                    Name <span>*</span>
                  </label>
                  <input
                    className="form-control w-100 input-menu "
                    type="text"
                    name="name"
                    id="name"
                    {...register("name", { required: true })}
                    placeholder="Enter"
                  />
                  {errors.name && (
                    <div className={`invalid-feedback d-block`}>
                      Please Enter Name
                    </div>
                  )}
                </div>

                <div className="col">
                  <label htmlFor="" className="mb-3 ms-3 ">
                    Active Status <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input
                        className="me-3"
                        type="radio"
                        name="active"
                        id="active"
                        value={true}
                        checked={checked ? "checked" : ""}
                        onClick={() => setChecked(true)}
                        {...register("active", { required: true })}
                      />
                      <label class="" htmlFor="inline1">
                        Yes
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="me-3"
                        type="radio"
                        name="active"
                        id="active"
                        value={false}
                        checked={checked ? "" : "checked"}
                        onClick={() => setChecked(false)}
                        {...register("active", { required: true })}
                      />
                      <label class="" htmlFor="inline2">
                        No
                      </label>
                    </div>
                  </div>
                  {errors.status && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select an Option
                    </div>
                  )}
                </div>
              </div>
              <div className="Add-Btn">
                <button className="add-button" type="submit">
                  Add
                </button>
              </div>
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal 
           page={"/healtharticlemenu"} 
           name="Health Article Menu" 
           post='posted'
        />
      )}
    </div>
  );
}
