import React from "react";
import success from "../../assets/images/animation_300_lb53ccn4 (1).gif";
import "./SucessModel.scss";

export default function SucessModal({ page, name, post }) {
  return (
    <div
      onClick={() => {
        window.location.href = page;
      }}
    >
      <div className="modal-backdrop fade show success-model"></div>
      <div
        style={{ display: "block" }}
        class="modal fade show modal-sm"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class=" modal-content ">
            <div><img className="w-100 " src={success} alt="" /></div>
            <p className="text-center pb-3">A {name} has been {post} successfully</p>
          </div>
        </div>
      </div>
    </div>
  );
}
