import axios from "axios";
import React, { useState, useEffect } from "react";
import Home from "../../Home/Home";
import "./healthsy.scss";
import { useNavigate } from "react-router";
import { FaAngleRight } from "react-icons/fa";
import filter1 from "../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../assets/svg/Health Article/sortbyicon.svg";
import { Link } from "react-router-dom";
import search from "../../../assets/images/searchIcon.png";

// actions icons
import contact from "../../../assets/images/HealthSy/contact.png";
import onboard from "../../../assets/images/HealthSy/onboard.png";
import notcontact from "../../../assets/images/HealthSy/notcontacted.png";
import newdelete from "../../../assets/images/HealthSy/delete.png";
import Deletepopup from "../../modal/Deletepopup";
import Autocomplete from "react-google-autocomplete";
import Moment from "moment";

const DoctorRegistration = () => {
  const [isChecked, setIsChecked] = useState(true);

  const [records, setRecords] = useState([]);
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({ name: "" });

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [feedata, setFeeData] = useState([]);
  const [expdata, setExpData] = useState([]);
  const [genderdata, setGenderData] = useState([]);
  const [languagedata, setLanguageData] = useState([]);
  const [spldata, setSplData] = useState([]);
  const [location, setLocation] = useState("");
  const [submitfilter, setSubmitFilter] = useState(false);

  const fee = [
    {
      text: "Less than INR 99",
    },
    {
      text: "INR 100 to INR 299",
    },
    {
      text: "INR 300 to INR 499",
    },
    {
      text: "INR 500 to INR 799",
    },
    {
      text: "INR 800 to INR 999",
    },
    {
      text: "More than INR 1000",
    },
  ];

  const experience = [
    {
      text: "Less than 1 Years",
    },
    {
      text: "1 - 2 Years",
    },
    {
      text: "2 - 3 Years",
    },
    {
      text: "3 - 5 Years",
    },
    {
      text: "5+ Years",
    },
  ];

  const gender = [
    {
      text: "Male",
    },
    {
      text: "Female",
    },
    {
      text: "Others",
    },
  ];

  const language = [
    {
      text: "Tamil",
    },
    {
      text: "Telugu",
    },
    {
      text: "Hindi",
    },
    {
      text: "English",
    },
    {
      text: "Malayalam",
    },
    {
      text: "Kannada",
    },
    {
      text: "Bengali",
    },
    {
      text: "Marathi",
    },
    {
      text: "Punjabi",
    },
    {
      text: "Others",
    },
  ];

  const primaryspl = [
    {
      text: "Andrology",
    },
    {
      text: "Ayurveda",
    },
    {
      text: "Allergist",
    },
    {
      text: "Bariatrics",
    },
    {
      text: "Cardiology",
    },
    {
      text: "Cosmetology",
    },
    {
      text: "Dentistry",
    },
  ];

  useEffect(() => {
    list_api_call();
  }, [limit, sort, skip]);

  useEffect(() => {
    filter_call();
  }, [feedata, expdata, genderdata, languagedata, spldata, submitfilter]);

  // useEffect(()=>{
  //   filter_Location_Search_call()
  // }, [location, submitfilter])

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const filter_call = () => {
    if (submitfilter === true) {
      axios
        .post(`${process.env.REACT_APP_API_URL}filters/healthsy-filter`, {
          key: "doctors",
          values: {
            languages_known: languagedata,
            in_clinic_fee: feedata,
            experience: expdata,
            specialization: spldata,
            gender: genderdata,
            location: location,
          },
        })
        .then((res) => {
          setRecords(res.data);
        });
    }
  };

  // const filter_Location_Search_call=()=>{
  //   if(submitfilter === true){
  //     axios
  //     .post(`${process.env.REACT_APP_API_URL}filters/location`, {
  //       key: "doctors",
  //       values:{
  //         location:location
  //       }
  //     })
  //     .then((res)=>{
  //       setRecords(res.data)
  //     })
  //   }
  // }

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}filters/sortlist`, {
        // ...filter,
        // skip: skip,
        // limit: limit,
        key: "doctors",
        value: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data);
        // setRowCount(response.data.count);
        // setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).indexOf(filter) > -1;
    });
    setFilteredRecords(rows);
  };

  const handleBookDemo = () => {
    navigate("/doctor-demo");
  };
  const handleService = () => {
    navigate("/healthcare-registration");
  };
  const handleRetail = () => {
    navigate("/RetailPharmacy");
  };
  const handleOthers = () => {
    navigate("/other-registration");
  };

  //Actions

  //Delete Functionality
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  const handleDelete = (id) => {
    fetch(`${process.env.REACT_APP_API_URL}doctor-registration/delete/${id}`, {
      method: "DELETE",
    }).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  //Contact Functionality
  const handleContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`,
        {
          contact: true,
          notContacted: false,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //NotContact Functionality
  const handleNotContact = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`,
        {
          contact: false,
          notContacted: true,
          onBoard: false,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  //On-Board Functionality
  const handleOnBoard = (id) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}doctor-registration/update/${id}`,
        {
          contact: false,
          notContacted: false,
          onBoard: true,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle ps-3">
        <div className="healtharticle-container ">
          <div className="healtharticle-title ms-0">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard"
              >
                Dashboard
              </Link>
            </span>
            <span>&#62;</span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/doctor-registration"
              >
                HealthSynergy
              </Link>
            </span>
            <span style={{ paddingLeft: "0.4%" }}>&#62;</span>
            <span style={{ paddingLeft: "0.4%" }}>
              HealthSy Partnered Doctors Network Programme
            </span>
          </div>

          <div className="careers-tab  ">
            <div className="career-tab-jobpost Doctor">Doctors</div>
            <div className="career-tab-jobpost " onClick={handleRetail}>
              Retail Pharmacy
            </div>
            <div className="career-post-department" onClick={handleService}>
              Home Healthcare Service Providers
            </div>
            <div className="career-post-position other " onClick={handleOthers}>
              Others
            </div>
            <div
              className="career-post-position "
              style={{ color: "#65626C" }}
              onClick={handleBookDemo}
            >
              Book a Demo
            </div>
          </div>

          {/* <div className='row'>
            <div className='col-12 col-md-4'>
              <div className='row'>
                <label className='col-12 col-md-4'>Name</label>
                <div className='col-12 col-md-8'>
                  <input type="text" className='form-control' onChange={e=>setFilter(prevFilter=>{
                    console.log("prevfilter", prevFilter);
                    prevFilter.name=e.target.value
                    return prevFilter;
                  })} />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='row'>
                <label className='col-12 col-md-4'>Mobile</label>
                <div className='col-12 col-md-8'>
                  <input type="text" className='form-control' onChange={e=>setFilter(prevFilter=>{
                    console.log("prevfilter", prevFilter);
                    prevFilter.mobile=e.target.value
                    return prevFilter;
                  })} />
                </div>
              </div>
            </div>
            <div className='col-12 col-md-4'>
              <input type="button" className='btn btn-primary' value="Filter" onClick={list_api_call} />
            </div>
          </div> */}
          {/* <div className='healtharticle-show row'>
            <div className='col d-flex'>
              <label className='me-2'>Show</label>
              <select value={limit} onChange={e=>setLimit(e.target.value)} className='me-2'>
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
              <label>entries of {rows_count}</label>
            </div>
          
            <div className='col'>
              <div className='input-group'>
                <input type="text" placeholder='Search' className='form-control' onChange={e=>filter_records(e.target.value)} />
                <div className="input-group-append input-group-text">
                  <span className=''><FaSearch /></span>
                </div>
              </div>
            </div>
            <div className="col justify-content-end">
              <div className='row'>
                <div className='col-6'>
                  <div className='SortByposition'>
                    <div>
                      <select onChange={e=>setSort(e.target.value)}>
                        <option>Sort by</option>
                        <option value="id-desc">Latest</option>
                        <option value="name-asc">By Name</option>
                        <option value="id-asc">Oldest</option>
                      </select>
                    </div>
                    <div>
                      <img src={sort} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div> */}

          <div className="healtharticle-show mt-5 ms-2">
            <div>Show</div>
            <div>
              <select
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                className="me-2"
              >
                <option>10</option>
                <option>25</option>
                <option>50</option>
                <option>100</option>
                <option value="0">All</option>
              </select>
            </div>
            <div>entries</div>
            <div>
              <div className="">
                {/* <FaSearch /> */}
                <img src={search} alt="" />
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Search"
                  className="form-control"
                  onChange={(e) => filter_records(e.target.value)}
                />
              </div>
            </div>
            <div className="sortandfilter-new">
              <div className="sortByPosition">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setSortShow(!sortshow);
                      }}
                    >
                      {sortName}
                    </div>
                    {/* <select onChange={(e) => setSort(e.target.value)}>
                        <option>Sort by</option>
                        <option value="id-desc">
                          <div style={{ color: "red" }}>Oldest</div>
                        </option>
                        <option value="name-asc">
                          <div>Newest</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↑</div>
                        </option>
                        <option value="id-asc">
                          <div>Alphabetical ↓</div>
                          <span>
                            <img src="" alt="" />
                          </span>
                        </option>
                      </select> */}
                  </div>
                  <div>
                    <img src={sort1} alt="" />
                  </div>
                </div>
              </div>
              <div className="sortByDepartment">
                <div>
                  <div>
                    <div
                      onClick={() => {
                        setFilterShow(true);
                      }}
                    >
                      Filter
                    </div>
                    {/* <select
                        name=""
                        id=""
                        // onChange={handleSort}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                  </div>
                  <div>
                    <img src={filter1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="doctorregistetr healtharticle-table ms-2">
          <table class="doctorregistetr-table">
            <thead className="">
              <tr>
                <th className="sno ">S.No</th>
                <th className="align-middle">Name</th>
                <th className="align-middle">Mobile Number</th>
                <th className="align-middle">Email ID</th>
                <th className="align-middle">Gender</th>
                <th className="align-middle">Primary Specialisation</th>
                <th className="align-middle">Secondary Specialisation</th>
                <th className="align-middle meddical-reg-no">
                  Medical Registration No (MCI)
                </th>
                <th className="align-middle">Memberships</th>
                <th className="align-middle">Experience (Yrs)</th>
                <th className="align-middle">Educational Qualification</th>
                <th className="align-middle">City / Town</th>
                <th className="align-middle">Pincode</th>
                <th className="align-middle">Fees ( &#x20b9; INR)</th>
                <th className="align-middle">Clinic Address</th>
                <th className="align-middle">Registration Date and Time</th>
                <th className="align-middle">Languages Known</th>
                {/* <th className="align-middle">Working Days</th> */}
                {/* <th className="align-middle">Timings</th> */}
                <th className="align-middle">Establishment Details</th>
                {/* <th className="align-middle">
                    Current Association with Other Platforms
                  </th> */}
                {/* <th className="align-middle">
                    List for Online Doctor Consultation
                  </th> */}
                {/* <th className="align-middle">
                    List for In-Clinic Appoinment
                  </th> */}
                <th className="align-middle">Actions</th>
              </tr>
            </thead>
            <tbody>
              {(filtered_records.length > 0 ? filtered_records : records).map(
                (x, i) => {
                  if (x.show === undefined || x.show === true)
                    return (
                      <tr key={x._id}>
                        <td>{(current_page - 1) * limit + (i + 1)}</td>
                        <td>{x.name}</td>
                        <td>{x.mobile}</td>
                        <td>{x.email}</td>
                        <td>{x.gender}</td>
                        <td>{x.specialization}</td>
                        <td>{x.secondary_specialization}</td>
                        <td>{x.mci}</td>
                        <td>{x.memberships}</td>
                        <td>{x.experience}</td>
                        <td>{x.qualification}</td>
                        <td>{x.location}</td>
                        <td>{x.pincode}</td>
                        <td>{x.in_clinic_fee}</td>
                        <td>{x.clinic_address ? x.clinic_address : "-"}</td>
                        <td>{Moment(x.createdAt).format("DD MMMM YYYY LT")}</td>
                        <td>{x?.languages_known?.join(", ")}</td>
                        <td>{x.establishment}</td>
                        {/* <td></td> */}
                        {/* <td></td> */}
                        {/* <td></td> */}
                        <td>
                          <span
                            className={`contact ${
                              x.contact == true ? "contact-highlight" : ""
                            }`}
                            onClick={() => {
                              handleContact(x._id);
                            }}
                          >
                            Contact
                          </span>
                          <span className="contact-logo">
                            <img src={contact} alt="" />
                          </span>
                          <span
                            className={`onboard ${
                              x.onBoard == true ? "onboard-highlight" : ""
                            }`}
                            onClick={() => {
                              // if (
                              //   x.contact == true &&
                              //   x.notContacted == false
                              // ) {
                              //   handleOnBoard(x._id, x.onBoard);
                              // }
                              handleOnBoard(x._id);
                            }}
                          >
                            On-Board
                          </span>
                          <span className="onboard-logo">
                            <img src={onboard} alt="" />
                          </span>
                          <span
                            className={`notcontact ${
                              x.notContacted == true
                                ? "notcontact-highlight"
                                : ""
                            }`}
                            onClick={() => {
                              handleNotContact(x._id);
                            }}
                          >
                            Not Contacted
                          </span>
                          <span className="notcontacted-logo">
                            <img src={notcontact} alt="" />
                          </span>
                          <span
                            className="newdelete"
                            onClick={() => {
                              setId(x._id);
                              setDel(true);
                            }}
                          >
                            Delete
                          </span>
                          <span className="newdelete-logo">
                            <img src={newdelete} alt="" />
                          </span>
                        </td>
                      </tr>
                    );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col d-flex pagination justify-content-end admin-pagination">
        <input
          type="button"
          className="page-item page-link"
          value="<<"
          // onClick={(e) => setCurrentPage(1)}
          disabled={current_page === 1}
        />
        <input
          type="button"
          className="page-item page-link"
          value="<"
          // onClick={(e) => setCurrentPage(current_page - 1)}
          disabled={current_page === 1}
        />

        {current_page > 1 && (
          <input
            type="button"
            value={current_page - 1}
            onClick={(e) => {
              // setCurrentPage(current_page - 1);
            }}
          />
        )}
        <input
          className="page-item page-link"
          type="button"
          value={current_page}
        />
        {current_page < pages && (
          <input
            type="button"
            value={current_page + 1}
            onClick={(e) => {
              // setCurrentPage(current_page + 1);
            }}
          />
        )}
        <input
          type="button"
          value=">"
          className="page-item page-link"
          // onClick={(e) => setCurrentPage(current_page + 1)}
          disabled={current_page === pages}
        />
        <input
          type="button"
          value=">>"
          className="page-link"
          // onClick={(e) => setCurrentPage(pages)}
          disabled={current_page === pages}
        />
      </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

      {/*---------Filter Portion--------*/}
      {filtershow ? (
        <div className="filter-container">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(1);
              }}
            >
              <div
                style={{
                  color: textcolor == 1 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 1 ? "bold" : "",
                }}
              >
                Fee
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(2);
              }}
            >
              <div
                style={{
                  color: textcolor == 2 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 2 ? "bold" : "",
                }}
              >
                Experience
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(3);
              }}
            >
              <div
                style={{
                  color: textcolor == 3 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 3 ? "bold" : "",
                }}
              >
                Gender
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(4);
              }}
            >
              <div
                style={{
                  color: textcolor == 4 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 4 ? "bold" : "",
                }}
              >
                Language
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(5);
              }}
            >
              <div
                style={{
                  color: textcolor == 5 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 5 ? "bold" : "",
                }}
              >
                City / Town
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
            <div
              className="filter-content-card-doctor"
              onClick={() => {
                setTextColor(6);
              }}
            >
              <div
                style={{
                  color: textcolor == 6 ? "#CB1B5B" : "",
                  fontWeight: textcolor == 6 ? "bold" : "",
                }}
              >
                Primary Specialisation
              </div>
              <div>
                <FaAngleRight className="rightarrow" />
              </div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setFeeData([]);
                  setExpData([]);
                  setGenderData([]);
                  setLanguageData([]);
                  setSplData([]);
                  setLocation("");
                }}
              >
                Clear
              </button>
            </div>
            <div>
              <button
                onClick={() => {
                  setFilterShow(false);
                  setSubmitFilter(true);
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Fee---------*/}
      {textcolor == 1 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Fee</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {fee.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setFeeData([...feedata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Experience--------*/}
      {textcolor == 2 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Experience</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {experience.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setExpData([...expdata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Gender--------*/}
      {textcolor == 3 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Gender</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {gender.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setGenderData([...genderdata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------Language--------*/}
      {textcolor == 4 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Language</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {language.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setLanguageData([...languagedata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*-------City / Town--------*/}
      {textcolor == 5 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">City / Town</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            <div className="filter-doctor-search">
              <div>
                <div>
                  <img src={search} alt="" />
                </div>
                <div>
                  {/* <input 
                    type="text" 
                    name="" 
                    id="filter-search" 
                    placeholder="Search"
                    onChange={(e)=>setLocation(e.target.value)}
                  /> */}
                  <Autocomplete
                    apiKey={"AIzaSyABX4LTqTLQGg_b3jFOH8Z6_H5CDqn8tbc"}
                    onPlaceSelected={(place) => {
                      setLocation(place.formatted_address);
                    }}
                    types={["address"]}
                    className="form-control newlocation"
                    defaultValue={location}
                    placeholder=""
                    componentRestrictions={{ country: "us" }}
                    preventDefault
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/*-------Primary Specialisation--------*/}
      {textcolor == 6 && filtershow == true ? (
        <div className="filter-doctor-fee">
          <div className="filter-doctor-fee-header">Primary Specialisation</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {primaryspl.map((el, i) => {
              return (
                <div className="filter-doctor-fee-box">
                  <div>{el.text}</div>
                  <div className="checkbox-wrapper">
                    <input
                      type="checkbox"
                      className={isChecked ? "checked" : ""}
                      name={el.text}
                      id=""
                      onChange={(e) => {
                        setSplData([...spldata, e.target.name]);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}

      {/*--------Sort By Portion---------*/}

      {sortshow ? (
        <div className="sortby-Common">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor == 11 ? "#CB1B5B" : "",
              fontWeight: textcolor == 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor == 12 ? "#CB1B5B" : "",
              fontWeight: textcolor == 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor == 13 ? "#CB1B5B" : "",
              fontWeight: textcolor == 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor == 14 ? "#CB1B5B" : "",
              fontWeight: textcolor == 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DoctorRegistration;
