import React from 'react'
import Home from '../../../Home/Home'
import "./index.scss"
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import NewSuccessModal from '../../../modal/NewSuccessModal';

const DoctorActivate = () => {

    const navigate = useNavigate()
    const [success , setSuccess] = useState(false)

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/add-new-doctors")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>
                <Link
                  to="/add-new-doctors"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    fontSize: "15px",
                  }}
                >
                  Doctors
                </Link>
              </span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>List of Doctors for Online Consultation</span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>Activate Doctor</span>
            </div>

            <div className='mt-5 ms-1' style={{fontWeight:"700"}}>Please Verify & confirm details before activating</div>

            <div className='doctoractivate-card1'>
              <div>
                <div className='doc-label'>Name of Doctor</div>
                <div>Ananth K</div>
              </div>
              <div>
                <div className='doc-label'>Primary Specialisation</div>
                <div>General Physician</div>
              </div>
              <div>
                <div className='doc-label'>Gender</div>
                <div>Male</div>
              </div>
              <div>
                <div className='doc-label'>Age</div>
                <div>29</div>
              </div>
            </div>

            <div className='doctoractivate-card2'>
              <div>
                <div className='doc-label'>Online Consultant Fees (₹ INR)</div>
                <div>₹ 199</div>
              </div>
              <div>
                <div className='doc-label'>Registered Mobile Number</div>
                <div>+91 92226 25629</div>
              </div>
              <div>
                <div className='doc-label'>Registered Email ID</div>
                <div>ananth@gmail.com</div>
              </div>
            </div>

            <div className='doctoractivate-card3'>
              <div>
                <div className='doc-label'>Medical Registered Number</div>
                <div>PJ272611</div>
              </div>
              <div>
                <div className='doc-label'>HealthSy ID</div>
                <div>AR21246</div>
              </div>
              <div>
                <div className='doc-label'>City / Town</div>
                <div>Ranipet</div>
              </div>
            </div>

            <div className='doctor-activate-submit'>
              <button onClick={()=>setSuccess(true)}>CONFIRM & SEND LOGIN CREDENTIALS</button>
            </div>

          </div>
        </div>
      {/* </Home> */}
      {success && (
        <NewSuccessModal 
          page={"/add-new-doctors"} 
          name="Doctor"
        />
      )}
    </>
  )
}

export default DoctorActivate