import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const DoctorDemoBrochures = () => {
    const[filename, setFilename]=useState('')

    return (
      <div className="healtharticle">
          <div className="healtharticle-title">
            <Link
              to="/doctor-registration-brochures"
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
              }}
            >
              <span>Email Brochures</span>
            </Link>
            <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
            <Link
              to=""
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "15px",
                paddingLeft: "1%",
              }}
            >
              <span>Doctor Demo Registration Brochures</span>
            </Link>
          </div>
  
          <form className="carr-dep-form">
  
              {/* ----one---- */}
              <div className="col-3 upload-img mt-5">
                <label className="mb-3  car-text">Upload Doctor Demo Registration Brochure</label>
                <input
                  className="form-control-file custom-file-input"
                  type="file"
                  onChange={(e)=>{
                    setFilename(e.target.files[0].name)
                  }}
                />
                <label className='text-danger'>{filename}</label>
              </div>
              </form>
              <div className="car-dep-form-button mb-5">
                <button type="submit">SUBMIT</button>
              </div>
          </div>
    )
  }

export default DoctorDemoBrochures