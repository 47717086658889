import React from 'react'
import Home from '../../../Home/Home'
import "./index.scss"
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { Link, useNavigate } from 'react-router-dom';
import NewSuccessModal from '../../../modal/NewSuccessModal';
import { useState } from 'react';

const HHSPSpecialisationActive = () => {

  const navigate = useNavigate()
  const [success , setSuccess] = useState(false)

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/home-healthcare-hhsp")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container ps-3">
            <div className="healtharticle-title ms-0">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ paddingLeft: "1%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>Home Healthcare Service Provider</span>
              <span style={{ paddingLeft: "0.4%" }}>></span>
              <span style={{ paddingLeft: "0.4%" }}>Activate Profile</span>
            </div>

            <div className='mt-5 ms-1' style={{fontWeight:"700"}}>Please Verify & confirm details before activating HHSP's Profile</div>

            <div className='health-active'>
              <div className='common-active'>
                <div className='active-title'>Name of Healthcare Service Provider</div>
                <div className='active-box'>Julie. R</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Primary Specialisation</div>
                <div style={{width:"200px"}} className='active-box'>Nursing Support Service</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Secondary Specialisation</div>
                <div className='active-box'>Mom & Baby Care</div>
              </div>
            </div>

            <div className='health-active'>
              <div className='common-active'>
                <div className='active-title'>Gender</div>
                <div style={{width:"200px"}} className='active-box'>Female</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Age</div>
                <div style={{width:"100px"}} className='active-box'>29</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Home Healthcare Booking Fees (INR Per Hour)</div>
                <div className='active-box'>199</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>HealthSy ID</div>
                <div style={{width:"200px"}} className='active-box'>KOSL95612DF</div>
              </div>
            </div>

            <div className='health-active'>
              <div className='common-active'>
                <div className='active-title'>Registered License No</div>
                <div className='active-box'>PJ2J2601</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Registered Mobile Number</div>
                <div style={{width:"200px"}} className='active-box'>+91 92226 25629</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Registered Email ID</div>
                <div className='active-box'>Julie@gmail.com</div>
              </div>
            </div>

            <div className='health-active'>
              <div className='common-active'>
                <div className='active-title'>City / Town</div>
                <div className='active-box'>Ranjipet</div>
              </div>
              <div className='common-active ms-5'>
                <div className='active-title'>Pincode</div>
                <div style={{width:"200px"}} className='active-box'>641 035</div>
              </div>
            </div>

            <div className='doctor-activate-submit'>
              <button onClick={()=>setSuccess(true)}>CONFIRM & SEND LOGIN CREDENTIALS</button>
            </div>

          </div>
        </div>
      {/* </Home> */}
      {success && (
        <NewSuccessModal 
          page={"/home-healthcare-hhsp"} 
          name="HHSP Specialisation"
        />
      )}
    </>
  )
}

export default HHSPSpecialisationActive