import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Home from "../../../Home/Home";
import "./JobPostForm.scss";
import "bootstrap/dist/css/bootstrap.css";
import axios from "axios";
import JoditEditor from "jodit-react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import SucessModal from "../../../modal/SucessModal";
import backRed from "../../../../assets/svg/back-rose-icon.svg";

const JobPostForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const navigate = useNavigate();
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  const [success, setSuccess] = useState(false);

  const [checked, setChecked] = useState(true);

  useEffect(() => {
    handleDepartment();
    handlePosition();
  }, []);

  const handleDepartment = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-department/getData`)
      .then((res) => {
        setDepartment(res.data);
      });
  };

  const handlePosition = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}career-add-position/getData`)
      .then((res) => {
        setPosition(res.data);
      });
  };

  const onSubmit = (fields) => {
    delete fields.job_post_image;
    fields.job_Description =
      document.getElementsByName("job_Description")[0].value;
    fields["job_post_image"] =
      document.getElementById("job-post-image").files[0];
    axios
      .postForm(
        `${process.env.REACT_APP_API_URL}career-add-job-post/create`,
        fields
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
          console.log(success, "success...");
        } else {
          console.log("failed...");
        }
      });
  };

  const config = {
    zIndex: 0,
    readonly: false,
    activeButtonsInReadOnly: ["source", "fullsize", "print", "about"],
    toolbarButtonSize: "middle",
    theme: "default",
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    height: 220,
    direction: "ltr",
    language: "en",
    debugLanguage: false,
    i18n: "en",
    tabIndex: -1,
    toolbar: true,
    enter: "P",
    useSplitMode: false,
    colorPickerDefaultTab: "background",
    // imageDefaultWidth: 100,
    // removeButtons: ['source', 'fullsize', 'about', 'outdent', 'indent', 'video', 'print', 'table', 'fontsize', 'superscript', 'subscript', 'file', 'cut', 'selectall'],
    disablePlugins: ["paste", "stat"],
    events: {},
    textIcons: false,
    uploader: {
      insertImageAsBase64URI: true,
      imageExtensions: ["jpg", "jpeg", "png", "gif"],
    },
    imageExtensions: ["jpg", "jpeg", "png", "gif"],
    placeholder: "Type here",
    showXPathInStatusbar: false,
    controls: {
      font: {
        list: {
          'Mulish': 'Mulish'
        }
      }
    }
  };
  

  const chooseFile = document.getElementById("job-post-image");
  const imgPreview = document.getElementById("img-preview");
  const handlegetImgdata=()=>{
    const files = chooseFile.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", function () {
          imgPreview.style.display = "block";
          imgPreview.innerHTML = '<img src="' + this.result + '" />';
        });    
      }
  }

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/careerjobpost")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>&#62;</span>
              <Link
                to="/careers"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                  paddingLeft: "0.4%",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Careers</span>
              </Link>
              <span style={{ paddingLeft: "1%", fontSize: "15px" }}>&#62;</span>
              <span style={{ paddingLeft: "0.7%", fontSize: "15px" }}>
                Add New Job Post
              </span>
            </div>

            <form
              className="career-jobpost-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="row">
                <div className="select-department col-5">
                  <label className="mb-2 car-text">
                    Select Department / Function Name{" "}
                    <span className="star">*</span>
                  </label>
                  <div className="Department-Select">
                    <select
                      className="form-select car-job-select"
                      {...register("department", { required: true })}
                    >
                      <option selected hidden>
                        Select
                      </option>
                      {department.map((el) => {
                        return (
                          <option value={el.department}>{el.department}</option>
                        );
                      })}
                    </select>
                    {errors.department && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select department
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-5">
                  <label className="car-text mb-2 ">
                    Job Location <span>*</span>
                  </label>
                  <input
                    className="form-control car-job-input"
                    type="text"
                    {...register("job_Location", { required: true })}
                    placeholder="Type here"
                  />
                  {errors.job_Location && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Job Location
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-4">
                  <label className="mb-3 car-text">
                    Type of Job <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("type_of_job", { required: true })}
                  />
                  {errors.type_of_job && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Type of Job
                    </div>
                  )}
                </div>
                <div className="col-2">
                  <label
                    style={{ fontSize: "12px" }}
                    className="mb-3  car-text"
                  >
                    Total Open Positions <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("total_Open_Position", { required: true })}
                  />
                  {errors.total_Open_Position && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Total Open Positions
                    </div>
                  )}
                </div>
                <div className="col-6">
                  <label
                    className="mb-3  car-text"
                    style={{ fontSize: "12px" }}
                  >
                    Select Position / Designation <span>*</span>
                  </label>
                  <div className="Select-Position">
                    <select
                      className="form-select w-75 car-job-select"
                      {...register("designation", { required: true })}
                    >
                      <option value="">Select</option>
                      {position.map((el) => {
                        return (
                          <option value={el.functionName2}>
                            {el.functionName2}
                          </option>
                        );
                      })}
                    </select>
                    {errors.designation && (
                      <div className={`invalid-feedback d-block`}>
                        Please Select Designation
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-4 ">
                <div className="col-3 upload-img">
                  <label className="mb-3  car-text">
                    Upload Image For Job Post <span>*</span>
                  </label>
                  <div id="img-preview"></div>
                  <input
                    className="form-control-file custom-file-input"
                    type="file"
                    id="job-post-image"
                    {...register("job_post_image", { required: true })}
                    onChange={()=>{
                      handlegetImgdata()
                    }}
                  />
                  {errors.job_post_image && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select Job Post Image
                    </div>
                  )}
                </div>
              </div>
              <div className="job-description row">
                <div className="col">
                  <label className="mb-3 mt-3 car-text">
                    Job Description <span>*</span>
                  </label>
                  <JoditEditor
                    {...register("job_Description", { required: false })}
                    ref={editor}
                    value={content}
                    // config={config}
                    // placeholder="Type here"
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => setContent(newContent)}
                  />
                  {/* <textarea
                    class="form-control w-100 car-job-des"
                    id="car-job-des"
                    placeholder="Type here"
                    rows="3"
                    {...register("job_Description", { required: true })}
                  ></textarea> */}
                  {errors.job_Description && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Job Description
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3 me-2">
                  <label className="mb-2 car-text">
                    Organisation Name <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("organisation_Name", { required: true })}
                  />
                  {errors.organization_name && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Organisation Name
                    </div>
                  )}
                </div>
                <div className="col-3 me-2">
                  <label className="mb-2 car-text">
                    Employment Start Date <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    {...register("employment_Startdate", { required: true })}
                  />
                  {errors.employment_Startdate && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Employment Start Date
                    </div>
                  )}
                </div>

                <div className="col-2 me-2 form-date">
                  <label className="mb-2 car-text">
                    Total Working Days <span>*</span>
                  </label>
                  <input
                    className="form-control form-date"
                    placeholder="Type"
                    type="text"
                    {...register("total_working_days", { required: true })}
                  />
                  {errors.total_working_day && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Total Working Days
                    </div>
                  )}
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-3 me-3">
                  <label className="mb-3 car-text">
                    {" "}
                    Job Post Expiry <span>*</span>{" "}
                  </label>
                  <input
                    className="form-control form-date"
                    placeholder="dd-mm-yy"
                    type="date"
                    {...register("jobpost_expiry", { required: true })}
                  />
                  {errors.jobpost_expiry && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide Job Post Expiry
                    </div>
                  )}
                </div>

                <div className="col ">
                  <label className="car-text">
                    Status <span>*</span>
                  </label>
                  <div className="d-flex mt-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value="Active"
                        checked={checked ? "checked" : ""}
                        onClick={() => setChecked(true)}
                        {...register("status", { required: true })}
                      />
                      <label class="" for="option-1" className="mt-1">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input me-3"
                        type="radio"
                        value="Inactive"
                        checked={checked ? "" : "checked"}
                        onClick={() => setChecked(false)}
                        {...register("status", { required: true })}
                      />
                      <label class="" for="option-2" className="mt-1">
                        Inactive
                      </label>
                    </div>
                  </div>
                  {errors.status && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select an Option
                    </div>
                  )}
                </div>
              </div>

              <div className="car-job-button">
                <button
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && 
      <SucessModal 
        page={"/careerjobpost"} 
        name="new job" 
        post='posted'
      />}
    </>
  );
};

export default JobPostForm;
