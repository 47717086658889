import React from "react";
import Home from "../../../Home/Home";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import "./department.scss";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import edit from "../../../../assets/svg/edit.svg";
import inactive from "../../../../assets/svg/inactive.svg";
import { useNavigate } from "react-router";
import filter1 from "../../../../assets/svg/Health Article/filtericon.svg";
import sort1 from "../../../../assets/svg/Health Article/sortbyicon.svg";
import { Link } from "react-router-dom";
import buttonadd from "../../../../assets/images/plus.png";
import Deletepopup from "../../../modal/Deletepopup";
import search from "../../../../assets/images/searchIcon.png";
import { FaAngleRight } from "react-icons/fa";

const Department = () => {
  const [filtered_records, setFilteredRecords] = useState([]);
  const [rows_count, setRowCount] = useState(0);
  const [pages, setPages] = useState(0);
  const [current_page, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState("newest");
  const [sortName, setSortName] = useState("Sort by");
  const [filter, setFilter] = useState({});
  const [records, setRecords] = useState([]);
  const [del, setDel] = useState(false);
  const [id, setId] = useState("");

  //filter portion
  const [filtershow, setFilterShow] = useState(false);
  const [sortshow, setSortShow] = useState(false);
  const [textcolor, setTextColor] = useState(0);

  const [submitfilter, setSubmitFilter] = useState(false);
  const [departmentdata, setDepartmentData] = useState([])
  const [statusdata, setStatusData] = useState([])

  const department=[
    {
      text:"Engineering"
    },
    {
      text:"Sales"
    },
    {
      text:"Content"
    }
  ]

  const status=[
    {
      text:"Active"
    },
    {
      text:"Inactive"
    }
  ]


  const handleActive = (id, status) => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}career-add-department/update/${id}`,
        {
          active: !status,
        }
      )
      .then((result) => {
        list_api_call();
      });
  };

  useEffect(() => {
    list_api_call();
  }, [filter, limit, sort, skip]);

  useEffect(() => {
    if (current_page > 1) {
      setSkip((current_page - 1) * limit);
    } else {
      setSkip(0);
    }
  }, [current_page]);

  const list_api_call = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}career-add-department/sortlist`, {
        // ...filter,
        // skip: skip,
        // limit: limit,
        // sort: sort,
        value: sort,
      })
      .then((response) => {
        setFilteredRecords([]);
        setRecords(response.data);
        // setRowCount(response.data.count);
        // setPages(Math.ceil(response.data.count / limit));
      })
      .catch((err) => {
        console.error(err);
        alert("Some error");
      });
  };

  const filter_records = (filter) => {
    let rows = records.filter((x) => {
      return JSON.stringify(x).toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    setFilteredRecords(rows);
  };

  const handledepartmentForm = () => {
    navigate("/departmentFunctionForm");
  };

  const handleDelete = (id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}career-add-department/delete/${id}`,
      {
        method: "DELETE",
      }
    ).then((result) => {
      result.json().then((res) => {
        list_api_call();
        setDel(false);
      });
    });
  };

  const handleEdit = (id) => {
    navigate(`/careerDepartmentEdit/${id}`);
  };

  return (
    <>
      {/* <Home> */}
        <div className="healtharticle">
          <div className="healtharticle-container">
            <div className="healtharticle-title ms-4">
              <Link
                to="/dashboard"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Dashboard</span>
              </Link>
              <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
              <Link
                to="/careers"
                style={{
                  textDecoration: "none",
                  color: "black",
                  fontSize: "15px",
                }}
              >
                <span style={{ paddingLeft: "5%" }}>Careers</span>
              </Link>
              <span style={{ paddingLeft: "0.9%", fontSize: "15px" }}>></span>
              <span style={{ paddingLeft: "0.6%", fontSize: "15px" }}>
                Department / Function
              </span>
            </div>
            <div className="healtharticle-addnew">
              <div
                id="addnewpost"
                style={{ width: "35%" }}
                onClick={handledepartmentForm}
                className="addnewhealth"
              >
                <div>
                  {/* <BsFillPlusCircleFill /> */}
                  <img src={buttonadd} alt="" />
                </div>
                <div>
                  <button>Add New Department / Function</button>
                </div>
              </div>
            </div>
            <div className="careers-tab" style={{ marginLeft: "2%" }}>
              <div
                className="career-tab-career"
                onClick={() => navigate("/careers")}
              >
                Careers
              </div>
              <div
                className="career-tab-jobpost"
                onClick={() => navigate("/careerjobpost")}
              >
                Job Post
              </div>
              <div className="career-post-department depart">
                Department / Function
              </div>
              <div
                className="career-post-position"
                onClick={() => navigate("/careerposition")}
              >
                Position / Designation
              </div>
            </div>

            <div className="healtharticle-show mt-5">
              <div>Show</div>
              <div>
                <select
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  className="me-2"
                >
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>100</option>
                  <option value="0">All</option>
                </select>
              </div>
              <div>entries</div>
              <div>
                <div>
                  {/* <FaSearch /> */}
                  <img src={search} alt="" />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search"
                    className="form-control"
                    onChange={(e) => filter_records(e.target.value)}
                  />
                </div>
              </div>
              <div className="sortandfilter-new">
                <div className="sortByPosition">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setSortShow(!sortshow);
                        }}
                      >
                        {sortName}
                      </div>
                      {/* <select onChange={(e) => setSort(e.target.value)}>
                          <option>Sort by</option>
                          <option value="id-desc"><div style={{color:"red"}}>Oldest</div></option>
                          <option value="name-asc"><div>Newest</div></option>
                          <option value="id-asc"><div>Alphabetical ↑</div></option>
                          <option value="id-asc"><div>Alphabetical ↓</div><span><img src="" alt="" /></span></option>
                        </select> */}
                    </div>
                    <div>
                      <img src={sort1} alt="" />
                    </div>
                  </div>
                </div>
                <div className="sortByDepartment">
                  <div>
                    <div>
                      <div
                        onClick={() => {
                          setFilterShow(true);
                        }}
                      >
                        Filter
                      </div>
                      {/* <select
                        name=""
                        id=""
                        onChange={(e) => {
                          setFilter((prevFilter) => {
                            prevFilter.active = e.target.value;
                            return prevFilter;
                          });
                          list_api_call();
                        }}
                      >
                        <option value="">Filter</option>
                        <option value="Asc to Des">Asc to Des</option>
                        <option value="Des to Asc">Des to Asc</option>
                      </select> */}
                    </div>
                    <div>
                      <img src={filter1} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="health-article department-function career-department-table">
              <table className="">
                <thead>
                  <tr>
                    <td className="serial ">S.No</td>
                    <td className="department-fun ">
                      Department / Function Name
                    </td>
                    {/* <td className="position  ">Position / Designation</td> */}
                    <td className="action ">Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {(filtered_records.length > 0
                    ? filtered_records
                    : records
                  ).map((el, i) => {
                    if (el.show === undefined || el.show === true)
                      return (
                        <tr key={el._id}>
                          <td>{(current_page - 1) * limit + (i + 1)}</td>
                          <td>{el.department}</td>
                          {/* <td></td> */}
                          <td>
                            <span
                              onClick={() => handleEdit(el._id)}
                              className="edit"
                            >
                              Edit
                            </span>
                            <span className="edit-icon">
                              <img src={edit} alt="" />
                            </span>
                            <span
                              type="submit"
                              onClick={() => {
                                //alert("Are you sure want to delete?", handleDelete(el._id))
                                setId(el._id);
                                setDel(true);
                              }}
                              className="delete"
                            >
                              Delete
                            </span>
                            <span className="delete-icon"></span>
                            <span
                              className="inactive"
                              onClick={() => {
                                handleActive(el._id, el.active);
                              }}
                            >
                              {el.active ? "Inactive" : "Active"}
                            </span>
                            <span className="inactive-icon">
                              <img src={inactive} alt="" />
                            </span>
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="col d-flex pagination justify-content-end mt-5 admin-pagination">
          <input
            type="button"
            className="page-item page-link"
            value="<<"
            // onClick={(e) => setCurrentPage(1)}
            disabled={current_page === 1}
          />
          <input
            type="button"
            className="page-item page-link"
            value="<"
            // onClick={(e) => setCurrentPage(current_page - 1)}
            disabled={current_page === 1}
          />

          {current_page > 1 && (
            <input
              type="button"
              value={current_page - 1}
              onClick={(e) => {
                // setCurrentPage(current_page - 1);
              }}
            />
          )}
          <input
            className="page-item page-link"
            type="button"
            value={current_page}
          />
          {current_page < pages && (
            <input
              type="button"
              value={current_page + 1}
              onClick={(e) => {
                // setCurrentPage(current_page + 1);
              }}
            />
          )}
          <input
            type="button"
            value=">"
            className="page-item page-link"
            // onClick={(e) => setCurrentPage(current_page + 1)}
            disabled={current_page === pages}
          />
          <input
            type="button"
            value=">>"
            className="page-link"
            // onClick={(e) => setCurrentPage(pages)}
            disabled={current_page === pages}
          />
        </div>
      {/* </Home> */}
      {del === true && <Deletepopup handleDelete={handleDelete} id={id} />}

       {/*---------Filter Portion--------*/}
       {
        filtershow
        ?
        <div className="filter-container-career">
          <div className="filter-header">Filter</div>
          <div className="filter-header-hr"></div>
          <div className="filter-content-doctor">
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(1)
              }}
            >
              <div style={{color:textcolor==1?"#CB1B5B":"", fontWeight:textcolor==1?"bold":""}}>Department</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
            <div 
              className="filter-content-card-doctor"
              onClick={()=>{
                setTextColor(2)
              }}
            >
              <div style={{color:textcolor==2?"#CB1B5B":"", fontWeight:textcolor==2?"bold":""}}>Status</div>
              <div><FaAngleRight className="rightarrow"/></div>
            </div>
          </div>
          <div className="filter-foot-btn">
            <div>
              <button onClick={()=>{
                setFilterShow(false)
              }}>Clear</button>
            </div>
            <div>
              <button 
                onClick={()=>{
                  setFilterShow(false)
                  setSubmitFilter(true)
                }}
              >Apply</button>
            </div>
          </div>
        </div>
        : null
      }

       {/*-------Department---------*/}
       {
        textcolor == 1 && filtershow == true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Department</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              department.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div>
                      <input 
                        type="radio" 
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setDepartmentData([...departmentdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {/*-------Status---------*/}
      {
        textcolor == 2 && filtershow == true
        ? 
        <div className="filter-doctor-fee-career">
          <div className="filter-doctor-fee-header">Type of Job</div>
          <div className="filter-doctor-fee-hr"></div>
          <div className="filter-doctor-fee-container">
            {
              status.map((el, i)=>{
                return(
                  <div className='filter-doctor-fee-box'>
                    <div>{el.text}</div>
                    <div>
                      <input 
                        type="radio" 
                        name={el.text} 
                        id="" 
                        onChange={(e)=>{
                          setStatusData([...statusdata, e.target.name])
                        }}
                      />
                    </div>
                  </div>
                )
              })
            }
           </div>
        </div>
        : null
      }

      {sortshow ? (
        <div className="sortby-Common-career">
          <div
            onClick={() => {
              setTextColor(11);
              setSortShow(false);
              setSort("oldest");
              setSortName("Oldest");
            }}
            style={{
              color: textcolor == 11 ? "#CB1B5B" : "",
              fontWeight: textcolor == 11 ? "bold" : "",
            }}
          >
            Oldest
          </div>
          <div
            onClick={() => {
              setTextColor(12);
              setSortShow(false);
              setSort("newest");
              setSortName("Newest");
            }}
            style={{
              color: textcolor == 12 ? "#CB1B5B" : "",
              fontWeight: textcolor == 12 ? "bold" : "",
            }}
          >
            Newest
          </div>
          <div
            onClick={() => {
              setTextColor(13);
              setSortShow(false);
              setSort("ascending");
              setSortName("Ascending");
            }}
            style={{
              color: textcolor == 13 ? "#CB1B5B" : "",
              fontWeight: textcolor == 13 ? "bold" : "",
            }}
          >
            Alphabetical ↑
          </div>
          <div
            onClick={() => {
              setTextColor(14);
              setSortShow(false);
              setSort("descending");
              setSortName("Descending");
            }}
            style={{
              color: textcolor == 14 ? "#CB1B5B" : "",
              fontWeight: textcolor == 14 ? "bold" : "",
            }}
          >
            Alphabetical ↓
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Department;
