import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Home from "../../../Home/Home";
import "./index.scss";
import redtick from "../../../../assets/images/redtick.PNG";
import graytick from "../../../../assets/images/graytick.PNG";
import next from "../../../../assets/svg/next-icon.svg";
import backRed from "../../../../assets/svg/back-rose-icon.svg";
import { useRef } from "react";
import { useState } from "react";
import JoditEditor from "jodit-react";
import back from "../../../../assets/svg/back-icon.svg";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import SucessModal from "../../../modal/SucessModal";
import search from "../../../../assets/images/searchIcon.png"

const AddNewPageOne = () => {

  const {
      getValues,
      register,
      setValue,
      formState: { errors },
      handleSubmit
    } = useForm();


  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(false);
  const[menu,setMenu]=useState([])
  const[category,setCategory]=useState([])
  const [success, setSuccess] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);
    const [authorImage, setAuthorImage] = useState(null);
  const navigate = useNavigate()

  useEffect(()=>{
    getMenuData()
    getCategoryData()
  }, [])

  const getMenuData=()=>{
    axios
    .post(`${process.env.REACT_APP_API_URL}health-article-menu/list`)
    .then((res)=>{
      setMenu(res.data.rows)
    })
  }

  const getCategoryData=()=>{
    axios
    .post(`${process.env.REACT_APP_API_URL}health-article-category/list`)
    .then((res)=>{
      setCategory(res.data.rows)
    })
  }

  const handleNext = (e) => {
    e.preventDefault();
      setShow(true);
      setPage((prev) => !prev);
  };

  const handleBack = (e) => {
    e.preventDefault();
    setShow(false);
    setPage((prev) => !prev);
  };

  const handlePage2back = (e)=>{
    e.preventDefault()
    setShow(false);
    setPage((prev) => !prev);
  }
  const handlePage1back = (e)=>{
    e.preventDefault()
    navigate("/healtharticle")
  }

  const onSubmit = (fields) => {
    delete fields.uploadImage;
    Object.keys(fields).map((key) => setValue(key, fields[key]?.trim()));
    fields.description = document.getElementsByName("healtharticle_description")[0].value;
    fields["uploadImage"] = bannerImage;
    fields["authorImage"] = authorImage;
    let updatedFields = { ...fields, ...getValues() }
    axios
      .postForm(`${process.env.REACT_APP_API_URL}health-article/create`,
      updatedFields)
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true)
        } else {
          alert("Fail");
        }
      })
  };
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(true)

  const config = {
    zIndex: 0,
    readonly: false,
    iframe: true,
    useInputsPlaceholder: true,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 474,
    activeButtonsInReadOnly: ['source', 'fullsize', 'print', 'about'],
    toolbarButtonSize: 'middle',
    theme: 'default',
    enableDragAndDropFileToEditor: true,
    saveModeInCookie: false,
    spellcheck: true,
    editorCssClass: true,
    triggerChangeEvent: true,
    height: 220,
    direction: 'ltr',
    language: 'en',
    debugLanguage: false,
    i18n: 'en',
    tabIndex: -1,
    toolbar: true,
    enter: 'P',
    useSplitMode: false,
    colorPickerDefaultTab: 'background',
    disablePlugins: ['paste', 'stat'],
    events: {},
    textIcons: false,
    uploader: {
      insertImageAsBase64URI: true,
      imageExtensions: ["jpg", "jpeg", "png", "gif"],
    },
    imageExtensions:["jpg", "jpeg", "png", "gif"],
    placeholder: 'Type here',
    showXPathInStatusbar: false,
    imageProcessor: {
      replaceDataURIToBlobIdInView: Boolean
    },
    imageDefaultWidth: 300,
    selectionCellStyle: 'border: 1px solid #1e88e5 !important;',
    selectionCellStyle: 'width: 100% !important',
    useExtraClassesOptions: true,
    table: {
        width: 100,
        data: {
            classList: {
                'table table-bordered': 'Bootstrap Bordered',
                'table table-striped': 'Bootstrap Striped',
                'table table-dark': 'Bootstrap Dark'
            }
        }
    }
  };

  const chooseFile1 = document.getElementById("uploadImage");
  const imgPreview1 = document.getElementById("img-preview-one");

  const handlegetImgdata1=()=>{
    const files = chooseFile1.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", function () {
          imgPreview1.style.display = "block";
          imgPreview1.innerHTML = '<img src="' + this.result + '" />';
        });
      }
  }

  const chooseFile2 = document.getElementById("authorImage");
  const imgPreview2 = document.getElementById("img-preview-two");

  const handlegetImgdata2=()=>{
    const files = chooseFile2.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", function () {
          imgPreview2.style.display = "block";
          imgPreview2.innerHTML = '<img src="' + this.result + '" />';
        });
      }
  }

  return (
    <>
      {/* <Home> */}
        {
          show
          ?
          <div
            onClick={handlePage2back}
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
          :
          <div
            onClick={handlePage1back}
            className="rose back-button"
          >
            <img src={backRed} alt="" />
            <span className="rose-back">Back</span>
          </div>
        }
        <div className="healtharticle addnew1">
          <div className="addnew1-container" style={{marginLeft:"2%"}}>
            <div className="addnew1-title">
            <Link to="/dashboard" style={{textDecoration:"none", color:"black", fontSize:"15px", fontWeight:"bold"}}><span style={{paddingLeft:"5%"}}>Dashboard</span></Link>
              <span>&#62;</span>
              <Link to="/healtharticle" style={{textDecoration:"none", color:"black", fontSize:"15px", fontWeight:"bold", paddingLeft:"0.4%"}}><span>Health Article</span></Link>
              <span>&#62;</span>
              <span style={{fontWeight:"bold", fontSize:"15px",marginRight:"0", paddingLeft:"0.4%"}}>Add New Health Articles</span>
            </div>
            <div className="addnew1-page">
              <div className="addnew1-page1" style={{
                borderBottom: page ? "" : "3px solid #CB1B5B"
              }}>
                {
                  <div>
                    <img src={redtick} alt="" />
                  </div>
                }

                <div onClick={handleBack}>Page 1</div>
              </div>
              <div
                onClick={handleNext}
                style={{
                  color: page ? " #CB1B5B" : "black",
                  borderBottom: page && "3px solid #CB1B5B",
                }}
                className="addnew1-page2"
              >
                {page ? (
                  <div>
                    <img src={redtick} alt="" />
                  </div>
                ) : (
                  <div>
                    <img src={graytick} alt="" />
                  </div>
                )}

                <div>Page 2</div>
              </div>
            </div>

            <form className="row g-3 health-art-form" onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{ display: show ? "none" : "block" }}
                className="addnew1-form"
              >
                <div className="d-flex mb-2">
                  <div className="col-md-4">
                    <label for="inputState" className="form-label title">
                      Menu <span className="star">*</span>
                    </label>
                    <select
                      id="menu"
                      className="form-select addnew-input"
                      {...register("menu", { required: true })}
                    >
                      <option selected hidden>Select Menu</option>
                      {
                        menu.map((el)=>{
                          if(el.active == true){
                            return(
                              <option value={el.name}>{el.name}</option>
                            )
                          }
                        })
                      }
                    </select>
                    {errors.menu && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Menu
                    </div>
                  )}
                  </div>
                  <div className="col-md-4">
                    <label for="inputState" className="form-label">
                      Category <span className="star">*</span>
                    </label>
                    <select
                      id="category"
                      className="form-select addnew-input title"
                      {...register("category", { required: true })}
                    >
                      <option selected hidden>Select Category</option>
                      {
                        category.map((el)=>{
                          if(el.active == true){
                            return(
                              <option value={el.category}>{el.category}</option>
                            )
                          }
                        })
                      }
                    </select>
                    {errors.category && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Category
                    </div>
                  )}
                  </div>
                </div>

                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Title <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input"
                    style={{paddingLeft:"10px"}}
                    id="title"
                    {...register("title", { required: true })}

                    // placeholder="1234 Main St"
                  />
                  {errors.title && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Title
                    </div>
                  )}
                </div>
                <div className="addnew-form-active mb-3">
                  <div className="addnew-active">
                    <label className="fo-tit">
                      Active <span className="star">*</span>
                    </label>
                    <div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            value={true}
                            id="active"
                            name="active"
                            checked={checked1 ? "checked" : ""}
                            onClick={()=>setChecked1(true)}
                            {...register("active", { required: true })}
                            // checked
                          />
                        </div>
                        <div>Yes</div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            value={false}
                            id="active"
                            name="active"
                            checked={checked1 ? "" : "checked"}
                            onClick={()=>setChecked1(false)}
                            {...register("active", { required: true })}

                          />
                        </div>
                        <div>No</div>
                      </div>
                    </div>
                    {errors.active && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select a option
                    </div>
                  )}
                  </div>
                  <div className="addnew-featured">
                    <label className="fo-tit">
                      Featured <span className="star">*</span>
                    </label>
                    <div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            id="featured"
                            name="featured"
                            value={true}
                            {...register("featured", { required: true })}
                            checked={checked2 ? "checked" : ""}
                            onClick={()=>setChecked2(true)}
                          />
                        </div>
                        <div>Yes</div>
                      </div>
                      <div>
                        <div>
                          <input
                            type="radio"
                            id="featured"
                            name="featured"
                            value={false}
                            checked={checked2 ? "" : "checked"}
                            onClick={()=>setChecked2(false)}
                            {...register("featured", { required: true })}
                          />
                        </div>
                        <div>No</div>
                      </div>
                    </div>
                    {errors.featured && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select a option
                    </div>
                  )}
                  </div>
                </div>
                <div className="addnew-form-upload">
                  <div>
                    <label className="title fo-tit fo-tit-top ">
                      Upload image <span className="star">*</span>
                    </label>
                    <div id="img-preview-one"></div>
                      <div style={{"width": "200px"}}>
                         <ImageUploadAndPreview
                             onChange={files => setBannerImage(files.length ? files[0] : null)}
                         />
                      {errors.uploadImage && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select Upload Image
                    </div>
                  )}
                    </div>
                  </div>
                  <div className="authorUpload">
                    <label className="title fo-tit fo-tit-top">
                      Upload Author image <span className="star">*</span>
                    </label>
                    <div id="img-preview-two"></div>
                    <div>

                         <ImageUploadAndPreview
                             onChange={files => setAuthorImage(files.length ? files[0] : null)}
                         />
                       {errors.authorImage && (
                    <div className={`invalid-feedback d-block`}>
                      Please Select Author Image
                    </div>
                  )}
                    </div>
                  </div>
                </div>
                <div className="addnew-form1-autor-detail mt-3 d-flex">
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Author Name <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authordetails"
                      {...register("authordetails", { required: true })}
                      style={{paddingLeft:"10px"}}
                    />
                    {errors.authordetails && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Author Name
                    </div>
                  )}
                  </div>
                  <div className="col-md-4">
                    <label for="inputAddress" className="form-label title">
                      Author Specialization <span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input"
                      id="authorSpecialization"
                      {...register("authorSpecialization", { required: true })}
                      style={{paddingLeft:"10px"}}
                    />
                    {errors.authorSpecialization && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Author Specialization
                    </div>
                  )}
                  </div>
                </div>

                <div className="addnew-form1-desc mt-3">
                  <label className="title">
                    Description <span className="star">*</span>
                  </label>
                  <div>
                    <JoditEditor
                      {...register("healtharticle_description", { required: false })}
                      ref={editor}
                      value={content}
                      id="description"
                      // name="description"
                      config={config}
                    />
                    {/* <Editor
                      // editorState={editorState}
                      {...register("healtharticle_description", { required: false })}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      // onEditorStateChange={this.onEditorStateChange}
                    /> */}
                    {/* <CKEditor
                      editor={ ClassicEditor }
                      data="<p>Hello from CKEditor 5!</p>"
                      onReady={ editor => {
                          // You can store the "editor" and use when it is needed.
                          console.log( 'Editor is ready to use!', editor );
                      } }
                      onChange={ ( event, editor ) => {
                          const data = editor.getData();
                          console.log( { event, editor, data } );
                      } }
                      onBlur={ ( event, editor ) => {
                          console.log( 'Blur.', editor );
                      } }
                      onFocus={ ( event, editor ) => {
                          console.log( 'Focus.', editor );
                      } }
                  /> */}
                     {errors.description && (
                        <div className={`invalid-feedback d-block`}>
                          Please provide a Description
                        </div>
                      )}
                    {/* <textarea
                       name=""
                       id=""
                       cols="30"
                       rows="10"
                       {...register("description", { required: false })}
                       ></textarea> */}
                  </div>
                </div>
                <div className="addnew-form1-videourl mt-3">
                  <div className="col-7">
                    <label for="inputAddress" className="form-label title">
                      Video URL
                    </label>
                    <input
                      type="text"
                      className="form-control addnew-input  w-100"
                      id="videoUrl"
                      {...register("videoUrl", { required: false })}
                      style={{paddingLeft:"10px"}}
                    />
                    {errors.videoUrl && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Video URL
                    </div>
                  )}
                  </div>
                </div>
                <div className="addnew-form1-next">
                  <div onClick={handleNext}>
                    <div>
                      <button>NEXT</button>
                    </div>
                    <div>
                      <img src={next} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ display: show ? "block" : "none" }}
                className="addnew2-form"
              >
                <div className="col-12">
                  <label for="inputAddress" className="form-label title ">
                    Meta Title <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metatitle"
                    {...register("metatitle", { required: true })}
                    // placeholder="1234 Main St"
                    style={{paddingLeft:"10px"}}
                  />
                  {errors.metatitle && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Meta Title
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Meta Description <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metadescription"
                    style={{paddingLeft:"10px"}}
                    // placeholder="1234 Main St"
                    {...register("metadescription", { required: true })}
                  />
                  {errors.metadescription && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Meta Description
                    </div>
                  )}
                </div>
                <div className="col-12 ">
                  <label for="inputAddress" className="form-label title">
                    Meta Keywords <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100 "
                    id="metakeywords"
                    {...register("metakeywords", { required: true })}
                    style={{paddingLeft:"10px"}}
                  />
                  {errors.metakeywords && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Meta Keywords
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Meta Tags <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="metatags"
                    {...register("metatags", { required: true })}
                    style={{paddingLeft:"10px"}}
                  />
                  {errors.metatags && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Meta Tags
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <label for="inputAddress" className="form-label title">
                    Canonical Url <span className="star">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control addnew-input w-100"
                    id="canonicalUrl"
                    {...register("canonicalUrl", { required: true })}
                    style={{paddingLeft:"10px"}}
                  />
                  {errors.canonicalUrl && (
                    <div className={`invalid-feedback d-block`}>
                      Please provide a Canonical Url
                    </div>
                  )}
                </div>

                <div className="addnew1-form1-next">
                  <div className="addnew1-back-next">
                    <div className="addnew1-back">
                      <div onClick={handleBack}>
                        <div>
                          <img src={back} alt="" />
                        </div>
                        <div>BACK</div>
                      </div>
                    </div>
                    <div className="addnew1-next">
                    <div>
                      <button
                         type="submit"
                         className="bg-transparent border-0 text-white"
                         data-bs-toggle="modal"
                         data-bs-target="#exampleModal2"
                      >ADD</button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/healtharticle"}
          name="Health Article"
          post='posted'
        />
      )}
    </>
  );
};

export const ImageUploadAndPreview = props => {
    const [files, setFiles] = useState(props.files || []);
    const [imageUrls, setImageUrls] = useState([]);
    const width = props.width || 200;
    const height = props.height || 250;

    useEffect(() => {
            props.onChange && props.onChange(files);
    }, [files])

    const onFileInputChange = e => {
        if (e.target.files === 0) {
            return;
        }
        setFiles(props.multiple ? [...files, ...e.target.files] : [...e.target.files] );

        const newImageUrls = props.multiple ? [...imageUrls] : [];
        for(let i=0; i < e.target.files.length; i++) {
            newImageUrls.push(URL.createObjectURL(e.target.files[i]));
        }

        setImageUrls(newImageUrls)

    }

    const onClickAddFileBtn = e => {
        e.preventDefault();
        const inputs = e.target.parentNode.querySelector('input:last-child');
        if (inputs) {
            inputs.click();
        }
    }

    const removeFile = i => {
        files.splice(i, 1)
        setFiles([...files]);
        imageUrls.splice(i, 1)
        setImageUrls([...imageUrls]);
    }

    return (<>
        <div className="d-flex justify-content-center align-items-center image-upload-and-preview-container" style={{width: width + 'px', height: height + 'px'}}>
            {imageUrls.map((url, i) => {
                return (<div key={i} className="card">
                    <img
                        src={url}
                        className="img-fluid object-fit-cover" />
                    <div className="card-footer">
                        <button
                            className="btn btn-sm btn-danger"
                            onClick={() => {removeFile(i)}}
                        >Remove</button>
                    </div>
                </div>);
            })}
            {!props.multiple && files.length ? '' : <div
                onClick={onClickAddFileBtn}
                className="d-flex align-items-center justify-content-center w-100 bg-dark bg-opacity-50"
                style={{height: height + 'px'}}
            >
                +
            </div>}
            <div className="image-upload-and-previewer visually-hidden">
                    <input
                        type="file"
                        onChange={onFileInputChange}
                        multiple={props.multiple ? 'multiple' : ''}
                        className="image-upload-and-previewer-file-input" />
            </div>
        </div>
    </>);
}

export default AddNewPageOne;
