import React, { useState } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../modal/SucessModal";
import { useEffect } from "react";

const StoreLinks = () => {
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_URL}store-link/list`)
      .then((res) => {
        setData(res.data.rows);
        setId(res.data.rows[0]._id);
        setCount(res.data.count);
        Object.keys(res.data.rows[0]).map((x) => {
          setValue(x, res.data.rows[0][x]);
        });
      });
  }, [count]);

  const onSubmit = (fields) => {
    if (count == 0) {
      axios
        .post(`${process.env.REACT_APP_API_URL}store-link/create`, fields)
        .then((response) => {
          if (response.data?._id) {
            setSuccess(true);
          } else {
            alert("Fail");
            console.log("failed...");
          }
        });
    } else if (count == 1) {
      let fields = getValues();
      axios
        .patch(
          `${process.env.REACT_APP_API_URL}store-link/update/${id}`,
          fields
        )
        .then((response) => {
          if (response.data?._id) {
            setEditSuccess(true);
          } else {
            alert("Fail");
          }
        });
    }
  };

  return (
    <>
      {/* <Home> */}
      <div className="healtharticle">
        <div className="healtharticle-title">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>
              Play Store / App Store Links
            </span>
          </Link>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="carr-dep-form">
          <div className="row car-dep-form">
            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                Play Store Link{" "}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("playStore", { required: false })}
                placeholder="Type here..."
              />
            </div>

            <div className="col-6">
              <label
                className="car-dep-form-txt mb-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
              >
                App Store Link{" "}
              </label>
              <input
                className="form-control w-75"
                type="text"
                name=""
                id="type"
                {...register("appStore", { required: false })}
                placeholder="Type here..."
              />
            </div>
          </div>

          <div className="car-dep-form-button">
            <button type="submit">SUBMIT</button>
          </div>
        </form>
      </div>
      {/* </Home> */}
      {success && (
        <SucessModal
          page={"/store-links"}
          name="Play Store / App Store Links"
          post="posted"
        />
      )}

      {editSuccess && (
        <SucessModal
          page={"/store-links"}
          name="Play Store / App Store Links"
          post="updated"
        />
      )}
    </>
  );
};

export default StoreLinks;
