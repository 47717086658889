import React, { useState } from "react";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../modal/SucessModal";
import { useEffect } from "react";

const SiteMapSettings = () => {
 const {
    register,
    getValues,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [count, setCount] = useState(0);

  return (
    <>
      <div className="healtharticle">
        <div className="healtharticle-title ms-4">
          <Link
            to="/settings-social-media-link"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
            }}
          >
            <span style={{ paddingLeft: "5%" }}>Settings</span>
          </Link>
          <span style={{ fontSize: "15px", paddingLeft: "1%" }}>></span>
          <Link
            to=""
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "15px",
              paddingLeft: "0.2%",
              width: "20%",
            }}
          >
            <span style={{ paddingLeft: "6%" }}>Site Map Setting</span>
          </Link>
        </div>
        <form className="carr-dep-form">
          <div className="">
             
             <div className="d-flex ms-2 mt-3">
               <div className="md-6">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Site map file
                </label>
                <div className="meta-tag-file mt-2">
                    <input 
                      type="file"
                    />
                </div>
               </div>
               <div className="md-6 ms-5">
                <label
                  className="car-dep-form-txt mt-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Analytics Code
                </label>
                <div className="meta-tag-file mt-2">
                    <input 
                      type="file"
                    />
                </div>
               </div>
             </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2">
            <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
            >
                Robots File Upload
            </label>
            <div className="meta-tag-file mt-2">
                <input 
                  type="file"
                />
            </div>
            </div>

            {/*--------------Site Map Settings----------*/}
            <div className="mt-3 ms-2">
            <label
                className="car-dep-form-txt mt-2"
                style={{ color: "#4B4853" }}
                htmlFor=""
            >
                Google Ads
            </label>
            <div className="md-12 mt-2">
                <textarea className="site-map-text-area" name="" id="" cols="30" rows="10">

                </textarea>
            </div>
            </div>

            <div className="car-dep-form-button mb-5 mt-5">
              <button type="submit">SUBMIT</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default SiteMapSettings