import React, { useState } from "react";
import "./form.scss";
import { Link, useNavigate } from "react-router-dom";
import backRed from "../../../../../assets/svg/back-rose-icon.svg";
import Home from "../../../../Home/Home";
import { useForm } from "react-hook-form";
import axios from "axios";
import SucessModal from "../../../../modal/SucessModal";

const LocationForm = () => {

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);

  const onSubmit = (fields) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}location/create`,
        fields
      )
      .then((response) => {
        if (response.data?._id) {
          setSuccess(true);
        } else {
          alert("Fail");
          console.log("failed...");
        }
      });
  };

  return (
    <>
      {/* <Home> */}
        <div
          onClick={() => navigate("/master-location")}
          className="rose back-button"
        >
          <img src={backRed} alt="" />
          <span className="rose-back">Back</span>
        </div>
        <div className="healtharticle">
          <div className="healtharticle-title ms-2">
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/master-primary-specialisation"
              >
                Master
              </Link>
            </span>
            <span className="bread-crumb">></span>
            <span>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/master-location"
              >
                Common
              </Link>
            </span>
            <span className="bread-crumb1" style={{ paddingLeft: "0.4%" }}>
              >
            </span>
            <span style={{ paddingLeft: "0.4%" }}>Location(City / Town)</span>
          </div>
          <form
             onSubmit={handleSubmit(onSubmit)}
            className="carr-dep-form"
          >
            <div className="row car-dep-form">
              <div className="col-5">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Location(City / Town) <span className="star">*</span>
                </label>
                <input
                  className="form-control w-75"
                  type="text"
                  name=""
                  id="type"
                    {...register("type", { required: true })}
                  placeholder="Type here..."
                />
                {errors.type && (
                  <div className={`invalid-feedback d-block`}>
                    Please Enter Location
                  </div>
                )}
              </div>

              <div className="col-3">
                <label
                  className="car-dep-form-txt mb-2"
                  style={{ color: "#4B4853" }}
                  htmlFor=""
                >
                  Active <span className="star">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline me-3 mt-1">
                    <input
                      className="form-check-input me-3"
                      type="radio"
                      name="active"
                      id="active"
                        {...register("active", { required: true })}
                      value={true}
                      checked={checked ? "checked" : ""}
                      onClick={() => setChecked(true)}
                    />
                    <label htmlFor="option-1" className="text-dark ">
                      Active
                    </label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input
                      className="form-check-input  me-3"
                      type="radio"
                      name="active"
                      id="active"
                      value={false}
                      checked={checked ? "" : "checked"}
                        onClick={() => setChecked(false)}
                        {...register("active", { required: true })}
                    />
                    <label htmlFor="option-2" className="text-dark">
                      Inactive
                    </label>
                  </div>
                </div>
                {errors.active && (
                  <div className={`invalid-feedback d-block`}>
                    Please select an Option
                  </div>
                )}
              </div>
            </div>

            <div className="car-dep-form-button">
              <button type="submit" style={{ marginTop: "5%" }}>
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      {/* </Home> */}
      {success && (
        <SucessModal page={"/master-location"} name="Location" post='posted'/>
      )}
    </>
  )
}

export default LocationForm